
import React, { useState, useEffect, useRef } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import { URLS } from "../../../constants/urls";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import {
  Card, Input,
} from "reactstrap";
import {
  ReactDataTable,
  UserAvatar,
  Icon,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { FaFilter } from "react-icons/fa";
import Select from 'react-select';
import { Rate } from 'antd';
import { findUpper, capital } from "../../../utils/Utils";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const ExcelJS = require('exceljs');

const ResourceReport = () => {
  const [getAllResource, setGetAllResources] = useState([]);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false)
  const [getAllResourceNew, setgetAllResourceNew] = useState([]);
  const [resourceTypeText, setResourceTypeText] = useState("");
  const [curriculamContentType, setCurriculamContentType] = useState('');
  const [experienceType, setExperienceType] = useState('');
  const [facilityType, setFacilityType] = useState('');
  const [fundingType, setFundingType] = useState("");
  const [resourceType, setResourceType] = useState("");
  const [organizationType, setOrganizationType] = useState("");
  const [studentSupportType, setStudentSupportType] = useState("");
  const [teacherParentSupportType, setTeacherParentSupportType] = useState("");
  const [timeFrameTypeCariculam, setTimeFrameTypeCariculam] = useState("");
  const [timeFrameTypeExperience, setTimeFrameTypeExperience] = useState("");
  const [state, setState] = useState({});
  const [getAllFacilityResourcePage, setGetAllFacilityResourcePage] = useState([]);
  const [getAllFundingResourcePage, setGetAllFundingResourcePage] = useState([]);
  const [getAllOrganizationResourcePage, setGetAllOrganizationResourcePage] = useState([]);
  const [getAllExperienceResourcePage, setGetAllExperienceResourcePage] = useState([]);
  const [getAllStudentSupportResourcePage, setGetAllStudentSupportResourcePage] = useState([]);
  const [getTeacherParentSupportResourcePage, setGetTeacherParentSupportResourcePage] = useState([]);
  const [getInstructionStyleResourcePage, setGetInstructionStyleResourcePage] = useState([]);
  const [getCustomerParentData, setGetCustomerParentData] = useState([]);
  const [getChildlistData, setGetChildlistData] = useState([])
  const [allLearningPiller, setAllLearningPiller] = useState([])
  const [parentid, setparentid] = useState("")
  const [pillerId, setPillerId] = useState([])
  const [childId, setChildId] = useState('')
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  let [count, setCount] = useState()
  const [pageCount, setPageCount] = useState(null)
  const [search, setSearch] = useState("")
  const [selectedfromdate, setSelectedfromDate] = useState(null)
  const [selectedtodate, setSelectedToDate] = useState(null)
  const [formDateError, setformDateError] = useState("")
  const [toDateError, settoDateError] = useState("")
  const [parentDropdown, setParentDropdown] = useState([])
  const [dropdownPage, setDropdownPage] = useState(1)
  const [selectedParent, setselectedParent] = useState()
  const [countScroll, setCountScroll] = useState(0)
  const [resourcestate, setresourcestate] = useState("")
  const [rating, setrating] = useState("")
  const [resourcedeletestate, setresourcedeletestate] = useState("")
  const [limitvalue, setlimitvalue] = useState("")
  const [sortresource, setSortResource] = useState("")
  const [learningPillarData, setLearningPillarData] = useState([]);
  const [learningPillarIds, setLearningPillarIds] = useState([]);
  const [displayvalueSelect, setDisplayValueSelect] = useState(true);
  const [clearStateList, setClearStateList] = useState(false);
  const [customerType, setCustomerType] = useState("");
  const [instructionalStyleType, setInstructionalStyleType] = useState("");
  
  const [userName, setuserName] = useState(true);
  
  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]
  let learning_pillars_arr = [];
  let ratingdata = [
    { label: "★", value: "1" },
    { label: "★★", value: "2" },
    { label: "★★★", value: "3" },
    { label: "★★★★", value: "4" },
    { label: "★★★★★", value: "5" },

  ]
  const ShowHideSelect = () => {
    setShow(!show)
  }
  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 1,
      minWidth: "70px",
      style: { minWidth: "70px" },

      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {currentPage == 1 ? (index + 1) : ((currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10)) + (index + 1)} </div>
        </>
      ),
    },

    {
      name: "Customer Detail",
      selector: (row) => row.tbl_user.firstName ? row.tbl_user.firstName : "--" + " " + row.tbl_user.lastName ? row.tbl_user.lastName : "",
      grow: 5,
      sortable: true,

      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {/* <UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes?.length)]} text={findUpper(capital(row.tbl_user.firstName + " " + row.tbl_user.lastName))} ></UserAvatar> */}
          <div className="user-info">
            <span className="tb-lead">
              {row.tbl_user?.firstName == null ? <div>--</div> : row.tbl_user?.firstName} {row.tbl_user?.lastName == null ? <div>--</div> : row.tbl_user?.lastName}
            </span>
            <span className="sub-text">{row.tbl_user?.email}</span>
            {/* <span className="sub-text">{row.tbl_user?.phone}</span> */}
            <div className="sub-phoneinp-text">
            <PhoneInput
            country={row?.tbl_user?.country_dial_code?.toUpperCase() || ""}
            value={(row?.tbl_user?.countryCode + row?.tbl_user?.phone)}
            placeholder="--"
            inputClass="minimal-input"
            buttonStyle={{ display: "none" }}
            inputStyle={{
              border: "none",
              padding: "0",
              background: "none",
              fontSize: "12px",
              width: "100%",
              outline: "none",
              color: "#8094ae",
              cursor: "pointer",
              // position: "relative",
              // top: "-56px",
            }}
            containerStyle={{
              border: "none",
              padding: "0",
            }}
            disabled={true}
          />
           </div>
          </div>
        </div>
      ),
    },
    {
      name: "Registered Date",
      selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">{moment(row.created_at).format("MM-DD-YYYY")}</span>
            {row.is_review == "yes" ?
              <div className="user-card mt-0 mb-0">
                <button type="button" className="badge badge-success" style={{ background: "#28a745" }} >
                  <span>Reviewed</span>
                </button>
              </div>
              : ""}
          </div>
        </div>
      ),
    },
    {
      name: "Resource Details",
      selector: (row) => row.resource_title,

      grow: 3,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.resource_title}</span>
            {/* <span className="sub-text">{row.tbl_pillar_resource_mappings.map((item)=>item.tbl_learning_pillar_master.name).join(", ")}</span> */}
            {/* <span className="sub-text">{row?.tbl_pillar_resource_mappings?.map((item)=>
           
           <div>
             <img src={`https://matcheddev.s3.ca-central-1.amazonaws.com${item.tbl_learning_pillar_master?.image}`} style={{width:"20px",height:"20px"}}></img>
           </div>
           )}</span> */}
          </div>
        </div>
      ),
    },

    {
      name: "Resource Type",
      selector: (row) => row.tbl_resource_type_master?.name,
      grow: 2,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row.tbl_resource_type_master?.name ? row.tbl_resource_type_master?.name : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => "",
      grow: 1,
      // sortable: true,
      cell: (row, index) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.status ? row.status : "--"}</span>
          </div>
        </div>
      ),
    },
    {
      name: "Rating",
      selector: (row) => row.rating,
      grow: 1,
      minWidth: "100px",
      style: { minWidth: "100px" },

      // sortable: true,
      cell: (row, index) => (

        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="tb-lead">
              {row.rating == null || row.rating == 0 ? <div><p>--</p></div> : <Rate disabled defaultValue={row.rating} style={{ color: "#F19357", fontSize: 12 }} />}
            </span>

          </div>
        </div>
      ),

    },
    // {
    //   name: "Action",
    //   selector: (row) => row.status,
    //   grow: 1,
    //   minWidth: "50px",
    //  style: { minWidth: "50px" },
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2">
    //        <Link to={`/view-resource/${row.id}`}>  <button
    //         className="dropdown-item btn btn-md"
    //       >
    //         <em className="icon ni ni-eye"></em>
    //       </button>
    //       {sessionStorage.setItem("tab","")}
    //       </Link> 
    //       <Link to={`/editResource-page/${row.id}`}>  <button
    //         className="dropdown-item btn btn-md"
    //       >
    //        <em class="icon ni ni-edit"></em>
    //       </button>
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  const handlebutton = (id, isSelected, index) => {

    let data = {
      id: id,
      status: isSelected,
    };
    const newArray = Array.from(getAllResource);
    newArray[index].status = isSelected === "publish" ? "unpublish" : "publish";
    setGetAllResources(newArray);
    makePostRequest(URLS.UPDATE_ADMIN_RESOURCE, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          toast.success(res.message);
          getresourcefun();
        } else {
          toast.error(res.message);
          getresourcefun();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    // getParentDropdown()
  }, [])

  const handleMenuScroll = (event) => {
    const { target } = event;
    // if (target.scrollTop === target.scrollHeight - target.clientHeight) {
    //   getParentDropdown()
    // }
    // console.log("count scroll", countScroll)
    if (countScroll > 0) {
      getParentDropdown()
    }

  };

  const HandleLimitValue = (e) => {

    setlimitvalue(e.target.value)
    setCurrentPage(1)
  }
  const HandleSortResource = (e) => {
    setSortResource(e.target.value)
    setCurrentPage(1)


  }
  useEffect(() => {
    getresourcefun()
  }, [sortresource, limitvalue])

  const handleLearningPillar = (learningPillarVal) => {
    setLearningPillarIds(learningPillarVal);
  };
 
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }

  const getAllUserType = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_RESOURCE_TYPE, {}, null)
      .then(res => {
        if (res.code == 200) {
          setgetAllResourceNew(res.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }

  const handlestate = (e) => {
    setresourcestate(e.target.value);
  }

  const handlerating = (e) => {
    setrating(e.target.value)
  }
  const handledeletestate = (e) => {
    setresourcedeletestate(e.target.value);
  }

  const HandleFromDate = (e) => {
    setDatePickerShow(!datepickershow);
    setSelectedfromDate(e)
    setformDateError("")

  }
  const HandleToDate = (e) => {
    setSelectedToDate(e)
    setDatePickerShow1(!datepickershow1);
    settoDateError("")
  }
  const handleCustomerType = (e) => {
    setCustomerType(e.target.value);
  }
  const handleInstructionalStyle = (e) => {
    setInstructionalStyleType(e.target.value);
  }

  const handleResourceType = (e) => {
    let name = e.target.name;
    let value = e.target.value;


    if (name === "resourceType") {
      let index = e.nativeEvent.target.selectedIndex;
      let label = e.nativeEvent.target[index].text;
      setResourceTypeText(label);
      setResourceType(value);
      //  console.log("name",label,"value",value)
    } else if (name === "curriculamContentType") {
      setCurriculamContentType(value);
    } else if (name === "experienceType") {
      setExperienceType(value);
    } else if (name === "facilityType") {
      setFacilityType(value);
    }
    else if (name === "fundingType") {
      setFundingType(value);
    }

    else if (name === "organizationType") {
      setOrganizationType(value);

    }
    else if (name === "studentSupportType") {
      setStudentSupportType(value);
    }
    else if (name === "teacherParentSupportType") {
      setTeacherParentSupportType(value);
    }
    else if (name === "timeFrameTypeCariculam") {
      setTimeFrameTypeCariculam(value);
    } else if (name === "timeFrameTypeExperience") {
      setTimeFrameTypeExperience(value);
    }
    else {
      setState({ ...state, [name]: value });
    }

  }

  const clearFilterList = () => {
    clearFilterListFun();
  }

  const clearFilterListFun = () => {
     if(show== true){
    
    setparentid("");
    setSaveServe("");
    setChildId("");
    setrating("");
    setresourcedeletestate("");
    setResourceType("");
	//setLearningPillarIds("");
	selectRef.current.select.setValue("", "select-option");
    setCurriculamContentType("");
    setExperienceType("");
    setFacilityType("");
    setFundingType("");
    setOrganizationType("");
    setStudentSupportType("");
    setTeacherParentSupportType("")
    setTimeFrameTypeCariculam("");
    setTimeFrameTypeExperience("");
    setResourceTypeText("");
    setCurrentPage(1)
    setSearch("")
    setresourcestate("")
    setCustomerType("")
    setInstructionalStyleType("");
    setPillerId("")
    setSelectedfromDate("");
    setSelectedToDate("");
    setSortResource("");
    setlimitvalue("10");
    setresourcedeletestate("")
    setClearStateList(!clearStateList)
	 }
	 else{
		  setSortResource("");
		  setlimitvalue("10");
	 }
  }

  const [saveserve, setSaveServe] = useState("")
  const [reviewdresource, setReviewdresource] = useState("")

  const handleSaveServe = (e) => {
    setSaveServe(e.target.value)

  }

  const handleReviewResource = (e) => {
    setReviewdresource(e.target.value)
  }
  // Get customer API for parent and customer data
  let selectParentId;
  let selectChildId;
  const handleParentList = (e) => {
    selectParentId = e;
    setparentid(e);
    // console.log("Parent_id", selectParentId);
    getChildListData()

  }
  const handleChildId = (e) => {
    selectChildId = e.target.value;
    setChildId(e.target.value)
    //  console.log("child_id", selectChildId);
  }
  const handlePillerList = (e) => {

    setPillerId(e.target.value);
  }

  const getCustomerParentDatas = () => {
    setLoader(true);
    makePostRequest(URLS.RESOURCE_USER, null, { page_number: 1 }, null)
      .then((res) => {
        // console.log("Res",res)
        if (res.code == 200) {
          setGetCustomerParentData(res.data?.data);
          setLoader(false);
          // console.log(res.data,"ch name")
          setparentid("")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getParentDropdown = () => {
    makePostRequest(URLS.RESOURCE_USER, null, { page_number: dropdownPage }, null)
      .then((res) => {
        // console.log("response1", res)
        if (res.code == 200) {
          setParentDropdown((prevOptions) => [...prevOptions, ...res?.data?.data]);
          setCountScroll(res.data.remaining_count)
          setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  // console.log("parent Dropdown list", parentDropdown)

  const getChildListData = () => {
    // setLoader(true);
    let data = {
      "user_id": selectParentId ? selectParentId : "",
    }

    makePostRequest(URLS.GETALL_CHILD, null, data, null)
      .then((res) => {

        if (res.code == 200) {
          setGetChildlistData(res.data);
          // setLoader(false);
          setChildId("")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Instruction Style API calling Here...
  const handleViewInstructionStyle = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_INSTRUCTION_STYLE, {}, null)
      .then(res => {
        if (res.code == 200) {
          let filterContent = res.data.filter((val) => val.status === "enable");
          setGetInstructionStyleResourcePage(filterContent);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })
  }
  // Experience API calling Here...

  const handlViewExperience = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_EXPERIENCE_TYPE, {}, null)
      .then((res) => {
        if (res.code === 200) {
          
          let filterDataExperience = res.data.filter((val) => val.status === "enable");
          setGetAllExperienceResourcePage(filterDataExperience);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Facilities API calling Here...

  const handlViewFacility = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_FACILITY_TYPE, {}, null)
      .then((res) => {
        if (res.code === 200) {
          let filterDatafacility = res.data.filter((val) => val.status === "enable");
          setGetAllFacilityResourcePage(filterDatafacility);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // Funding API calling Here...
  const handlViewFunding = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_FUNDING_TYPE, {}, null)
      .then((res) => {
        if (res.code === 200) {
          let filterDatafunding = res.data.filter((val) => val.status === "enable");
          setGetAllFundingResourcePage(filterDatafunding);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //Organization API calling Here...

  const handlViewOrganization = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_ORGANISATION_TYPE, {}, null)
      .then((res) => {
        if (res.code === 200) {
          let filterDataorganisation = res.data.filter((val) => val.status === "enable");
          setGetAllOrganizationResourcePage(filterDataorganisation);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //Support API calling Here...
  const handlViewSupport = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_SUPPORT_TYPE, {}, null)
      .then((res) => {
        if (res.code === 200) {
         let filterDatasupport = res.data.filter((val) => val.status === "enable");
          setGetAllStudentSupportResourcePage(filterDatasupport);
          setGetTeacherParentSupportResourcePage(filterDatasupport);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getresourcefun = () => {
    setLoader(true);

    if (learningPillarIds && learningPillarIds?.length > 0) {

      learningPillarIds.forEach((learningpillarid) =>
        learning_pillars_arr.push(learningpillarid.value)
      );

    }

    let data = {
      "resource_mode": saveserve ? saveserve : "",
      "user_id": parentid.value ? parentid.value : "",
      "child_id": childId ? childId : "",
      //  "search_title": search,
      "piller_id": learning_pillars_arr ? learning_pillars_arr : "",
      "resource_type": resourceType ? resourceType : "",
      "content_type": curriculamContentType ? curriculamContentType : "",
      "experience_type": experienceType ? experienceType : "",
      "facility_type": facilityType ? facilityType : "",
      "funding_type": fundingType ? fundingType : "",
      "organization_type": organizationType ? organizationType : "",
      // "student_support_type": studentSupportType ? studentSupportType : "",
      // "teacher_parrent_support_type": teacherParentSupportType ? teacherParentSupportType : "",
      "support_type":studentSupportType ? studentSupportType : "" || teacherParentSupportType ? teacherParentSupportType : "",
      "start_date": selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      "end_date": selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
      "timeframe": timeFrameTypeCariculam ? timeFrameTypeCariculam : "" || timeFrameTypeExperience ? timeFrameTypeExperience : "",
      "page_number": currentPage,
      "item_per_page": limitvalue ? limitvalue : 10,
      "rating": rating ? rating : "",
      "key": "no",
      "status": resourcestate ? resourcestate : "",
      "is_deleted": resourcedeletestate ? resourcedeletestate : "",
     "sortby": sortresource ? sortresource : "",
      "is_review": reviewdresource ? reviewdresource : "",
      "customer_type":customerType,
      "instructional_style": instructionalStyleType ? instructionalStyleType : "",
    };
    // console.log("resource payload", data)

    makePostRequest(URLS.GET_ALL_RESOURCESREPORT, null, data, null)
      .then((res) => {
        //console.log("All resource", res)
        if (res.code === 200) {
          setLoader(false);
          setGetAllResources(res.data.list_data ? res.data.list_data : []);
          setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)))


        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handleSearch = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date")
    }
    else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ")
    }
    else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date")
    }
    else {
      // setCount(1)
      setCurrentPage(1)
      getresourcefun();
    }
  }
  const getallLearningPiller = () => {
    makeGetRequest(URLS.GET_ALL_LEARNING_PILLER, {}, null)
      .then((res) => {
        if (res.code === 200) {

          setAllLearningPiller(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }
  const getLearningPillarsData = () => {
    makeGetRequest(URLS.GET_ALL_LEARNING_PILLER, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          let options = [];
          res.data.length > 0 &&
            res.data.map((val, i) => {
              options.push({ value: val.id, label: val.name });
            });
          setLearningPillarData(options);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  


  useEffect(() => {
    getAllUserType();
    getCustomerParentDatas();
    getLearningPillarsData();
    // getallLearningPiller();
    getChildListData();
    handlViewFacility();
    handlViewFunding();
    handlViewOrganization();
    handlViewExperience();
    handlViewSupport();
    handleViewInstructionStyle();
    setLoader(true);

  }, []);

  useEffect(() => {
    getresourcefun();
    }, [clearStateList]);


  const ExportEcelFile = () => {
    setLoader(true);
    let data = {
      "resource_mode": saveserve ? saveserve : "",
      "user_id": parentid.value ? parentid.value : "",
      "child_id": childId ? childId : "",
      //  "search_title": search,
      "piller_id": learning_pillars_arr ? learning_pillars_arr : "",
      "resource_type": resourceType ? resourceType : "",
      "content_type": curriculamContentType ? curriculamContentType : "",
      "experience_type": experienceType ? experienceType : "",
      "facility_type": facilityType ? facilityType : "",
      "funding_type": fundingType ? fundingType : "",
      "organization_type": organizationType ? organizationType : "",
      "support_type":studentSupportType ? studentSupportType : "" || teacherParentSupportType ? teacherParentSupportType : "",
      "start_date": selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      "end_date": selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
      "timeframe": timeFrameTypeCariculam ? timeFrameTypeCariculam : "" || timeFrameTypeExperience ? timeFrameTypeExperience : "",
      "page_number": currentPage,
      "item_per_page": limitvalue ? limitvalue : 10,
      "rating": rating ? rating : "",
     "key": "export",
      "export_data":"yes",
      "status": resourcestate ? resourcestate : "",
      "is_deleted": resourcedeletestate ? resourcedeletestate : "",
     "sortby": sortresource ? sortresource : "",
      "is_review": reviewdresource ? reviewdresource : "",
      "customer_type":customerType,
      "instructional_style": instructionalStyleType ? instructionalStyleType : "",
    };

    makePostRequest(URLS.GET_ALL_RESOURCESREPORT, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          // console.log("resource export", res)
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("ResourceData");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          }
          
          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "Date Registered",
              key: "REGISTER_DATE",
              width: 15,
            },
            {
              header: "CUSTOMER",
              key: "CUSTOMER",
              width: 15,
            },
            {
              header: "EMAIL",
              key: "EMAIL",
              width: 15,
            },
            {
              header: "PHONE",
              key: "PHONE",
              width: 15,
            },
            {
              header: "RESOURCE TYPE",
              key: "RESOURCE_TYPE",
              width: 18,
            },
            {
              header: "TITLE",
              key: "resource_title",
              width: 18,
            },
            {
              header: "RESOURCE MODE",
              key: "resource_mode",
              width: 18,
            },
            {
              header: "Learning Pillar",
              key: "Learning_pillar",
              width: 18,
            },
            {
              header: "Start Date",
              key: "Start_Date",
              width: 18,
            },
            {
              header: "End Date",
              key: "End_Date",
              width: 18,
            },
            {
              header: "Start Time",
              key: "Start_Time",
              width: 18,
            },
            {
              header: "End Time",
              key: "End_Time",
              width: 18,
            },
            {
              header: "Category",
              key: "Category",
              width: 18,
            },
            {
              header: "Instruction Style",
              key: "Instruction_Style",
              width: 18,
            },
            {
              header: "Timeframe",
              key: "Timeframe",
              width: 18,
            },
            {
              header: "Accrediated Resource",
              key: "Accrediated_Resource",
              width: 18,
            },
            {
              header: "Accrediated Institute",
              key: "Accrediated_Institute",
              width: 18,
            },
            {
              header: "Dual Enrollment",
              key: "Dual_Enrollmente",
              width: 18,
            },
            {
              header: "Credit Granting Institution",
              key: "Credit_Granting_Institution",
              width: 18,
            },
            {
              header: "College Credits",
              key: "College_Credits",
              width: 18,
            },
            {
              header: "Resource URL",
              key: "Resource_URL",
              width: 18,
            },
            {
              header: "ISBN",
              key: "ISBN",
              width: 18,
            },
            {
              header: "Description",
              key: "Description",
              width: 18,
            },
            {
              header: "About Resource",
              key: "About_Resource",
              width: 18,
            },
            {
              header: "Grade Range",
              key: "Grade_Range",
              width: 18,
            },
            {
              header: "Save For",
              key: "Save_For",
              width: 18,
            },
            // {
            //   header: "Share With",
            //   key: "Share_With",
            //   width: 18,
            // },
            {
              header: "Location Address",
              key: "Location_Address",
              width: 18,
            },
            {
              header: "Keywords",
              key: "Keywords",
              width: 18,
            },
            {
              header: "Elgibile for public funding",
              key: "Elgibile_for_public_funding",
              width: 18,
            },
            {
              header: "Funding States",
              key: "Funding_States",
              width: 18,
            },
            {
              header: "($)Price",
              key: "Price",
              width: 18,
            },
            {
              header: "Type of Experience",
              key: "Type_of_Experience",
              width: 18,
            },
            {
              header: "Type of facility",
              key: "Type_of_facility",
              width: 18,
            },
            {
              header: "Type of Funding",
              key: "Type_of_Funding",
              width: 18,
            },
            {
              header: "Type of Organization",
              key: "Type_of_Organization",
              width: 18,
            },
            {
              header: "Type of Support",
              key: "Type_of_Support",
              width: 18,
            },
            {
              header: "Type of Content",
              key: "Type_of_Content",
              width: 18,
            },
            {
              header: "Customer type",
              key: "Customer_type",
              width: 18,
            },
            {
              header: "Location",
              key: "Location",
              width: 18,
            },

            {
              header: "Total Views",
              key: "Total_views",
              width: 18,
            },
            {
              header: "Total Rating",
              key: "Total_Rating",
              width: 18,
            },
            {
              header: "Total Comments",
              key: "Total_Comments",
              width: 18,
            },
            {
              header: "Total Likes",
              key: "Total_Likes",
              width: 18,
            },
            {
              header: "Total Shared",
              key: "Total_Shared",
              width: 18,
            },
            {
              header: "Total Purchased",
              key: "Total_Purchased",
              width: 18,
            },
            {
              header: "Total Saved",
              key: "Total_Saved",
              width: 18,
            },
            {
              header: "Total Sessions",
              key: "Total_Sessions",
              width: 18,
            },
            {
              header: "STATUS",
              key: "STATUS",
              width: 15,
            },
            {
              header: "Show on Home",
              key: "Show_on_Home_Page",
              width: 18,
            },
            {
              header: "Review by Admin",
              key: "Reviwed_by_Admin",
              width: 18,
            },
            {
              header: "Viewable by Friend",
              key: "Viewable_by_Friend",
              width: 18,
            },
            {
              header: "Priority of Resource",
              key: "Priority_of_resource",
              width: 18,
            },
          ];

          res?.data?.list_data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              REGISTER_DATE: moment(val?.created_at).format("MM-DD-YYYY"),
              CUSTOMER: val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName,
              EMAIL: val?.tbl_user?.email ? val.tbl_user.email : "--",
              PHONE: val?.tbl_user?.phone ? val.tbl_user.phone : "--",
              RESOURCE_TYPE: val?.tbl_resource_type_master?.name ? val.tbl_resource_type_master.name : "--",
              resource_title: val?.resource_title ? val.resource_title : "--",
              resource_mode: val?.resource_mode ? val.resource_mode : "--",
              Learning_pillar:
                val?.tbl_pillar_resource_mappings && val?.tbl_pillar_resource_mappings.length > 0
                  ? val?.tbl_pillar_resource_mappings.map((item) => item?.tbl_learning_pillar_master?.name).join(", ")
                  : "--",
              Start_Date: val?.start_date ? val.start_date : "--",
              End_Date: val?.end_date ? val.end_date : "--",
              Start_Time: val?.start_time ? val.start_time : "--",
              End_Time: val?.end_time ? val.end_time : "--",
              Category: val?.storing_type ? val.storing_type : "--",
              Instruction_Style: val?.tbl_resource_instruction_styles[0]?.tbl_instructional_style?.name
                ? val?.tbl_resource_instruction_styles[0]?.tbl_instructional_style?.name
                : "--",
              Timeframe: val?.tbl_resource_timeframes[0]?.timeframe ? val?.tbl_resource_timeframes[0]?.timeframe : "--",
              Accrediated_Resource: val?.accredited_resource ? val.accredited_resource : "--",
              Accrediated_Institute: val?.accrediting_institution ? val.accrediting_institution : "--",
              Dual_Enrollmente: val?.dual_enrollment_resource ? val.dual_enrollment_resource : "--",
              Credit_Granting_Institution: val?.credit_granting_institution ? val.credit_granting_institution : "--",
              College_Credits: val?.college_credits_number ? val?.college_credits_number : "--",
              Credit_Granting_Organization: val?.tbl_credit_granting_organization?.name
                ? val.tbl_credit_granting_organization.name
                : "--",
              Resource_URL: val?.resource_url ? val.resource_url : "--",
              ISBN: val?.isbn ? val.isbn : "--",
              Save_For: val?.save_for ? val.save_for : "--",
              Location_Address: val?.define_address ? val.define_address : "--",
              Keywords:
                val?.tbl_resource_keywords && val?.tbl_resource_keywords?.length > 0
                  ? val?.tbl_resource_keywords.map((item) => item.keyword).join(", ")
                  : "--",
              Elgibile_for_public_funding: val?.public_funding ? val.public_funding : "--",
              Funding_States:
                val?.tbl_resource_funding_states && val?.tbl_resource_funding_states?.length > 0
                  ? val?.tbl_resource_funding_states?.map((item) => item.tbl_state?.name).join(", ")
                  : "--",
              Price: val?.purchase_price ? val.purchase_price : "--",
              Type_of_Experience:
                val?.tbl_resource_experiences && val?.tbl_resource_experiences?.length > 0
                  ? val?.tbl_resource_experiences?.map((item) => item.tbl_type_experience_master?.name).join(", ")
                  : "--",
              Type_of_Funding:
                val?.tbl_resource_fundings && val?.tbl_resource_fundings?.length > 0
                  ? val?.tbl_resource_fundings?.map((item) => item.tbl_type_funding_master?.name).join(", ")
                  : "--",
              Type_of_Organization:
                val?.tbl_resource_organisations && val?.tbl_resource_organisations?.length > 0
                  ? val?.tbl_resource_organisations?.map((item) => item.tbl_type_organisation_master?.name).join(", ")
                  : "--",
              Type_of_Support:
                val?.tbl_resource_supports && val?.tbl_resource_supports?.length > 0
                  ? val?.tbl_resource_supports?.map((item) => item.tbl_type_support_master?.name).join(", ")
                  : "--",
              Type_of_Content: val?.tbl_resource_contents[0]?.tbl_type_content_master?.name
                ? val?.tbl_resource_contents[0]?.tbl_type_content_master?.name
                : "--",
              Show_on_Home_Page: val?.show_on_home_page ? val.show_on_home_page : "--",
              Reviwed_by_Admin: val?.is_review ? val.is_review : "--",
              Viewable_by_Friend: val?.viewable_by_my_friends ? val.viewable_by_my_friends : "--",
              Credit_Granting_Organization: val?.tbl_credit_granting_organization?.name
                ? val.tbl_credit_granting_organization.name
                : "--",
              Resource_URL: val?.resource_url ? val.resource_url : "--",
              ISBN: val?.isbn ? val.isbn : "--",
              Description: val?.description ? val.description.replace(/(<([^>]+)>)/gi, "") : "--",
              About_Resource: val?.about_resource ? val.about_resource.replace(/(<([^>]+)>)/gi, "") : "--",
              Grade_Range:
                val?.tbl_grade_resource_mappings && val?.tbl_grade_resource_mappings?.length > 0
                  ? val?.tbl_grade_resource_mappings.map((temp) => temp.tbl_grade_level.name).join(", ")
                  : "--",
              Save_For: val?.save_for ? val.save_for : "--",
              Location:
                val?.tbl_resource_location_types && val?.tbl_resource_location_types?.length > 0
                  ? val?.tbl_resource_location_types?.map((item) => item.location_type).join(", ")
                  : "--",
              Keywords:
                val?.tbl_resource_keywords && val?.tbl_resource_keywords?.length > 0
                  ? val?.tbl_resource_keywords.map((item) => item.keyword).join(", ")
                  : "--",
              Elgibile_for_public_funding: val?.public_funding ? val.public_funding : "--",

              Funding_States:
                val?.tbl_resource_funding_states && val?.tbl_resource_funding_states?.length > 0
                  ? val?.tbl_resource_funding_states?.map((item) => item.tbl_state?.name).join(", ")
                  : "--",
              Price: val?.purchase_price ? val.purchase_price : "--",
              Type_of_Experience:
                val?.tbl_resource_experiences && val?.tbl_resource_experiences?.length > 0
                  ? val?.tbl_resource_experiences?.map((item) => item.tbl_type_experience_master?.name).join(", ")
                  : "--",
              Type_of_facility:
                val?.tbl_resource_facilities && val?.tbl_resource_facilities?.length > 0
                  ? val?.tbl_resource_facilities?.map((item) => item.tbl_type_facility_master?.name).join(", ")
                  : "--",
              Customer_type: val?.tbl_resource_customer_types[0]?.customer_type
                ? val.tbl_resource_customer_types[0]?.customer_type
                : "--",
              Total_views: val?.total_time_viewed ? val.total_time_viewed : "0",
              Total_Rating: val?.rating ? val.rating : "0",
              Total_Comments: val?.total_comments ? val.total_comments : "0",
              Total_Likes: val?.total_likes ? val.total_likes : "0",
              Total_Shared: val?.total_shared ? val.total_shared : "0",
              Total_Saved: val?.tbl_resource_saveds.length ? val.tbl_resource_saveds.length : "0",
              Total_Purchased: val?.tbl_resource_purchases ? val.tbl_resource_purchases.length : "0",
              Total_Sessions: val?.tbl_user_mentoring_sessions ? val.tbl_user_mentoring_sessions.length : "0",
              STATUS: val?.status ? val.status : "--",
              Show_on_Home_Page: val?.show_on_home_page ? val.show_on_home_page : "--",
              Viewable_by_Friend: val?.viewable_by_my_friends ? val.viewable_by_my_friends : "--",
              Priority_of_resource: val?.priority_of_resource ? val?.priority_of_resource : "--",
            });
          });

          workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            })
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'Resource.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url)
          })
          setLoader(false);

        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }
  

  const [contentTypeData, setcontentTypeData] = useState([]);
  //api call to get all content type data
  const getAllContentTypes = () => {
    makeGetRequest(URLS.GET_ALL_CONTENT_TYPE, null, null, null)
      .then((res) => {
        //  console.log(res, "content");
        if (res.code === 200) {
          let filterstatus = res.data.filter((val) => val.status === "enable");
          setcontentTypeData(filterstatus);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


useEffect(()=>{
  getAllContentTypes();
},[])

  // Handle page change event
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    // fetchData()
  };

  // Fetch data based on the current page
  // const fetchData = () => {

  //   // Your code to fetch data from the server based on the currentPage
  //   // For example, you can make an API call or update the state with the new data
  //   // setLoader(true);
  //   if (learningPillarIds && learningPillarIds.length > 0) {

  //     learningPillarIds.forEach((learningpillarid) =>
  //       learning_pillars_arr.push(learningpillarid.value)
  //     );

  //   }

  //   let data = {
  //     "resource_mode": saveserve ? saveserve : "",
  //     "user_id": parentid ? parentid : "",
  //     "child_id": childId ? childId : "",
  //     // "search_title": search,
  //     "piller_id": learning_pillars_arr ? learning_pillars_arr : "",
  //     "resource_type": resourceType ? resourceType : "",
  //     "curriculum_content": curriculamContentType ? curriculamContentType : "",
  //     "experience_type": experienceType ? experienceType : "",
  //     "facility_type": facilityType ? facilityType : "",
  //     "funding_type": fundingType ? fundingType : "",
  //     "organization_type": organizationType ? organizationType : "",
  //     "student_support_type": studentSupportType ? studentSupportType : "",
  //     "teacher_parrent_support_type": teacherParentSupportType ? teacherParentSupportType : "",
  //     "start_date": selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
  //     "end_date": selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
  //     "timeframe": timeFrameTypeCariculam ? timeFrameTypeCariculam : "" || timeFrameTypeExperience ? timeFrameTypeExperience : "",
  //     "page_number": currentPage,
  //     "item_per_page": limitvalue ? limitvalue : 10,

  //     rating: rating ? rating : "",
  //     "key": "list",
  //     status: resourcestate ? resourcestate : "",
  //     is_deleted: resourcedeletestate ? resourcedeletestate : "",
  //     sortby: sortresource ? sortresource : "",
  //     is_review: reviewdresource ? reviewdresource : "",
  //   };

  //   //console.log("resource payload", data)
  //   makePostRequest(URLS.GET_ALL_RESOURCESREPORT, null, data, null)
  //     .then((res) => {
  //       if (res.code === 200) {

  //         setLoader(false);
  //         setGetAllResources(res.data.list_data ? res.data.list_data : []);
  //         setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)))
  //         // setTimeout(() => {
  //         // }, 2000);

  //       } else {
  //         setLoader(false);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    //fetchData()
   getresourcefun();
  }, [currentPage])
  // Render your component with pagination
     const newRef = useRef(null);
	const newRef1 = useRef(null);
	const selectRef = useRef(null);
  const selectRefUserName = useRef(null);
	
  const handleCustomerName = ()=> {
    if(selectRefUserName.current) {
       setuserName(!userName);
      selectRefUserName.current.focus();
    }
  }

	 const displaySelect = (e)=> {
    if (selectRef.current )
    {
     setDisplayValueSelect(!displayvalueSelect);
    selectRef.current.focus();
    }
  }
  
  const handleOutSideClick = (e)=> {
	 if(newRef.current && !newRef.current.contains(e.target)){
         setDatePickerShow(false);
          
	 }		 
  }
  const handleOutSideClick1 = (e)=> {
	 if(newRef1.current && !newRef1.current.contains(e.target)){
            setDatePickerShow1(false);		 
	 }		 
  }
  useEffect(()=>{
	 document.addEventListener("mousedown", handleOutSideClick );
	 document.addEventListener("mousedown", handleOutSideClick1 );
      return ()=> {
       document.removeEventListener("mousedown", handleOutSideClick );
	    document.removeEventListener("mousedown", handleOutSideClick1 );
	  }	   
  });



  return (
    <React.Fragment>
      <Head title="Resources List"></Head>
      <ToastContainer />
      {/* <h3 className="nk-block-title page-title mb5 pb-4"> Resource List </h3> */}


      <Content>


        <h3 className="nk-block-title page-title mt-3 pb-4 pt-5"> Resource Report </h3>
        {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" >
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : (
          <Card className="card-bordered">
            <div className="card-inners relativeClass">
              <div className="p-4">
                <div className="row pb-2 pt-2  align-items-center">
                  <div className="col-lg-4 filtersidebutton">
                    <h5 style={{ cursor: "pointer" }} onClick={ShowHideSelect}>Filters<FaFilter /></h5>
                   <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList}>Clear Filters</button>
				  </div>

                  <div className="col-lg-8 d-flex  justify-content-end">

                    <select value={sortresource} className="form-select" style={{ width: "190px" }} onChange={HandleSortResource}>
                      <option value="">Sort By</option>
                      <option value="ASCDATE">Date Ascending</option>
                      <option value="DESCDATE">Date Descending</option>
                      <option value="ASCTITLE">Title Ascending</option>
                      <option value="DESCTITLE">Title Descending</option>
                      <option value="RATINGASC">Rating Ascending</option>
                      <option value="RATINGDESC">Rating Descending</option>
                    </select>


                    <div><label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label></div>
                    <div><select value={limitvalue} className="form-select" style={{ width: "85px" }} onChange={HandleLimitValue}>

                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    </div>
                    <div>
                      &nbsp; &nbsp;
                      <button className="btn btn-primary " onClick={ExportEcelFile}>
                        <em className="icon ni ni-download-cloud"></em><span>Export</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>
              <>

                <div className="padding row">
                  {show && <>
                    <div className="row pb-2">
                      <div className="col-lg-4">

                        <div className="form-control border-0 px-0">
                          <label><strong>Resource Mode</strong></label>
                          <select value={saveserve} className="form-select" onChange={handleSaveServe}>
                            <option value="">Select Mode</option>
                            <option value="save">Save</option>
                            <option value="serve">Serve</option>
                          </select>
                        </div></div>



                      {saveserve == "" || saveserve == "serve" ?

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label><strong>Parent/Customer</strong></label>
                            <div className="form-control-wrap paymetsession">
                            <i
                              className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                              onClick={handleCustomerName}
                            ></i>
                            {/* <select value={parentid} className="form-select" onChange={handleParentList}>
                              <option value="">All Parents</option>
                              {
                                getCustomerParentData && getCustomerParentData.map((val, index) => {
                                  return (<>
                                    <option value={val?.tbl_user?.id}>{val?.tbl_user?.firstName}{" "}{val?.tbl_user?.lastName}</option>
                                  </>)
                                })
                              }
                            </select> */}

                            <Select
                              className="form-select form-selectparent"
                              autosize={true}
                              onChange={(e) => handleParentList(e)}
                              defaultValue={parentid}
                              options={[
                                { value: "", label: "All" },
                                ...(getCustomerParentData?.map((val) => ({
                                  value: val.tbl_user.id,
                                  label: val.tbl_user?.firstName + " " + val.tbl_user?.lastName,
                                })) || []),
                              ]}
                              openMenuOnFocus={userName}
                              ref={selectRefUserName}
                            />

                           </div>
                          </div>
                        </div>
                        : <>

                          <div className="col-lg-4">
                            <div className="form-control border-0 px-0">
                              <label><strong>Parent/Customer</strong></label>
                              <div className="form-control-wrap paymetsession">
                            <i
                              className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                              onClick={handleCustomerName}
                            ></i>
                              {/* <select value={parentid} className="form-select" onChange={handleParentList}>
                                <option value="">All Parents</option>
                                {
                                  getCustomerParentData && getCustomerParentData.map((val, index) => {
                                    return (<>
                                      <option value={val?.tbl_user?.id}>{val?.tbl_user?.firstName}{" "}{val?.tbl_user?.lastName}</option>
                                    </>)
                                  })
                                }
                              </select> */}

                              <Select
                              className="form-select form-selectparent"
                              autosize={true}
                              onChange={(e) => handleParentList(e)}
                              defaultValue={parentid}
                              options={[
                                { value: "", label: "All" },
                                ...(getCustomerParentData?.map((val) => ({
                                  value: val.tbl_user.id,
                                  label: val.tbl_user?.firstName + " " + val.tbl_user?.lastName,
                                })) || []),
                              ]}
                              openMenuOnFocus={userName}
                              ref={selectRefUserName}
                            />

                           </div>

                            </div></div>

                          <div className="col-lg-4">
                            <div className="form-control border-0 px-0">
                              <label><strong>Student Name</strong></label>
                              <select value={childId} className="form-select" onChange={handleChildId}>
                                <option value="">Student List</option>
                                {
                                  getChildlistData && getChildlistData.map((val, index) => {

                                    return (<>
                                      <option value={val.id}>{val.firstName} {"  "} {val.lastName}</option>
                                    </>)

                                  })
                                }
                              </select>
                            </div>
                          </div>

                        </>
                      }
                    </div>
                    <div className="row pb-2">
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Resource Type</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceType} value={resourceType} >
                            <option value=""> Select Resource Type</option>
                            {
                              getAllResourceNew.map((val, index) => {
                                return (<>
                                  <option value={val.id} key={index + 1}>{val.name}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div>

                     
                        {resourceType==="2" && (<>
                        <div className="col-lg-4">
                             <div className="outerTypeBox form-control border-0 px-0">
                                   <label> <strong> Type of Content </strong></label>
                                   <select
                              className="form-select"
                              name="curriculamContentType"
                              onChange={handleResourceType}
                              value={curriculamContentType}
                            >
                              <option value="">Select Content Type </option>

                              {contentTypeData &&
                                contentTypeData.map((val) => {
                                  return (
                                    <>
                                      <option value={val.id}> {val.name} </option>
                                    </>
                                  );
                                })}
                            </select>

                             </div>
                          </div>



                          <div className="col-lg-4">
                               <div className="outerTypeBox form-control border-0 px-0">
                                   <label> <strong> Instructional Style </strong></label>
                                      <select className="form-select"  onChange={handleInstructionalStyle} value={instructionalStyleType} >
                                        <option value=""> Select Instruction Style  </option>
                                        {
                                          getInstructionStyleResourcePage && getInstructionStyleResourcePage.map((val) => {
                                            return (<>
                                              <option value={val.id}> {val.name} </option>
                                            </>)
                                          })
                                        }
                                      </select>
                                  </div>
						                </div>
                            </>)}
						
                        {resourceType==="3" && (
						<div className="col-lg-4">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label> <strong>Type of Experience</strong></label>
                            <ul className="px-2 py-2" style={{ display: "none" }}>
                              <li>Type of Experience</li>
                              <li>Time frame</li>
                            </ul>
                            <select className="form-select" name="experienceType" onChange={handleResourceType} value={experienceType} >
                              <option value=""> Select Experience  </option>
                              {
                                getAllExperienceResourcePage && getAllExperienceResourcePage.map((val) => {
                                  return (<>
                                    <option value={val.id}> {val.name} </option>
                                  </>)
                                })
                              }
                            </select>
                          </div>
						  </div>
                        )}
                        
						
                        {resourceType==="7" && (
						<div className="col-lg-4">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label> <strong>Type of Facility</strong></label>
                            <select className="form-select" name="facilityType" onChange={handleResourceType} value={facilityType} >
                              <option value=""> Select Facility  </option>
                              {
                                getAllFacilityResourcePage && getAllFacilityResourcePage.map((val) => {

                                  return (<>
                                    <option value={val.id}> {val.name} </option>
                                  </>)
                                })
                              }
                            </select>
                          </div>
						  </div>
                        )}
						
                        {resourceType==="6" && (
						<div className="col-lg-4">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label> <strong>Type of Funding</strong></label>
                            <select className="form-select" name="fundingType" onChange={handleResourceType} value={fundingType} >
                              <option value=""> Select Funding  </option>
                              {
                                getAllFundingResourcePage && getAllFundingResourcePage.map((val) => {
                                  return (<>
                                    <option value={val.id}> {val.name} </option>
                                  </>)
                                })
                              }
                            </select>
                           </div>
                          </div>
                        )}

                        {resourceType==="1" && (
                           <div className="col-lg-4">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label> <strong> Type of Organization </strong></label>
                            <select className="form-select" name="organizationType" onChange={handleResourceType} value={organizationType} >
                              <option value=""> Select Organization  </option>
                              {
                                getAllOrganizationResourcePage && getAllOrganizationResourcePage.map((val) => {
                                  return (<>
                                    <option value={val.id}> {val.name} </option>
                                  </>)
                                })
                              }
                            </select>
                           </div>
                          </div>
                        )}

                        {resourceType==="4" && (
                         <div className="col-lg-4">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label> <strong>Type of Support </strong></label>
                            <select className="form-select" name="studentSupportType" onChange={handleResourceType} value={studentSupportType} >
                              <option value=""> Select Support  </option>
                              {
                                getAllStudentSupportResourcePage && getAllStudentSupportResourcePage.map((val) => {
                                  return (<>
                                    <option value={val.id}> {val.name} </option>
                                  </>)

                                })


                              }
                            </select>
                           </div>
                          </div>
                        )}

                        {resourceType==="5" && (
                          <div className="col-lg-4">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label> <strong> Type of Support </strong></label>
                            <select className="form-select" name="teacherParentSupportType" onChange={handleResourceType} value={teacherParentSupportType} >
                              <option value=""> Select Support  </option>
                              {
                                getTeacherParentSupportResourcePage && getTeacherParentSupportResourcePage.map((val) => {
                                  return (<>
                                    <option value={val.id}> {val.name} </option>
                                  </>)

                                })
                              }
                            </select>
                          </div>
                          </div>
                        )}

{resourceType === "5" && (
                        <div className="col-lg-4">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label>
                              {" "}
                              <strong> Customer Type</strong>
                            </label>
                            <select
                              className="form-select"
                              name="timeFrameTypeCariculam"
                              onChange={handleCustomerType}
                              value={customerType}
                            >
                              <option value=""> Select Customer Type </option>
                              <option value="teacher">Teacher </option>
                              <option value="parent">Parent </option>
                              <option value="other"> Other </option>
                            </select>
                          </div>
                        </div>
                      )}

                      

                        {resourceType==="2" && (
                         <div className="col-lg-4">
                          <div className="outerTypeBox form-control border-0 px-0">
                            <label> <strong> Time Frame</strong></label>
                            <select className="form-select" name="timeFrameTypeCariculam" onChange={handleResourceType} value={timeFrameTypeCariculam} >

                              <option value=""> Select Time Frame  </option>
                              <option value="flexible"> Flexible </option>
                              <option value="less than a semester"> Less than a semester </option>
                              <option value="semester"> Semester </option>
                              <option value="school year"> School year </option>
                            </select>
                            </div>
                          </div>
                        )}

                        {/* 
                      {resourceTypeText === "Experiences" && (

                        <div className="outerTypeBox form-control border-0 px-0">
                          <label> <strong> TimeFrame</strong></label>
                          <ul className="px-2 py-2" style={{ display: "none" }}>
                            <li>Type of Experience</li>
                            <li>Time frame</li>
                          </ul>
                          <select className="form-select" name="timeFrameTypeExperience" onChange={handleResourceType} value={timeFrameTypeExperience} >
                            <option value=""> Select TimeFrame  </option>
                            <option value="day"> Day/s </option>
                            <option value="week"> Week/s </option>
                            <option value="month"> Month/s </option>
                            <option value="year"> Year/s </option>
                          </select>

                        </div>
                      )} */}

                       

                     
                      {/* {resourceType==="2" ? */}
                        <div className="col-lg-4">

                          <div className="form-control border-0 px-0 ">
                            <label>
                              <strong>Start Date</strong>
                            </label>
                            {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                            <div className="form-control-wrap datesaved" ref={newRef}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedfromdate}
                                onChange={HandleFromDate}
                                onFocus={() => setDatePickerShow(!datepickershow)}
                                className="form-control date-picker"
                                open={datepickershow}
                              />
                            </div>
                          </div>
                          <span className="error">{formDateError}</span>
                        </div>

                     {/* :"" } */}

                      {/* {resourceType==="2" ? */}
                        <div className="col-lg-4">

                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>End Date</strong>
                            </label>
                            {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                            <div className="form-control-wrap datesaved" ref={newRef1}>
                              <div className="form-icon form-icon-left">
                                <span onClick={() => handleclickdatepickericon1()}>
                                  <Icon name="calendar"></Icon>
                                </span>
                              </div>
                              <DatePicker
                                placeholderText="MM/DD/YYYY"
                                selected={selectedtodate}
                                onChange={HandleToDate}
                                onFocus={() => setDatePickerShow1(!datepickershow1)}
                                className="form-control date-picker"
                                open={datepickershow1}
                              />
                            </div>
                          </div>
                          <span className="error">{toDateError}</span>
                        </div>

                    {/* :"" } */}
                        

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                          <label><strong>Learning Pillar</strong></label>
                          <div className="form-control-wrap paymetsession">
                            <i className="fa fa-chevron-down chevron-downcustome"   onClick={displaySelect}></i> 
                              <Select
                                className="form-select"
                                value={
                                  learningPillarIds && learningPillarIds?.length > 0
                                    ? learningPillarIds
                                    : null
                                }
                                options={learningPillarData}
                                isMulti
                                selectMultiple={true}
                                onChange={handleLearningPillar}
                                openMenuOnFocus={displayvalueSelect}
                                ref={selectRef}
                              /></div>
                             </div>
                        </div>


                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0" >
                          <label><strong>Rating</strong></label>
                          <select className="form-select" name="resourceType" value={rating} onChange={handlerating}>

                            <option value=""> -- Select a rating -- </option>
                            <option value="0">No rating</option>
                            {ratingdata.map((fruit) => <option value={fruit.value}>{fruit.label}</option>)}
                          </select>
                        </div>
                      </div>


                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0" >
                          <label><strong>Resource Status</strong></label>
                          <select className="form-select" name="resourceType" value={resourcestate} onChange={handlestate}>
                            <option value=""> Select Status  </option>
                            <option value="publish"> Publish </option>
                            <option value="unpublish"> Unpublish</option>
                          </select>

                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="form-control border-0 px-0" >
                          <label><strong>Resource Deleted</strong></label>
                          <select className="form-select" name="resourceType" value={resourcedeletestate} onChange={handledeletestate}>
                            <option value=""> Select Deleted  </option>
                            <option value="yes"> Yes </option>
                            <option value="no">No</option>
                          </select>

                        </div>
                      </div>






                      <div className="col-lg-4">
					  <label><strong>&nbsp;</strong></label>
                        <div className="outerTypeBox">
                      
						
                       <button className="btn btn-md btn-primary" type="submit" onClick={handleSearch}> Search </button>


                        </div>
                      </div>


                    </div>

                  </>}
                </div>
                <div className="outerBox newregisterGrouptable">

                  {getAllResource?.length > 0 ? (

                    <ReactDataTable
                      data={getAllResource}
                      columns={columns}
                      className="nk-tb-list"
                      // searchable={true}
                      setPageCount={setPageCount}
                    // paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}

                    // pagination
                    />

                  ) : ""}

                  <div className="p-4">
                    {getAllResource && getAllResource?.length > 0 ?
                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={count}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={'pagination'}
                          pageClassName={"page-item"}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          activeClassName={"active"}
                          forcePage={currentPage - 1}
                        />
                      </div>
                      : <div className="p-4">No Data Found</div>}
                  </div>
                </div>
              </>

            </div>
          </Card>
        )}
      </Content>
    </React.Fragment>
  );
};
export default ResourceReport;
