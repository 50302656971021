import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { useEffect,useRef } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import { DropdownItem, UncontrolledDropdown, DropdownMenu, DropdownToggle, CardTitle } from "reactstrap";
import {
  Block,
  BlockTitle,
  BlockBetween,
  BlockDes,
  Icon,
  Row,
  Col,
  Button,
  DataTableHead,
  DataTableBody,
  DataTableRow,
  DataTableItem,
  PaginationComponent,
  ReactDataTable,
  UserAvatar,
  PreviewCard,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Badge } from "reactstrap";
import { useHistory } from "react-router-dom";
 import Decimal from 'decimal.js';
import { makePostRequest, makeGetRequest } from "../../../service/api";
import ReactPaginate from "react-paginate";
import { AreaChart, Area, BarChart, Bar, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Label, LineChart, Line, LabelList } from "recharts";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { parseBusinessHours } from "@fullcalendar/core";
import Highcharts, { chart } from "highcharts";
import { findUpper, capital } from "../../../utils/Utils";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import HighchartsReact from 'highcharts-react-official';
// import exporting from "highcharts/modules/exporting";
import { ToastContainer, toast } from "react-toastify";
// exporting(Highcharts);
const ExcelJS = require('exceljs');
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


function CustomerSpaceReport() {

  let history = useHistory();
  const [activeTab, setActiveTab] = useState("1");
  const [activeIconTab, setActiveIconTab] = useState("5");
  const [activeAltTab, setActiveAltTab] = useState("9");
  //   const [verticalTab, setVerticalTab] = useState("1");
  const [verticalIconTab, setVerticalIconTab] = useState("1");
  const [customerspacereport, setCustomerspacereport] = useState([]);
  const [customerspacereportchart, setCustomerspacereportchart] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [displayvalue, setDisplayValue] = useState("1");
  const [startdate, setStartDate] = useState("2023-01-01");
  const [enddate, setEndDate] = useState(new Date());
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [user, setUser] = useState("");
  const [userNameData, setUserNameData] = useState([])
  const [userName, setNameUser] = useState("")
  const [limitvalue, setlimitvalue] = useState("")
  const [sortvalue, setSortValue] = useState("")
  const [showchart, setshowchart] = useState([])
  const [getAllResourceNew, setgetAllResourceNew] = useState([]);
  const [resourceType, setResourceType] = useState("");
  const [resourceTypes, setResourceTypeGraph] = useState("");
  const [resourceTypeText, setResourceTypeText] = useState("");
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);

  const [toDateError, settoDateError] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [customerSpaceData, setCustomerSpaceData] = useState([])
  const [isOpen, setIsOpen] = useState(false);
  const [displayvalueSelectGraph, setDisplayValueSelectGraph] = useState(true);
  const [displayvalueSelect, setDisplayValueSelect] = useState(true);
  const [clearStateGraph, setClearStateGraph] = useState(false);
  const [clearStateList, setClearStateList] = useState(false);
  
  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);

  const colorThemes = ["primary", "purple", "danger", "warning", "success", "pink", "secondary", "blue"]
  let chartdata = []
  let SpaceData = []


  const handleyear = (e) => {
    setSelectyear(e.target.value);
  }

  const HandleUserName = (e) => {
    setNameUser(e.value);

  }
  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  }
  //console.log(selectedfromdate, "selectedfromdate")

  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");

  }

  const handlechart = (e) => {
    getcustomerspacereportchart();
    // setNameUser("");
  }
  const HandleLimitValue = (e) => {

    setlimitvalue(e.target.value)
    setCurrentPage(1)
  }
  const HandleSort = (e) => {
    setSortValue(e.target.value);
    setCurrentPage(1)
  }
  function handleclickdatepickericon() {


    setDatePickerShow(!datepickershow);


  }
  function handleclickdatepickericon1() {


    setDatePickerShow1(!datepickershow1);


  }
  const handleResourceTypeGraph = (e) => {
   
      setResourceTypeGraph(e.target.value);
    

  }
  const handleResourceType = (e) => {
   
      setResourceType(e.target.value);

  }

  const clearFilterGraph = () => {
    clearFilterGraphFun();
  }

  const clearFilterGraphFun = () => {
    setNameUser("");
    setResourceType("");
    selectRefGraph.current.select.setValue("","select-option")
    setSelectyear(new Date().getFullYear());
	setResourceTypeGraph("");
    setClearStateGraph(!clearStateGraph);
    
  }
  const clearFilterList = () => {
    clearFilterListFun();
  }

  const clearFilterListFun = () => {
    setNameUser("");
    
    setlimitvalue("");
    setSortValue("");
    setResourceType("");
    selectRef.current.select.setValue("","select-option")
    setCurrentPage(1);
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setClearStateList(!clearStateList);
  }

  const ExportEcelFile = () => {

    setLoader(true);

    let data = {

      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userName ? userName : "",
      resource_type: resourceType ? resourceType : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : "",
      export_data: "yes"
    };

console.log(data,"dataxle")

    makePostRequest(URLS.GET_CUSTOMER_SPACE_REPORTSLIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          //console.log("space export", res.data.list_data)
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("SpaceData");
          sheet.properties.defaultRowHeight = 30;



          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          }

          sheet.columns = [
            {
              header: "S.No",
              key: 'S_No',
              width: 5
            },

            {
              header: "Customer Name",
              key: 'USER_NAME',
              width: 30
            },
            {
              header: "Saved Date",
              key: 'saveddate',
              width: 30
            },

            {
              header: "Email",
              key: 'OWNEREMAIL',
              width: 30
            },
            {
              header: "Phone",
              key: 'OWNWERPHONE',
              width: 30
            },

            {
              header: "Total space used(MB)",
              key: 'total_space_used',
              width: 20
            },


          ];
          res.data.list_data?.map((val, i) => {
            sheet.addRow({
              S_No: (i + 1),
              saveddate: val.saved_date,
              USER_NAME: val?.tbl_user?.firstName + " " + val.tbl_user?.lastName,
              OWNEREMAIL: val?.tbl_user?.email,
              OWNWERPHONE: val?.tbl_user?.phone,
              total_space_used: (val?.total_space_used/1024).toFixed(2),


            })

          })

          workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            })
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'SpaceReport.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url)
          })
          setLoader(false);
         // console.log(res.data, "res g")

        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const getAllUserType = () => {
    setLoader(true);
    makeGetRequest(URLS.GET_ALL_RESOURCE_TYPE, {}, null)
      .then(res => {
        if (res.code == 200) {
          setgetAllResourceNew(res.data);
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      })

  }

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const viewSpaceDetails = (row) => {

    let data = {

      "used_date": row.saved_date,
      "user_id": row.tbl_user.id,
      "resource_type": resourceType ? resourceType : ""

    }
    //console.log("space details payload", data)
    makePostRequest(URLS.GET_SPACE_DETAILS, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          //console.log("space details", res)
          setCustomerSpaceData(res.data)
          setIsOpen(!isOpen);
        }
        else {
          toast.error(res.message)
        }


      })




  }

  const handlesearch = (e) => {
    // setLoader(true);

    // let data = {
    //   start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "2023-01-01",
    //   end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : moment(new Date()).format("YYYY-MM-DD"),
    //   user_id: userName ? userName : "",
    //   page_number:currentPage?currentPage:1,
    //   item_per_page:limitvalue?parseInt(limitvalue):10,

    // };

    // console.log(data, "data")
    // makePostRequest(URLS.GET_CUSTOMER_LOGGEDIN_REPORTSLIST, null, data, null)
    //   .then((res) => {
    //     // setLoader(true)
    //     if (res.code === 200) {
    //       console.log(res.data.user_data, "login data");
    //        setCustomerloginreport(res?.data?.user_data);
    //       setLoader(false);

    //     } else {
    //       setLoader(false);
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // // getcustomerloginreportchart();
    // setNameUser("");
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date")
    }
    else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ")
    }
    else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date")
    }
    else {

      getcustomerspacereport();
      getcustomerspacetotalreport();
      setCurrentPage(1)
    }
  }


  //user type get api GET_USER_TYPE
  const getUserName = () => {
    makePostRequest(URLS.GET_ALL_ONLY_CUSTOMER, null, {}, null)
      .then((res) => {
       // console.log(res)
        if (res.code == 200) {
          let option = [];
          option.push({ value: "", label: "All Users" })
          res.data.length > 0 && res.data.map((val) => {
            option.push({ value: val.id, label: val.firstName + " " + val.lastName })
          })
          setUserNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getcustomerspacereportchart = () => {
    setLoader(true);
    let data = {
      year: selectyear,
      user_id: userName ? userName : "",
      resource_type: resourceTypes ? resourceTypes : ""
    };
    //console.log("data chart:::", data)
    makePostRequest(URLS.GET_CUSTOMER_SAPCE_CHARTS, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          //console.log(res, "space chart data");
          setCustomerspacereportchart(res?.data?.graph_data);
          setLoader(false);

        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }


  /*==============================Space  report List Api ==============================*/

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const getcustomerspacereport = () => {
    setLoader1(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userName ? userName : "",
      resource_type: resourceType ? resourceType : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      export_data: "no",
      sort_by: sortvalue ? sortvalue : ""
    };
    console.log(data, "data")
    makePostRequest(URLS.GET_CUSTOMER_SPACE_REPORTSLIST, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          //console.log(res, "space  data list");
          setCustomerspacereport(res?.data.list_data);
          setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)))
          setLoader1(false);

        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // setNameUser("");
  }

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1)
  };
  // const fetchData = () => {
  //   let data = {

  //     start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
  //     end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
  //     user_id: userName ? userName : "",
  //     resource_type: resourceType ? resourceType : "",
  //     page_number: currentPage ? currentPage : 1,
  //     item_per_page: limitvalue ? parseInt(limitvalue) : 10,
  //     export_data: "no",
  //     sort_by: sortvalue ? sortvalue : ""
  //   }
  //   makePostRequest(URLS.GET_CUSTOMER_SPACE_REPORTSLIST, null, data, null)
  //     .then((res) => {
  //       if (res.code == 200) {
  //         //console.log(res, "space  data list")
  //         setCustomerspacereport(res?.data.list_data);
  //         setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)))
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };

  useEffect(() => {
    //fetchData()
    getcustomerspacereport();
  }, [currentPage, limitvalue, sortvalue])


  useEffect(() => {
    getcustomerspacereportchart();
  }, [clearStateGraph]);

 useEffect(() => {
  getcustomerspacereport();
  }, [clearStateList]);






  useEffect(() => {
    getcustomerspacereport();
    getcustomerspacereportchart();
    getUserName();
    getAllUserType();
  }, []);


  // useEffect(() => {
  //   getcustomerspacereport();
  // }, [limitvalue, sortvalue])

  useEffect(() => {
    getchartdata();
  }, [customerspacereportchart])



  const getchartdata = () => {
    customerspacereportchart?.map((val) => {
      chartdata.push(parseFloat((val.total_space_used / 1024).toFixed(2)))

    })
    setshowchart(chartdata)

  }
  //  console.log(showchart,"showchart")
  /*===================================Api call for Total Space===================*/

  const [totalSpaceAdd, setTotalSpaceAdd] = useState([])
  const getcustomerspacetotalreport = () => {

    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      user_id: userName ? userName : "",
      resource_type: resourceType ? resourceType : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      sort_by: sortvalue ? sortvalue : "",
      export_data: "yes",
     
    };
    //console.log(data, "data")
    makePostRequest(URLS.GET_CUSTOMER_SPACE_REPORTSLIST, null, data, null)
      .then((res) => {
        // setLoader(true)
        if (res.code === 200) {
          //console.log(res, "space  data list");
          setTotalSpaceAdd(res?.data.list_data);


        } else {
          setLoader1(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });

  }

  useEffect(() => {
    getcustomerspacetotalreport();
  }, [])



  
  let [totalSpace, setTotalSpace] = useState([]);
  let sum = 0;
  const TotalSpace = () => {
   
    totalSpaceAdd?.map((temp) => {
      SpaceData.push(parseFloat((temp.total_space_used / 1024).toFixed(2)))
    })
    setTotalSpace(SpaceData)
    //console.log(totalSpace, "total space")
  }
 // console.log(totalSpace, "total space")
  totalSpace.forEach(val => {
    sum += val;
  })

  //console.log(sum, "total")
  useEffect(() => {
    TotalSpace();
  }, [totalSpaceAdd])




  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div>
        </>
      ),
    },
    {
      name: "Customer Name",
      selector: (row) => row?.tbl_user,
      grow:4,
      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">
          {/* <UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(capital(row.tbl_user.firstName + " " + row.tbl_user.lastName))} ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">
                {row?.tbl_user ? (row.tbl_user.firstName!=""?capital(row.tbl_user.firstName.trim()):"") + " " + (row.tbl_user.lastName?capital(row.tbl_user.lastName):"" ): "--"}<br />
              </span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Email/Phone Number",
      selector: (row) => row.month,
      grow: 6,

      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">
                {row?.tbl_user?.email ? row.tbl_user.email : ""}<br />
              </span>
              {/* {row?.tbl_user?.phone ? row.tbl_user.phone : ""} */}
              <div className="sub-phoneinp-text">
              <PhoneInput
            country={row?.tbl_user?.country_dial_code?.toUpperCase() || ""}
            value={(row?.tbl_user?.countryCode + row?.tbl_user?.phone)}
            placeholder="--"
            inputClass="minimal-input"
            buttonStyle={{ display: "none" }}
            inputStyle={{
              border: "none",
              padding: "0",
              background: "none",
              fontSize: "12px",
              width: "100%",
              outline: "none",
              color: "#8094ae",
              cursor: "pointer",
              // position: "relative",
              // top: "-56px",
            }}
            containerStyle={{
              border: "none",
              padding: "0",
            }}
            disabled={true}
          />
            </div>
            </div>
          </div>
        </>
      ),
    },


    {
      name: "Saved Date",
      selector: (row) => row.saved_date,
      grow: 2,

      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">

            {row.saved_date ? moment(row.saved_date).format("MM-DD-YYYY") : "--"}
          </div>
        </>
      ),
    },

    {
      name: " Total Space Used(MB)",

      grow: 2,
      minWidth: "180px",
     center:true,
      sortable: true,
      cell: (row) => (
        <div  className="user-card mt-2 mb-2"> {row.total_space_used ? (row.total_space_used / 1024).toFixed(2) : "--"} </div>
      ),
    },
    {
      name: "Used Space Details",

      grow: 3,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">  <span><button
          className="btn btn-md"
          onClick={() => {
            viewSpaceDetails(row);
          }}
        >
          <em className="icon ni ni-eye"></em>
        </button>
        </span> </div>
      ),
    },

  ]
  const columnsshow = [
    {
      name: "#",
      selector: (row) => row.id,
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div>
        </>
      ),
    },
    {
      name: "Customer Name",
      selector: (row) => row?.tbl_user,
      grow:4,
      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">
          {/* <UserAvatar theme={colorThemes[Math.floor(Math.random() * colorThemes.length)]} text={findUpper(capital(row.tbl_user.firstName + " " + row.tbl_user.lastName))} ></UserAvatar> */}
            <div className="user-info">
              <span className="tb-lead">
                {row?.tbl_user ? (row.tbl_user.firstName!=""?capital(row.tbl_user.firstName):"") + " " + (row.tbl_user.lastName?capital(row.tbl_user.lastName):"" ): "--"}<br />
              </span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Email/Phone Number",
      selector: (row) => row.month,
      grow: 6,

      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">
                {row?.tbl_user?.email ? row.tbl_user.email : ""}<br />
              </span>

              {row?.tbl_user?.phone ? row.tbl_user.phone : ""}
            </div>
          </div>
        </>
      ),
    },


    {
      name: "Saved Date",
      selector: (row) => row.saved_date,
      grow: 2,

      cell: (row, index) => (
        <>
          {/* <div className="user-card mt-2 mb-2"> {currentPage==1?(index + 1):((currentPage-1)*(limitvalue!=""?parseInt(limitvalue):10))+(index + 1)} </div> */}
          <div className="user-card mt-2 mb-2">

            {row.saved_date ? moment(row.saved_date).format("MM-DD-YYYY") : "--"}
          </div>
        </>
      ),
    },
    {
      name: "Resource Type",
      grow: 2,
      minWidth: "150px",
      style: { minWidth: "150px" },
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2"> {row?.tbl_resource_type_master?.name ? row?.tbl_resource_type_master?.name : "--"} </div>
      ),
    },


    {
      name: " Total Space Used(MB)",

      grow: 2,
      minWidth: "180px",
     center:true,
      sortable: true,
      cell: (row) => (
        <div  className="user-card mt-2 mb-2"> {row.total_space_used ? (row.total_space_used / 1024).toFixed(2) : "--"} </div>
      ),
    },
    {
      name: "Used Space Details",

      grow: 3,

      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">  <span><button
          className="btn btn-md"
          onClick={() => {
            viewSpaceDetails(row);
          }}
        >
          <em className="icon ni ni-eye"></em>
        </button>
        </span> </div>
      ),
    },

  ]
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };
  const toggleAltTab = (alttab) => {
    if (activeAltTab !== alttab) setActiveAltTab(alttab);
  };

    const newRef = useRef(null);
	const newRef1 = useRef(null);
	const selectRefGraph = useRef(null);
	const selectRef = useRef(null);
	
	 const displaySelect = (e)=> {
    if (selectRef.current )
    {
     setDisplayValueSelect(!displayvalueSelect);
    selectRef.current.focus();
    }
  }
  
	 const displaySelectGraph = (e)=> {
    if (selectRefGraph.current )
    {
    setDisplayValueSelectGraph(!displayvalueSelectGraph);
    selectRefGraph.current.focus();
    }
  }
	
  const handleOutSideClick = (e)=> {
	 if(newRef.current && !newRef.current.contains(e.target)){
         setDatePickerShow(false);
        	 
	 }		 
  }
    const handleOutSideClick1 = (e)=> {
	 if(newRef1.current && !newRef1.current.contains(e.target)){
           setDatePickerShow1(false);		 
	 }		 
  }
  useEffect(()=>{
	 document.addEventListener("mousedown", handleOutSideClick );
	 document.addEventListener("mousedown", handleOutSideClick1 );
      return ()=> {
       document.removeEventListener("mousedown", handleOutSideClick );
	   document.removeEventListener("mousedown", handleOutSideClick1 );
	  }	   
  });
 

// Assuming val?.space_used_by_resource is a valid number

  // let values = []; // Array to store calculated values
  // let formattedValue;
  // {customerSpaceData && customerSpaceData.map((val, i) => {
  //   // const calculatedValue = val?.space_used_by_resource / 1024;
  //   // const formattedValue = calculatedValue.toFixed(2);
  //   // values.push(parseFloat(formattedValue)); // Store the formatted value in the array
  
  //   const desiredDecimalPlaces = 2; // Set your desired decimal places
  //   const originalValue = values?.space_used_by_resource / 1024;
  //   formattedValue = originalValue?.toFixed(desiredDecimalPlaces);
   
  //  while (formattedValue?.split('.')[1]?.length !== desiredDecimalPlaces) {
  //    if (formattedValue?.split('.')[1]?.length < desiredDecimalPlaces) {
  //      // If fewer decimal places, add one
  //      formattedValue = (originalValue + 0.1 ** (desiredDecimalPlaces + 1)).toFixed(desiredDecimalPlaces);
  //    } else {
  //      // If more decimal places, remove one
  //      formattedValue = (originalValue - 0.1 ** (desiredDecimalPlaces + 1)).toFixed(desiredDecimalPlaces);
  //    }
  //  }
  //  console.log(formattedValue,"formated value");
  // })}
  // console.log(values,"value")
  // console.log(formattedValue,"formated value");
  

  
  





  return (

    <React.Fragment>


      <Head title="Space Used Report"></Head>
      <Content>

        <h3 className="nk-block-title page-title mb5 pb-4">Space Used Report</h3>


        {/* {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "250px" }} >
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : ( */}
        <>
          <Card className="card-bordered">
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Space Used Details
              </ModalHeader>
              <Card className="card-bordered">
                <div className="space-used-details">
                  <div className="card-inner">
                    <BlockHeadContent>
                      <BlockHead size="lg">
                        <div className="customerDetailsTable">
                          <table >
                            <tr className="border-bottom">
                            <th>#</th>
                              <th width="50%">Resource Title </th>
                              <th width="100%" align="right">Mode</th>


                              <th width="100%" align="right">Space Used by Customer(MB)</th>
                            </tr>
                            {customerSpaceData && customerSpaceData.map((val, i) => {
                              return (
                                <tr className="border-bottom">
                                  <td>{i + 1}</td>
                                  <td>{val?.resource_title}</td>
                                  <td>{val?.resource_mode}</td>
                                  <td>{(val?.space_used_by_resource / 1024).toFixed(2)}</td>
                                  {/* <td>{(Math.round((val?.space_used_by_resource / 1024) * 100) / 100).toFixed(2)}</td> */}


                                </tr>
                              )

                            })}

                          </table>
                        </div>
                      </BlockHead>
                    </BlockHeadContent>
                  </div>
                </div>
              </Card>
              <ModalFooter className=""></ModalFooter>
            </Modal>
            <div className="card-inners relativeClass">






              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                  <div className="p-4">
                    <div className="filtersidebutton">
                      <h3 className="graphheading  mb-0 mt-1">Graph View</h3>
					  <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterGraph} >Clear Filters</button>
                    </div>
                    {/* <div class="d-flex justify-content-end g-2">
                  <div class="dataTables_length" id="DataTables_Table_0_length">*/}
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Select Year</strong>
                          </label>

                          <div className="form-control-wrap">
                            <select className="form-select" value={selectyear} onChange={handleyear} >
                              {
                                years.map((year, index) => {
                                  return <option key={`year${index}`} value={year}>{year}</option>
                                })
                              }
                            </select>

                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Customer Name</strong>
                          </label>

                          {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                          <div className="form-control-wrap paymetsession">
                           <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelectGraph}></i> 
                            <Select
                            className="form-select"
                              defaultvalue={userName}
                              options={userNameData}
                              openMenuOnFocus={displayvalueSelectGraph}
                              onChange={HandleUserName}
							  ref={selectRefGraph}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Resource Type</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceTypeGraph} value={resourceTypes} >
                            <option value=""> Select Resource Type</option>
                            {
                              getAllResourceNew.map((val, index) => {
                                return (<>
                                  <option value={val.id} key={index + 1}>{val.name}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div>

                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
						<label className="w-100 mb-0">&nbsp;</label>
                          <button className="btn btn-primary" type="submit" onClick={handlechart}>
                            {" "}
                            View{" "}
                          </button>

                        </div>
                      </div>

                     </div>
                    </div>


                    <div className="px-4">
                      <div className="row">
                        <div className="col-lg-6">

                        </div>

                      </div>
                      <div className="relativeClass">
                        {loader ? (
                          <div className="overflowloader" id="preload">
                            <div className="circle-square" >
                              <div className="red" style={{ background: "#AAC0CE" }}></div>
                              <div className="blue" style={{ background: "#265472" }}></div>
                              <div className="green" style={{ background: "#F19357" }}></div>
                              <div className="yellow" style={{ background: "#F0B285" }}></div>
                            </div>
                          </div>
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: 'line'
                              },
                              title: {
                                text: 'Monthly Space Used Chart',
                                style: { "color": "#003C71", "fontSize": "25px" }
                              },

                              xAxis: {
                                categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                                labels: {
                                  style: {
                                    color: '#364a63'
                                  }
                                }
                              },
                              plotOptions: {
                                line: {
                                  dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                      return (this.y != 0) ? this.y : "";
                                    }
                                  },
                                  enableMouseTracking: true
                                }
                              },
                              credits: {
                                enabled: false
                              },

                              yAxis: {
                                title: {
                                  text: 'Space Used in MB',
                                  style: {
                                    color: '#364a63'
                                  }
                                },
                                labels: {
                                  style: {
                                    color: '#364a63'
                                  }
                                }


                              },
                              series: [{
                                name: "Month",
                                data: showchart,
                                color: "rgb(38, 84, 114)"


                              }]
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="p-4">
                    <div className="d-flex justify-content-between mb-2">
                      <div className="filtersidebutton">
                          <h3 className="graphheading mb-0 mt-1">List View</h3>
						  <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList} >Clear Filters</button>
                        </div>
                      <div className="row">
                        <div className="col-lg-12 d-flex  justify-content-end">

                          <select value={sortvalue} className="form-select" style={{ width: "190px" }} onChange={HandleSort} >
                            <option value="">Sort By</option>
                            <option value="ASCDATE">Date Ascending</option>
                            <option value="DESCDATE">Date Descending</option>
                            <option value="ASCCOUNT">Space used  Ascending</option>
                            <option value="DESCCOUNT">Space used  Descending</option>



                          </select>

                          <div><label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label></div>
                          <div><select value={limitvalue} className="form-select" style={{ width: "85px" }} onChange={HandleLimitValue}>
                            {/* <option value="5">5</option> */}
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          </div>
                          <div>
                            &nbsp; &nbsp;
                            <button className="btn btn-primary " onClick={ExportEcelFile}>
                              <em className="icon ni ni-download-cloud"></em><span>Export</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row pb-2">
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Customer Name</strong>
                          </label>

                          {/* <input type="date" name ="formdate"  className="form-control" value={selectedfromdate} onChange={HandleFromDate} /> */}
                          <div className="form-control-wrap paymetsession spacereport">
                           <i className="fa fa-chevron-down  chevron-downcustome"   onClick={displaySelect}></i> 
                            <Select
                            className="form-select"
                              defaultvalue={userName}
                              options={userNameData}
                               openMenuOnFocus={displayvalueSelect}
                              onChange={HandleUserName}
							  ref={selectRef}
                            />
                          </div>
                        </div>
                        {/* <span className="error">{toDateError}</span> */}
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                          <div className="form-control-wrap"  ref={newRef}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdate}
                              onChange={HandleFromDate}
                              onFocus={() => setDatePickerShow(!datepickershow)}
                              className="form-control date-picker"
                              open={datepickershow}
                            />
                          </div>
                        </div>
                        <span className="error">{formDateError}</span>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>End Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                          <div className="form-control-wrap"  ref={newRef1}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon1()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodate}
                              onChange={HandleToDate}
                              onFocus={() => setDatePickerShow1(!datepickershow1)}
                              className="form-control date-picker"
                              open={datepickershow1}
                            />
                          </div>
                        </div>
                        <span className="error">{toDateError}</span>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0" style={{ marginBottom: "10px" }}>
                          <label><strong>Resource Type</strong></label>
                          <select className="form-select" name="resourceType" onChange={handleResourceType} value={resourceType} >
                            <option value=""> Select Resource Type </option>
                            {
                              getAllResourceNew.map((val, index) => {
                                return (<>
                                  <option value={val.id} key={index + 1}>{val.name}</option>

                                </>)
                              })

                            }
                          </select>
                        </div>
                      </div>

                    </div>
                    <div className="d-flex flex-row" style={{ gap: "10px" }}>
                      {/* <div className="col-lg-2 d-flex "> */}
                      <div className="outerTypeBox mb-3">
                        <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                          {" "}
                          Search{" "}
                        </button>

                        {/* <button className="btn btn-md btn-primary mt-2" type="submit" onClick={clearfilter}>Clearfilter</button> */}
                        {/* </div> */}
                      </div>
                      {/* <div className="col-lg-2 d-flex "> */}
                      {/* <div className="outerTypeBox mb-3">
                          <button className="btn btn-primary" onClick={ExportEcelFile}>
                            <em className="icon ni ni-download-cloud"></em><span>Export</span>
                          </button>
                        </div> */}
                      {/* </div> */}
                    </div>
                    </div>
                    <div className="relativeClass newregisterGrouptable">
                      {loader1 ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : ("")}

                     
                      {customerspacereport && customerspacereport?.length > 0 ? (
                        <div>

                        {customerspacereport?.some(obj=>Object.keys(obj).includes("tbl_resource_type_master"))? 
                          (
                          <div className="color">
                            <ReactDataTable
                              data={customerspacereport}
                              columns={columnsshow}
                              className="nk-tb-list"
                            />
                          </div>)
                          :
                          (
                          <div className="color">
                            <ReactDataTable
                              data={customerspacereport}
                              columns={columns}
                              className="nk-tb-list"
                            />
                            </div>)
                          }
                        </div>
                      ) : <p className="p-4" style={{ minHeight: "100px" }}>No Data Found</p>}
                    


                    </div>
					
                    <div className="paginationwithtotal px-2 mb-3">
                    {customerspacereport?.length > 0 ?
                      <div className="float-start">
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={count}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={'pagination'}
                          pageClassName={"page-item"}
                          activeClassName={'active'}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                          forcePage={currentPage - 1}
                        />
                      </div>
                      : ""}
					  
					  <div className="float-end">
					   <h2 className="nk-block-title page-title text-end pe-3" >Total Space Used: {sum ? sum.toFixed(2) : "0"}MB</h2>
					   </div>
                      </div>
					
                  </BlockHead>
                </BlockHeadContent>
              </div>

            </div>
          </Card>
        </>
        {/* )} */}
      </Content>
    </React.Fragment>
  );
}
export default CustomerSpaceReport;
