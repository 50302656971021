import React, { useState, useRef } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent, Icon } from "../../../components/Component";
import { ReactDataTable } from "../../../components/Component";
import { Card, Input } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import Select from "react-select";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { S3_BUCKET_URL } from "../../../constants";
import DatePicker from "react-datepicker";
const ExcelJS = require("exceljs");
import ReactPaginate from "react-paginate";
import { FaFilter } from "react-icons/fa";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

function PurchaseHistory() {
  const [purchaseData, setPurchaseData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [getCustomer, setGetCustomer] = useState("");
  const [getSellerType, setSellerType] = useState("");
  const [childparentdata, setchildparentdata] = useState([]);
  const [childparentdata2, setchildparentdata2] = useState([]);
  const [selectedResourse, setselectedResourse] = useState();
  const [resourceDropdown, setResourceDropdown] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState([]);
  const [selectedfromdate, setSelectedfromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [search, setSearch] = useState("");
  const [parentid, setparentid] = useState("");
  const [dropdownPage, setDropdownPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isPayOpen, setPayOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  let [count, setCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [limitvalue, setlimitvalue] = useState("");
  const [sortpurchase, setSortPurchase] = useState("");
  const [remaining, setRemaining] = useState(0);
  const [stripeBalance, setStripeBalance] = useState(0);
  const [payAmount, setPayAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [purchaseBalance, setPurchaseBalance] = useState(0);
  const [transferamount, setTransferAmount] = useState([]);
  const [errorpay, setErrorPay] = useState(false);
  const [formDateError, setformDateError] = useState("");
  const [toDateError, settoDateError] = useState("");
  const [displayvalueSelectFilter, setDisplayValueSelectFilter] = useState(true);
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [clearState, setClearState] = useState(false);

  const handleAction = (data) => {
    setModalData(data);
    setIsOpen(true);
  };
  console.log(getCustomer, "getCustomer");

  const openpaymentModal = (pay) => {
    gettransactions(pay);
    setPayOpen(!isPayOpen);
    setPayAmount(pay);
  };
  const gettransactions = (pay) => {
    let data = {
      payment_id: pay.tbl_payment_history?.id ? pay.tbl_payment_history.id : "",
    };
    //console.log("data",data)
    makePostRequest(URLS.GET_TRANSFER_HISTORY, null, data, null).then((res) => {
      //console.log("Transfer details", res)
      if (res.code == 200) {
        setTransferAmount(res.data);
      }
    });
  };
  const handleamount = (e) => {
    setAmount(e.target.value);
    setErrorPay(false);
  };

  const handlePayment = () => {
    if (amount == "") {
      setErrorPay(true);
    } else {
      setLoader(true);
      let data = {
        resource_purchase_id: payAmount?.id ? payAmount?.id : "",

        trasfer_amount: parseFloat(amount) ? parseFloat(amount) : 0.0,
      };
      //console.log("data",data)
      makePostRequest(URLS.TRANSFER_RESOURCE_AMOUNT, null, data, null).then((res) => {
        //console.log("transfer", res);
        if (res.code === 200) {
          toast.success(res.message);
          setTimeout(() => {
            setPayOpen(!isPayOpen);
            getPurchaseHistoryData();
            getpurchasebalance();
          }, 6000);
        } else {
          setLoader(false);
          toast.error(res.message);
        }
      });
    }
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    // sessionStorage.setItem("limitvalueresourcepurchase",e.target.value)
    setCurrentPage(1);
  };
  const HandleSortPurchase = (e) => {
    setSortPurchase(e.target.value);
    // sessionStorage.setItem("sortvalueresourcepurchase",e.target.value)
    setCurrentPage(1);
  };

  useEffect(() => {
    getPurchaseHistoryData();
  }, [sortpurchase, limitvalue]);

  const getpurchasebalance = () => {
    // let data = {
    //   "payment_for":"resource_purchased"
    // }
    makePostRequest(URLS.GET_RESOURCE_BALANCE, null, null, null).then((res) => {
      if (res.code == 200) {
        //console.log("purchase Balance",res)
        setPurchaseBalance(res.data.total_amount);
      }
    });
  };

  const getResourceDropdown = () => {
    makePostRequest(URLS.RESOURCE_PUR, null, { page_number: dropdownPage }, null)
      .then((res) => {
        //console.log("response1", res);
        if (res.code == 200) {
          setResourceDropdown((prevOptions) => [...prevOptions, ...res?.data?.data]);
          setDropdownPage((prevPage) => prevPage + 1);
          setRemaining(res.data.remaining_count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //console.log("resourceDropdown", resourceDropdown);

  useEffect(() => {
    getResourceDropdown();
  }, []);

  const handleMenuScroll = (event) => {
    const { target } = event;
    if (remaining > 0)
      if (target.scrollTop === target.scrollHeight - target.clientHeight) {
        getResourceDropdown();
      }
  };

  const getcustomerdata = () => {
    makePostRequest(URLS.RESOURCE_BUYER, null, { page_number: "" }, null)
      .then((res) => {
        console.log(res, "buyer");

        if (res.code == 200) {
          setchildparentdata(res.data?.data);
          // setchildparentdata((prevOptions) => [...prevOptions, ...res?.data?.data]);
          // setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getcustomerdata2 = () => {
    makePostRequest(URLS.RESOURCE_SELLER, null, { page_number: "" }, null)
      .then((res) => {
        if (res.code == 200) {
          console.log(res, "saller");
          // setchildparentdata2(res.data?.data);
          setchildparentdata2((prevOptions) => [...prevOptions, ...res?.data?.data]);
          // setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getstripebalance = () => {
    makeGetRequest(URLS.GET_STRIPE_BALANCE, null, null, null).then((res) => {
      if (res.code == 200) {
        //console.log("stripe Balance",res)
        setStripeBalance(res.data.balanceAmount);
      }
    });
  };

  useEffect(() => {
    getcustomerdata();
    getcustomerdata2();
    getpurchasebalance();
  }, []);

  // const handleCustomer = (e) => {
  //   setGetCustomer(e.target.value);
  // };
  const ShowHideSelect = () => {
    setShow(!show);
  };

  // const HandleCustomer = (e) => {
  //   setSelectedCustomer(e.target.value);
  // };

  const handleSeller = (e) => {
    setSellerType(e.target.value);
  };

  const HandleSubscriptionPlan = (e) => {
    setSelectedPlan(e.target.value);
  };
  const HandleFromDate = (e) => {
    setSelectedfromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  };
  const HandleToDate = (e) => {
    //console.log(e)
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
  };
  const falsyToZero = (value) => {
    let result = Number(value);
    if (Number.isNaN(result)) {
      result = 0;
    }

    return result;
  };
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }

  useEffect(() => {
    getPurchaseHistoryData();
  }, [sortpurchase, limitvalue]);
  const ExportEcelFile = () => {
    setLoader(true);
    const body = {
      searchKey: search,
      resource_id: selectedResourse?.value ?? "",
      buyer_id: getCustomer?.value ?? "",
      seller_id: parentid?.value ?? "",
      start_date: selectedfromdate ?? "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, "d").format("YYYY-MM-DD") : "",
      limit: limitvalue ? limitvalue : 10,
      sortby: sortpurchase ? sortpurchase : "",

      resource_type: getSellerType ? getSellerType : "",
      key: "export",
    };

    //console.log("body",body)
    makePostRequest(URLS.GET_ALL_RESOURCES_PURCHASE_EXPORT, null, body, null)
      .then((res) => {
        // console.log("export data", res);
        if (res?.code == 200) {
          // setPurchaseData(res.data?.data);
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("ResourcePurchaseData");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "PURCHASE DATE",
              key: "PURCHASE_DATE",
              width: 15,
            },
            {
              header: "BUYER",
              key: "BUYER",
              width: 15,
            },

            {
              header: "BUYER EMAIL",
              key: "BUYER_EMAIL",
              width: 15,
            },
            {
              header: "BUYER PHONE",
              key: "BUYER_PHONE",
              width: 15,
            },
            {
              header: "SELLER",
              key: "SELLER",
              width: 15,
            },
            {
              header: "SELLER EMAIL",
              key: "SELLER_EMAIL",
              width: 15,
            },
            {
              header: "SELLER PHONE",
              key: "SELLER_PHONE",
              width: 15,
            },
            {
              header: "RESOURCE TITLE",
              key: "RESOURCE_TITLE",
              width: 15,
            },
            {
              header: "($)PRICE",
              key: "PRICE",
              width: 15,
            },
            {
              header: "($)TRANSACTION FEES",
              key: "TRANSACTION_FEES",
              width: 20,
            },
            {
              header: "($)TAX AMOUNT",
              key: "TAX_AMOUNT",
              width: 20,
            },
            {
              header: "($)AMOUNT",
              key: "AMOUNT",
              width: 15,
            },
            {
              header: "($)MATCHED REVENUE",
              key: "MATCHED_REVENUE",
              width: 20,
            },
            {
              header: "($)OWNER PAYMENT",
              key: "OWNER_PAYMENT",
              width: 20,
            },
            {
              header: "TRANSACTION ID",
              key: "TRANSACTION_NUMBER",
              width: 20,
            },
            {
              header: "INVOICE NUMBER",
              key: "INVOICE_NUMBER",
              width: 20,
            },
            {
              header: "($)TRANSFER TO OWNER",
              key: "TRANSFER_TO_OWNER",
              width: 20,
            },
          ];

          res?.data?.data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              PURCHASE_DATE: moment(val.created_at).format("MM-DD-YYYY"),
              BUYER: val.tbl_user.firstName + " " + val.tbl_user.lastName,
              BUYER_EMAIL: val.tbl_user.email ? val.tbl_user.email : "--",
              BUYER_PHONE: val.tbl_user.phone ? val.tbl_user.phone : "--",
              SELLER: val.tbl_user_resource?.tbl_user?.firstName + " " + val.tbl_user_resource?.tbl_user?.lastName,
              SELLER_EMAIL: val.tbl_user_resource?.tbl_user?.email ? val.tbl_user_resource?.tbl_user?.email : "--",
              SELLER_PHONE: val.tbl_user_resource?.tbl_user?.phone ? val.tbl_user_resource?.tbl_user?.phone : "--",
              RESOURCE_TITLE: val.tbl_user_resource?.resource_title ? val.tbl_user_resource?.resource_title : "--",
              PRICE: val.tbl_user_resource?.purchase_price ? val.tbl_user_resource?.purchase_price.toFixed(2) : "--",
              TRANSACTION_FEES: val.tbl_payment_history?.transaction_fee
                ? val.tbl_payment_history?.transaction_fee.toFixed(2)
                : "--",
              TAX_AMOUNT: val.tbl_payment_history?.total_tax_amount
                ? val.tbl_payment_history?.total_tax_amount.toFixed(2)
                : "--",
              AMOUNT: val.tbl_payment_history?.total_amount ? val.tbl_payment_history?.total_amount.toFixed(2) : "--",
              MATCHED_REVENUE: val.tbl_payment_history?.admin_fee ? val.tbl_payment_history.admin_fee.toFixed(2) : "--",
              OWNER_PAYMENT: val?.seller_amount ? val?.seller_amount.toFixed(2) : "--",
              TRANSACTION_NUMBER: val.tbl_payment_history?.payment_reference_no
                ? val.tbl_payment_history?.payment_reference_no
                : "--",
              INVOICE_NUMBER: val.tbl_payment_history?.invoice_number ? val.tbl_payment_history?.invoice_number : "--",
              TRANSFER_TO_OWNER: val?.transferred_amount ? val.transferred_amount.toFixed(2) : "0.00",

              AMOUNT: "$" + val?.amount?.toFixed(2),
              TAX_AMOUNT: "$" + val.total_tax_amount?.toFixed(2),
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "ResourcePurchase.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  //const csvLink = useRef();
  // let headers1 = [
  //   { label: "Customer", key: "firstName" },
  //   { label: "Subscription Date", key: "createdAt" },
  //   { label: "Subscription Plan", key: "subscriptionPlan" },
  //   { label: "Price", key: "price" },
  //   { label: "Valid Upto", key: "valid" },
  // ];
  // let subscriptionData = [];
  // purchaseData &&
  //   purchaseData.map((val, i) => {
  //     let obj = {};
  //     subscriptionData.push({
  //       firstName: val.tbl_user.firstName && val.tbl_user.firstName ? "#" + val.tbl_user.firstName : "--",
  //     });
  //   });

  // const downloadCsv = () => {
  //   if (subscriptionData.length != 0) {
  //     csvLink.current.link.click();
  //   } else {
  //     toast.error("No data import");
  //   }
  // };
  const columns = [
    {
      name: "#",
      selector: (row) => "1",
      // grow: 0,
      minWidth: "50px",
      style: { minWidth: "50px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Purchase Date",
      selector: (row) => moment(row.created_at).format("MM-DD-YYYY"),
      minWidth: "140px",
      style: { minWidth: "140px" },

      grow: 2,
      // sortable: true,
      cell: (row) => <span className="tb-lead">{moment(row.created_at).format("MM-DD-YYYY")}</span>,
    },
    {
      name: "Customer Details",
      selector: (row) => row.tbl_user?.firstName,
      minWidth: "140px",
      style: { minWidth: "140px" },
      grow: 3,
      // sortable: true,
      cell: (row) => (
        <div>
          <span className="sub-text">{row.tbl_user?.firstName}</span>
          <span className="sub-text">{row.tbl_user?.email}</span>
          <span className="sub-text">
            {/* {row.tbl_user?.phone} */}
            <div className="sub-phoneinp-text">
          <PhoneInput
              country={row?.tbl_user?.country_dial_code?.toUpperCase() || ""}
              value={(row?.tbl_user?.countryCode + row?.tbl_user?.phone)}
              placeholder="--"
              inputClass="minimal-input"
              buttonStyle={{ display: "none" }}
              inputStyle={{
                border: "none",
                padding: "0",
                background: "none",
                fontSize: "12px",
                width: "100%",
                outline: "none",
                color: "#8094ae",
                cursor: "pointer",
                // position: "relative",
                // top: "-56px",
              }}
              containerStyle={{
                border: "none",
                padding: "0",
              }}
              disabled={true}
            />
             </div>
            </span>
        </div>
      ),
    },
    {
      name: "Vendor Details",
      selector: (row) => row.resource_type_status,
      minWidth: "140px",
      style: { minWidth: "140px" },
      grow: 3,
      // sortable: true,
      cell: (row) => (<div>
      {row.resource_type_status=="individual"?
      <div>
      <span className="sub-text">{row.resource_type_status}</span>
      <span className="sub-text">{row.tbl_user_resource?.tbl_user?.firstName}</span>
      <span className="sub-text">{row.tbl_user_resource?.tbl_user?.email}</span>
      <span className="sub-text sub-phoneinp-text">
        {/* {row.tbl_user_resource?.tbl_user?.phone} */}
       
      <PhoneInput
              country={row?.tbl_user?.country_dial_code?.toUpperCase() || ""}
              value={(row?.tbl_user?.countryCode + row?.tbl_user?.phone)}
              placeholder="--"
              inputClass="minimal-input"
              buttonStyle={{ display: "none" }}
              inputStyle={{
                border: "none",
                padding: "0",
                background: "none",
                fontSize: "12px",
                width: "100%",
                outline: "none",
                color: "#8094ae",
                cursor: "pointer",
                // position: "relative",
                // top: "-56px",
              }}
              containerStyle={{
                border: "none",
                padding: "0",
              }}
              disabled={true}
            />
             </span>
             
      </div>:""}
      </div>) },
  

    {
      name: "Resource Title",
      selector: (row) => row.tbl_user_resource?.resource_title,

      grow: 3,
      // sortable: true,
      cell: (row) => (
        <div>
          <span className="sub-text">{row.tbl_user_resource?.resource_title}</span>
          <Link to={`/view-resource/${row.tbl_user_resource.id}`}>
            <button className="dropdown-item btn btn-md">
              <em className="icon ni ni-eye"></em>
            </button>
            {/* {sessionStorage.setItem("tabcust","")} */}
          </Link>
        </div>
      ),
    },
    {
      name: "Vendor's Payments",
      selector: (row) => row.seller_amount,
      grow: 3,

      minWidth: "180px",
      style: { minWidth: "180px" },
      // sortable: true,
      cell: (row) => (
        <div>
          <span className="sub-text">
            Total Amount:{" "}
            {row?.seller_amount
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(row?.seller_amount)
              : "$0.00"}{" "}
          </span>

          <span className="sub-text">
            Transferred Amount:{" "}
            {row.transferred_amount != null
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(row?.transferred_amount)
              : "$0.00"}{" "}
          </span>
          <span className="sub-text">
            Balance Amount: $
            {row?.seller_amount != null ? (row.seller_amount - falsyToZero(row.transferred_amount)).toFixed(2) : "0.00"}{" "}
          </span>
        </div>
      ),
    },
    // {
    //   name: "Amount",
    //   selector: (row) => "Akash yadav",
    //   grow: 1,
    //   minWidth: "110px",
    //   style: { minWidth: "110px" },
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2">
    //       <div className="user-info">
    //         <span className="sub-text"> $ {row.amount?.toFixed(2)}</span>
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   name: " Tax Amount",
    //   selector: (row) => "Akash yadav",
    //   grow: 2,
    //   minWidth: "120px",
    //   style: { minWidth: "120px" },
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2">
    //       <div className="user-info">
    //         <span className="sub-text"> $ {row.total_tax_amount?.toFixed(2)}</span>
    //       </div>
    //     </div>
    //   ),
    // },
    // {
    //   name: "Status",
    //   selector: (row) => (row.status),
    //    grow: 3,
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2">
    //       <div className="user-info">
    //         <span className="sub-text">
    //           {row.status}
    //         </span>
    //       </div>
    //     </div>
    //   ),
    // },

    {
      name: "Action",
      selector: (row) => "",

      cell: (row) => (
        <div className="user-card mt-2 mb-2 text-left">
          <button className="dropdown-item btn btn-md" onClick={() => handleAction(row)}>
            <em className="icon ni ni-eye"></em>
          </button>
          {/* <button
            className="btn btn-md"
            onClick={() => {
              openpaymentModal(row);
            }}
          >
            <i className="fa fa-credit-card" aria-hidden="true"></i>
          </button> */}
        </div>
      ),
    },
    // {
    //   name: "Pay",
    //   selector: (row) => "",
    //   minWidth: "60px",
    //   style: { minWidth: "60px" },
    //   cell: (row) => (
    //     <div>
    //       <button className="btn"  onClick={() => openpaymentModal(row)}>
    //       <i class="fa fa-credit-card" aria-hidden="true"></i>

    //       </button>
    //     </div>
    //   ),
    // },
  ];
  const getPurchaseHistoryData = () => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date");
    } else {
      // setCurrentPage(1)

      setLoader(true);
      const body = {
        searchKey: search,
        resource_id: selectedResourse?.value ?? "",
        buyer_id: getCustomer?.value ?? "",
        seller_id: parentid?.value ?? "",
        start_date: selectedfromdate ?? "",
        end_date: selectedtodate ? moment(selectedtodate).add(1, "d").format("YYYY-MM-DD") : "",
        limit: limitvalue ? limitvalue : 10,
        sortby: sortpurchase ? sortpurchase : "",
        key: "",
        resource_type: getSellerType ? getSellerType : "",
        page: currentPage,
      };

      //console.log("body",body)
      makePostRequest(URLS.GET_ALL_PURCHASEHISTORY, null, body, null)
        .then((res) => {
         
          if (res?.code == 200) {
            setPurchaseData(res.data?.data);
            console.log("purchase response.....",res.data?.data);
            setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)));
            setLoader(false);
          }
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
        });
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    sessionStorage.setItem("pagenumberresourcepurchase", selectedPage.selected + 1);
    // fetchData()
  };
  //console.log(currentPage,"current page",)
  // Fetch data based on the current page
  // const fetchData = (s) => {
  //   // Your code to fetch data from the server based on the currentPage
  //   // For example, you can make an API call or update the state with the new data
  //   let data = {
  //     searchKey: s ?? "",
  //     resource_id : selectedResourse?.value ?? "",
  //     buyer_id : getCustomer ?? "",
  //     seller_id : parentid ?? "",
  //     "start_date": selectedfromdate ?? "",
  //     end_date: selectedtodate? moment(selectedtodate).add(1,'d').format("YYYY-MM-DD"):"",
  //    "page": currentPage,
  //    "limit":limitvalue?limitvalue:10,
  //    searchKey : search,
  //     sortby:sortpurchase?sortpurchase:"",
  //    "key":"",
  //     resource_type:getSellerType?getSellerType:"",
  //     };
  //  makePostRequest(URLS.GET_ALL_PURCHASEHISTORY, null, data, null)
  //    .then((res) => {
  //      if (res.code === 200) {
  //        setLoader(false);
  //        setPurchaseData(res.data?.data);
  //        setCount(Math.ceil(res.data.count/parseInt(limitvalue?limitvalue:10)))

  //      } else {
  //        setLoader(false);
  //      }
  //    })
  //    .catch((error) => {
  //      console.log(error);
  //    });
  // };

  useEffect(() => {
    // fetchData()
  }, [currentPage]);

  useEffect(() => {
    // getPurchaseHistoryData(search);
  }, []);
  //console.log("purchaseData", purchaseData);

  // const handleparentname = (e) => {
  //   setparentid(e.target.value);
  // };

  const handleClearData = () => {
    setGetCustomer("");
    setparentid("");
    setselectedResourse("");
    setSelectedToDate("");
    setSelectedfromDate("");
    setSearch("");
    setSellerType("");
    setSortPurchase("");
    setCurrentPage(1);
    setlimitvalue("10");
    setClearState(!clearState);
    getPurchaseHistoryData();
  };

  const clearfilter = () => {
    handleClearData();
  };
  useEffect(() => {
    getPurchaseHistoryData();
  }, [clearState]);

   const [custName, setcustName] = useState(true);
   const [vendName, setvendName] = useState(true);
  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const selectRefFilter = useRef(null);
  const selectRefCustomerFilter = useRef(null);
  const selectRefVendorFilter = useRef(null);


  const handlevendor = () => {
    if (selectRefVendorFilter.current) {
      setvendName(!vendName);
      selectRefVendorFilter.current.focus();
    }
  }
  const handleCustomerName = () => {
    if (selectRefCustomerFilter.current) {
      setcustName(!custName);
      selectRefCustomerFilter.current.focus();
    }
  }
  const displaySelectFilter = (e) => {
    if (selectRefFilter.current) {
      setDisplayValueSelectFilter(!displayvalueSelectFilter);
      selectRefFilter.current.focus();
    }
  };

  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };
  const handleOutSideClick1 = (e) => {
    if (newRef1.current && !newRef1.current.contains(e.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  });

  return (
    <React.Fragment>
      <Head title="Resources Purchased"></Head>
      <Content>
        <ToastContainer />
        <div className="row row pb-2 pt-2">
          <div className="col-lg-6">
            <h3 className="nk-block-title page-title mb5 pb-4">Resource Purchased List</h3>
          </div>
          {/* <div className="col-lg-6">
            <h3 className="nk-block-title page-title text-end">
              Total Outstanding:{" "}
              {purchaseBalance
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(purchaseBalance)
                : "$0.00"}
            </h3>
          </div> */}
        </div>
        <Modal isOpen={isPayOpen} toggle={openpaymentModal}>
          <ModalHeader
            toggle={openpaymentModal}
            close={
              <button className="close" onClick={openpaymentModal}>
                <Icon name="cross" />
              </button>
            }
          >
            Transfer Payment History
          </ModalHeader>
          <Card className="card-bordered">
            <div className="card-inner">
              <BlockHeadContent>
                <BlockHead size="lg">
                  <div className="customerDetailsTable">
                    {payAmount.seller_amount > payAmount.transferred_amount ? (
                      <div>
                        <div>
                          <label>Enter Amount</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="enter amount"
                            onChange={handleamount}
                          />
                          <div style={{ color: "red" }}>{errorpay ? "Please enter amount" : ""}</div>
                          <button className="btn btn-primary mt-3" onClick={handlePayment}>
                            Pay
                          </button>
                        </div>

                        <hr></hr>

                        <table>
                          <tr className="border-bottom">
                            <th width="200px">Date</th>
                            <th width="200px">Transfer ID</th>
                            <th align="right">($)Amount</th>
                          </tr>
                          {transferamount &&
                            transferamount.map((val, i) => {
                              return (
                                <tr className="border-bottom">
                                  <td>{moment(val.created_at).format("MM-DD-YYYY")}</td>
                                  <td>{val.balance_transaction}</td>
                                  <td align="right">
                                    {new Intl.NumberFormat("en-US", {
                                      style: "currency",
                                      currency: "USD",
                                    }).format(val.trasfer_amount)}
                                  </td>
                                </tr>
                              );
                            })}
                        </table>
                      </div>
                    ) : (
                      <div>
                        <table>
                          <tr className="border-bottom">
                            <th width="200px">Date</th>
                            <th width="200px">Transfer ID</th>
                            <th align="right">($)Amount</th>
                          </tr>
                          {transferamount &&
                            transferamount.map((val, i) => {
                              return (
                                <tr className="border-bottom">
                                  <td>{moment(val.created_at).format("MM-DD-YYYY")}</td>
                                  <td>{val.balance_transaction}</td>
                                  <td align="right">{val.trasfer_amount.toFixed(2)}</td>
                                </tr>
                              );
                            })}
                        </table>
                        <h5 style={{ textAlign: "center", marginTop: "20px" }}>No payment pending</h5>
                      </div>
                    )}
                  </div>
                </BlockHead>
              </BlockHeadContent>
            </div>
          </Card>
          <ModalFooter className=""></ModalFooter>
        </Modal>

        <Modal isOpen={isOpen} toggle={openModal}>
          <ModalHeader
            toggle={openModal}
            close={
              <button className="close" onClick={openModal}>
                <Icon name="cross" />
              </button>
            }
          >
            Payment Information
          </ModalHeader>
          <Card className="card-bordered">
            <div className="card-inner">
              <BlockHeadContent>
                <BlockHead size="lg">
                  <div className="customerDetailsTable">
                    <div className="row">
                      <div>
                        <Card className="card-bordered mb-5">
                          <ul className="data-list is-compact">
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Resource Title</div>
                                <div className="data-value">
                                  {modalData?.tbl_user_resource?.resource_title
                                    ? modalData?.tbl_user_resource?.resource_title
                                    : "--"}
                                </div>
                              </div>
                            </li>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Amount</div>
                                <div className="data-value">
                                  {modalData?.total_amount
                                    ? new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(modalData?.total_amount)
                                    : "$0.00"}
                                </div>
                              </div>
                            </li>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Tax Amount</div>
                                <div className="data-value">
                                  {modalData?.total_tax_amount
                                    ? new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(modalData?.total_tax_amount)
                                    : "$0.00"}
                                </div>
                              </div>
                            </li>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Admin Fees</div>
                                <div className="data-value">
                                  {modalData?.tbl_payment_history?.admin_fee
                                    ? new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(modalData?.tbl_payment_history?.admin_fee)
                                    : "$0.00"}
                                </div>
                              </div>
                            </li>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Stripe Fees</div>
                                <div className="data-value">
                                  {modalData?.tbl_payment_history?.stripe_fee
                                    ? new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(modalData?.tbl_payment_history?.stripe_fee)
                                    : "$0.00"}
                                </div>
                              </div>
                            </li>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Seller Amount</div>
                                <div className="data-value">
                                  {modalData?.seller_amount
                                    ? new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(modalData?.seller_amount)
                                    : "$0.00"}
                                </div>
                              </div>
                            </li>
                            {/* <li className="data-item">
                                <div className="data-col">
                                  <div className="data-label">Seller Remaining Balance</div>
                                  <div className="data-value">${modalData?.tbl_payment_history?.seller_remaining_amount?modalData?.tbl_payment_history?.seller_remaining_amount.toFixed(2):"0.00"}</div>
                                </div>
                              </li> */}
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Transaction ID</div>
                                <div className="data-value" style={{ width: "150px" }}>
                                  {" "}
                                  {modalData?.tbl_payment_history?.payment_intent
                                    ? modalData?.tbl_payment_history?.payment_intent
                                    : "--"}
                                </div>
                              </div>
                            </li>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Invoice Number</div>
                                <div className="data-value">
                                  {modalData?.tbl_payment_history?.invoice_number
                                    ? modalData?.tbl_payment_history?.invoice_number
                                    : "--"}
                                </div>
                              </div>
                            </li>
                            <li className="data-item">
                              <div className="data-col">
                                <div className="data-label">Invoice PDF Link</div>
                                <div className="data-value invoicePDF">
                                  <a
                                    href={S3_BUCKET_URL + modalData?.tbl_payment_history?.invoice_pdf_link}
                                    target="_blank"
                                  >
                                    View Invoice
                                  </a>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </Card>
                      </div>
                    </div>
                  </div>
                </BlockHead>
              </BlockHeadContent>
            </div>
          </Card>
          <ModalFooter className=""></ModalFooter>
        </Modal>

        {loader ? (
          <div className="overflowloader" id="preload">
            <div className="circle-square" style={{ marginTop: "250px" }}>
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
        ) : (
          <>
            {/* <h4 onClick={ShowHideSelect} className="cursorPointer">Filters <i class="fa fa-filter" aria-hidden="true"></i></h4> */}
            <Card className="card-bordered">
              <div className="card-inner relativeClass">
                <div className="row pb-2 pt-2  align-items-center">
                  <div className="col-lg-4 ">
                    <div className="filtersidebutton">
                      <h5 style={{ cursor: "pointer" }} onClick={ShowHideSelect}>
                        Filters
                        <FaFilter />
                      </h5>
                      <button className="btn btn-md btn-primary" type="submit" onClick={clearfilter}>
                        {" "}
                        Clear Filters{" "}
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-8 d-flex  justify-content-end">
                    <select
                      value={sortpurchase}
                      className="form-select"
                      style={{ width: "170px" }}
                      onChange={HandleSortPurchase}
                    >
                      <option value="">Sort By</option>
                      <option value="ASCDATE">Date Ascending</option>
                      <option value="DESCDATE">Date Descending</option>
                      <option value="ASCTITLE">Title Ascending</option>
                      <option value="DESCTITLE">Title Descending</option>
                      <option value="ASCBUYER">Buyer Ascending</option>
                      <option value="DESCBUYER">Buyer Descending</option>
                      <option value="ASCSELLER">Seller Ascending</option>
                      <option value="DESCSELLER">Seller Descending</option>
                    </select>

                    <div>
                      <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                    </div>
                    <div>
                      <select
                        value={limitvalue}
                        className="form-select"
                        style={{ width: "85px" }}
                        onChange={HandleLimitValue}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </div>
                    <div>
                      &nbsp; &nbsp;
                      <button className="btn btn-primary " onClick={ExportEcelFile}>
                        <em className="icon ni ni-download-cloud"></em>
                        <span>Export</span>
                      </button>
                    </div>
                  </div>
                </div>
                <hr></hr>
                {show && (
                  <div className="card-inners relativeClass">
                    <div className="row pb-2">
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Customer Name</strong>
                          </label>

                          <div className="form-control-wrap paymetsession">
                            <i
                              className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                              onClick={handleCustomerName}
                            ></i>
                            <Select
                              className="form-select"
                              autosize={true}
                              onChange={(e) => setGetCustomer(e)}
                              defaultValue={getCustomer}
                              // onChange={HandleCustomer}
                              // onMenuScrollToBottom={handleMenuScroll}
                              options={[
                                { value: "", label: "All" },
                                ...(childparentdata?.map((val) => ({
                                  value: val?.tbl_user?.id,
                                  label: val.tbl_user?.firstName + " " + val.tbl_user?.lastName,
                                })) || []),
                              ]}
                               openMenuOnFocus={custName}
                                ref={selectRefCustomerFilter}
                            />
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong> Buyer</strong>
                          </label>
                          <select value={getCustomer} className="form-select" onChange={handleCustomer}>
                            <option value="">All Buyers</option>
                            {childparentdata &&
                              childparentdata.map((val) => {
                                return (
                                  <>
                                    <option value={val?.tbl_user?.id}>
                                      {val?.tbl_user?.firstName} {val?.tbl_user?.lastName}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                      </div> */}

                      {/* <div className="col-lg-4">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong> Seller</strong>
                          </label>
                          <select value={parentid} className="form-select" onChange={handleparentname}>
                            <option value="">All Sellers</option>

                            {childparentdata2 &&
                              childparentdata2.map((val) => {
                                return (
                                  <>
                                    <option value={val?.tbl_user_resource.tbl_user?.id}>
                                      {val?.tbl_user_resource.tbl_user?.firstName} {val?.tbl_user_resource.tbl_user?.lastName}
                                    </option>
                                  </>
                                );
                              })}
                          </select>
                        </div>
                      </div> */}

                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Vendor Name</strong>
                          </label>
                          <div className="form-control-wrap paymetsession">
                            <i
                              className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                              onClick={handlevendor}
                            ></i>
                            <Select
                              className="form-select"
                              autosize={true}
                              onChange={(e) => setparentid(e)}
                              defaultValue={parentid}
                              // onChange={HandleCustomer}
                              // onMenuScrollToBottom={handleMenuScroll}
                              options={[
                                { value: "", label: "All" },
                                ...(childparentdata2?.map((val) => ({
                                  value: val?.tbl_user_resource.tbl_user?.id,
                                  label:
                                    val?.tbl_user_resource.tbl_user?.firstName +
                                    " " +
                                    val?.tbl_user_resource.tbl_user?.lastName,
                                })) || []),
                              ]}
                                openMenuOnFocus={vendName}
                                ref={selectRefVendorFilter}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Vendor Type</strong>
                          </label>
                          <select value={getSellerType} className="form-select" onChange={handleSeller}>
                            <option value="">Select Vendor Type</option>
                            <option value="individual">Individual</option>
                            <option value="group">Group</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Resource</strong>
                          </label>
                          <div className="form-control-wrap paymetsession">
                            <i
                              className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList"
                              onClick={displaySelectFilter}
                            ></i>
                            <Select
                              className="form-select"
                              defaultValue={selectedResourse}
                              // value={selectedResourse}
                              onChange={(e) => setselectedResourse(e)}
                              // onMenuScrollToBottom={handleMenuScroll}
                              options={resourceDropdown?.map((v) => ({
                                value: v?.resource_id,
                                label: v?.tbl_user_resource?.resource_title,
                              }))}
                              openMenuOnFocus={displayvalueSelectFilter}
                              ref={selectRefFilter}
                            />
                          </div>
                        </div>
                      </div>
                    
                      <div className="col-lg-3">
                        <div className="form-control startDate border-0 px-0">
                          <label>
                            <strong>Start Date</strong>
                          </label>
                          {/* <input type="date" name="formdate" className="form-control" onChange={HandleFromDate} /> */}

                          <div className="form-control-wrap" ref={newRef}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedfromdate}
                              onChange={HandleFromDate}
                              className="form-control date-picker"
                              open={datepickershow}
                              onFocus={() => setDatePickerShow(!datepickershow)}
                            />
                          </div>
                        </div>
                        <span className="error">{formDateError}</span>
                      </div>

                      <div className="col-lg-3">
                        <div className="form-control endDate border-0 px-0">
                          <label>
                            <strong> End Date</strong>
                          </label>
                          {/* <input type="date" name="todate" className="form-control" onChange={HandleToDate}></input> */}

                          <div className="form-control-wrap" ref={newRef1}>
                            <div className="form-icon form-icon-left">
                              <span onClick={() => handleclickdatepickericon1()}>
                                <Icon name="calendar"></Icon>
                              </span>
                            </div>
                            <DatePicker
                              placeholderText="MM/DD/YYYY"
                              selected={selectedtodate}
                              onChange={HandleToDate}
                              className="form-control date-picker"
                              open={datepickershow1}
                              onFocus={() => setDatePickerShow1(!datepickershow1)}
                            />
                          </div>
                        </div>
                        <span className="error">{toDateError}</span>
                      </div>
                      {/* <div className="row pb-2"> */}
                      <div className="col-lg-3">
                        <div className="form-control border-0 px-0">
                          <label>
                            <strong>Search by Customer Name</strong>
                          </label>
                          <Input
                            name="name"
                            placeholder="search..."
                            type="text"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                   

                      <div className="col-lg-3">
                        <div className="outerTypeBox mb-3">
                          <label className="w-100 mb-0">&nbsp;</label>
                          <button className="btn btn-md btn-primary" type="submit" onClick={getPurchaseHistoryData}>
                            {" "}
                            Search{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="card-inners relativeClass">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      {/* <div className="clearboth"></div> */}

                      {purchaseData.length == 0 ? (
                        <div>no data found</div>
                      ) : (
                        <ReactDataTable
                          data={purchaseData}
                          columns={columns}
                          className="nk-tb-list"
                          searchable={false}

                          // pagination
                        />
                      )}

                      {purchaseData?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </div>
            </Card>
          </>
        )}
      </Content>
    </React.Fragment>
  );
}
export default PurchaseHistory;
