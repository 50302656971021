import React, { useRef } from "react";
import { useState, useEffect } from "react";
import axios from 'axios';
import {
  Card,
} from "reactstrap";
import './EditGroup.css';
import { useHistory, useNavigate, useParams } from "react-router-dom";
import Select from 'react-select'
import { makePostRequest, makeGetRequest, makePostRequestForAll, getHeaders, BASE_URL, isProduction, secretKey } from "../../../service/api";
import CryptoJS from "crypto-js";
import { URLS } from "../../../constants/urls";
import { getUserDetailsFromToken, isValidFileUploaded, checkUrl, toastifyMessage } from '../../../service/auth';
import { toast } from 'react-toastify';
import Content from "../../../layout/content/Content";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import JoditEditor from "jodit-react";
import TextField from "@mui/material/TextField";
const editorConfig = {
  buttons: [
    'bold', // Bold
    'italic', // Italic
    'underline', // Underline
    'strikethrough', // Strikethrough
    'superscript', // Superscript
    'subscript', // Subscript
    'ul', // Unordered List
    'ol', // Ordered List
    'outdent', // Decrease Indentation
    'indent', // Increase Indentation
    'align', // Align (left, center, right, justify)
    '|', // Separator
    'link', // Insert Link
    'table', // Insert Table
    'undo', // Undo
    'redo', // Redo
  ],
};

const filter = createFilterOptions();
const EditGroup = () => {
  let useractive = sessionStorage.getItem("useractive");
  const param = useParams();
  let history = useHistory();
  const inputRef = useRef();
  const autoCompleteRef = useRef();
  const [isValid, setIsValid] = useState(false)
  const [learningPillarData, setLearningPillarData] = useState([]);
  const [learningPillarIds, setLearningPillarIds] = useState([]);
  const [gardeSelected, setGradeSelected] = useState([]);
  const [keywordSelected, setKeywordSelected] = useState([]);
  const [shareWithSelected, setShareWithSelected] = useState([]);
  const [learningPillarSelected, setLearningPillarSelected] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [grade, setGrade] = useState([]);
  const [groupImage, setGroupImage] = useState("");
  const [image, setImage] = useState("https://matchedstage.s3.us-west-1.amazonaws.com/groupdefult.jpeg");
  const [error, setError] = useState(false);
  const [shareWith, setShreWith] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [groupId, setGroupId] = useState([]);
  const [shareFriend, setShareFriends] = useState([]);
  const [friendData, setFriendData] = useState([])
  const [friendList, setFriendList] = useState([]);
  const [loader, setloader] = useState(false);
  const [keywords, setKeywords] = useState([]);
  const [aboutGroupData, setAboutGroupData] = useState("");
  const [userId, setUserId] = useState("")
  const [userIdCreatedby, setUserIdCreatedby] = useState("");
  const [transactionFee, setTransactionFee] = useState("")
  const [payLoad, setPayLoad] = useState({
    group_name: "",
    url: "",
    group_chat_permission: "0",
    group_join_permission: "anyone",
    group_id: ""
  });
  const [address, setAddress] = useState("")
  const [fullAddress, setFullAddress] = useState({
    city: "",
    state: "",
    zipcode: "",
    latitude: "",
    longitude: "",
  })
  const [inputlist, setInputlist] = useState([{ firstName: "", email: "" }]);
  const shareResourceOptions = [
    { value: "matched", label: "All MatchED" },
    { value: "local", label: "Local MatchED Community" },
    { value: "friend", label: "Friends" },
    { value: "group", label: "Groups" }
  ];
  const options = {
    componentRestrictions: { country: ["US", "IND"] },
    fields: ["address_components", "geometry", "icon", "name"],
    //strictBounds: false,
    types: ["address"],
  };
  // this function is use for add inputfield
  const handleadd = () => {
    const values = [...inputlist];
    values.push({
      firstName: "",
      email: "",
    });
    setInputlist(values);
  };
  const handleremove = (index) => {

    const values = [...inputlist];
    values.splice(index, 1);
    setInputlist(values);
  };
  const handleInputChange = (index, event) => {
    const values = [...inputlist];
    const updatedValue = event.target.name;
    values[index][updatedValue] = event.target.value;
    setInputlist(values);
  };

  const HnadleGroupAdminFee = (e) => {
    setTransactionFee(e.target.value)
  }

  const inputFocus = useRef();
  const inputFocusZip = useRef();
  const resourceUrl = useRef();
  const LearningFocus = useRef();
  const AboutFocus = useRef();
  const ShareFocus = useRef();
  const [zipCodeError, setZipCodeError] = useState("");
  const [urlError, setUrlError] = useState("");
  let Zipcode = /^[a-zA-Z0-9]{5,6}$/;

  // this function is use for google address
  const getGoogleAddressStreetOne = () => {
    if (window.google && window.google.maps && window.google.maps.places) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        { ...options, disablePoweredByContainer: true }
      );
      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        const addressComponents = place.address_components;
        let name = "";
        let city = "";
        let state = "";
        let zipcode = "";
        let lat = "";
        let lng = ""
        addressComponents.forEach((component) => {
          const componentType = component.types[0];
          if (componentType === "street_number" || componentType === "route") {
            name += component.long_name + ", ";
          }
          if (componentType === "locality") {
            city = component.long_name;
          }
          if (componentType === "administrative_area_level_1") {
            state = component.long_name;
          }
          if (componentType === "postal_code") {
            zipcode = component.long_name;
          }
        });
        if (place.geometry && place.geometry.location) {
          lat = place.geometry.location.lat();
          lng = place.geometry.location.lng()
        }
        name = name.slice(0, -2);
        setAddress(name)
        setFullAddress({ ...fullAddress, "city": city, "state": state, "zipcode": zipcode, "latitude": lat, "longitude": lng })
      });
    } else {
      console.error('Google Maps API not available');
    }
  };
  // this function is use for get keyword & grade data
  const handleeducationbackgrounddata = () => {
    makeGetRequest(URLS.GET_ALL_KEYWORDS, null, null, null).then(
      (response) => {
        let options = [];
        response.data.length > 0 &&
          response.data.map((val, i) => {
            options.push({ keyword: val.keyword, label: val.keyword });
          });

        setSelectedOption(options);
      }
    );
  };
  const handlegetgradedata = () => {
    makeGetRequest(URLS.GET_ALL_GRADE_LEVEL, null, null, null).then(
      (response) => {
        setGrade(response.data);
      }
    );
  };
  //api call to get learning pillar  data
  const getLearningPillarsData = () => {
    makeGetRequest(URLS.GET_ALL_LEARNING_PILLER, null, null, null)
      .then((res) => {
        if (res.code === 200) {
          setLearningPillarData(res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getGroupShare = () => {
    let data = {
      "group_id": param.id,
    };
    makePostRequest(URLS.GET_GROUP_FRIEND, null, data, null)
      .then((res) => {
        // console.log("reshareListGroups:::", res)
        let shareArr = [];
        let arr = [];
        for (let i = 0; i < res?.data?.friend?.length; i++) {
          arr.push({ value: res?.data?.friend[i]?.id, label: res?.data?.friend[i].firstName + " " + res?.data?.friend[i]?.lastName });
          shareArr.push(res?.data?.friend[i]?.id)
        }
        setShareFriends(shareArr);
        setFriendData(arr)
        const groupArr = [];
        const shareGroupArr = [];

        for (let i = 0; i < res?.data?.group?.length; i++) {
          groupArr.push({ value: res?.data?.group[i]?.id, label: res?.data?.group[i].group_name });
          shareGroupArr.push(res?.data?.group[i]?.id)
        }
        setGroupData(groupArr);
        setGroupId(shareGroupArr)
      }).catch((error) => {
        // console.log("error:::", error)
      })
  }

  // this function use for load time api calling
  useEffect(() => {
    if (useractive == "") {
      //   navigate("/sign-in");
    }

    handleeducationbackgrounddata();
    handlegetgradedata();
    getLearningPillarsData();
    getGoogleAddressStreetOne();
    document.body.classList.add("createnewGroup");
    return () => {
      document.body.classList.remove("createnewGroup");
    };
  }, []);

  useEffect(() => {
    param.id && getGroupShare(param.id)
    param.id && getGroupById(param.id);
  }, [param.id])

  // handle input
  const handleInput = (e) => {
    // setError(false);
    const { name, value } = e.target
    if (name === "group_chat_permission") {
      if (e.target.checked) {
        setPayLoad({ ...payLoad, "group_chat_permission": "1" })
      }
      else {
        setPayLoad({ ...payLoad, "group_chat_permission": "0" })
      }
    }
    else if (name === "zipcode") {
      setFullAddress({ ...fullAddress, [name]: value })
    }
    else {
      setPayLoad({ ...payLoad, [name]: value })
    }
  }
  const handleselectedOptionKeyWord = (e) => {
    const arr = [];
    for (let i = 0; i < e.length; i++) {
      arr.push(e[i]);
    }
    setKeywordSelected(arr)

  };
  const handleselectedOption = (e) => {
    // console.log(e,"selectvalue")
    const arr = [];
    const shareWith = [];
    for (let i = 0; i < e.length; i++) {
      arr.push(e[i]);
      shareWith.push(e[i].value)
    }
    setShareWithSelected(arr)
    setShreWith(shareWith)
  };
  const handleselectedOptionPillars = (e) => {
    // console.log("pillar data",e)
    const arr = [];
    for (let i = 0; i < e.length; i++) {
      arr.push(e[i]);
    }
    setLearningPillarSelected(arr)

  }

  const handleselectedOptionGrade = (e) => {
    const arr = [];
    for (let i = 0; i < e.length; i++) {
      arr.push(e[i]);
    }
    setGradeSelected(arr)
  }

  const formData = new FormData();
  const [file, setFile] = useState("");

  function handleImage(event) {
    setloader(true)
    var file1 = event.target.files[0];
    let supportType = ['image/jpeg', 'image/png', 'image/jpg'];
    if (!supportType?.includes(file1?.type)) {
      toast.error('Please select a JPEG, PNG, or JPG image.')
      setloader(false)
      return;
    }
    setGroupImage(event.target.files[0])
    setFile((URL.createObjectURL(event.target.files[0])));

    formData.append("group_id", param?.id);
    formData.append("image", file1);

    axios.post(BASE_URL + URLS.GROUP_IMAGE, formData, {
      headers: getHeaders({ "Content-Type": "multipart/form-data" })
    })
      .then(res => {
        if (isProduction == "true") {
          let decryptdata = CryptoJS.AES.decrypt(
            res.data?.data,
            secretKey
          ).toString(CryptoJS.enc.Utf8);
          let plainText = JSON.parse(decryptdata);
          // console.log("plainText", plainText)
          if (plainText.code === 200) {
            toast.success(plainText.message);
            setloader(false);
          }
        }
        if (res.status == 200) {
          // toastifyMessage("Group image uploaded successfully.","success");
          setloader(false)

        } else {

        }
      })
  }

  const handleGroupId = (shareResourceVal) => {
    let shareArr = [];
    shareResourceVal.length > 0 &&
      shareResourceVal.map((val, i) => {
        shareArr.push(val.value);
      });
    setGroupData(shareResourceVal);
    setGroupId(shareArr)
  };
  const handleFriendsId = (shareResourceVal) => {
    let shareArr = [];
    shareResourceVal.length > 0 &&
      shareResourceVal.map((val, i) => {
        shareArr.push(val.value);
      });
    setShareFriends(shareArr);
    setFriendData(shareResourceVal)
  };
  useEffect(() => {
    if (shareWith?.includes("friend")) {
      let data = {
        user_id: userId,
      };
      makePostRequest(URLS.friendList, null, data, null)
        .then((res) => {
          const arr = [{ label: "All", value: "all" }];
          res?.data.map((val) => {
            return arr.push({ label: val.name, value: val.id })
          })
          setFriendList(arr)
        }).catch((error) => {
          console.log("error", error)
        })
    }
    if (shareWith?.includes("group")) {
      let data = {
        user_id: userId,
      }
      makePostRequest(URLS.getGroupList, null, data, null)
        .then((res) => {
          const arr = [{ label: "All", value: "all" }]
          res?.data?.myGroups?.data?.map((val, index) => {
            return arr.push({ label: val?.group_name + " (" + val?.member_type + ") ", value: val?.id })
          })
          setGroupList(arr);
        }).catch((error) => {
          console.log("error:::", error)
        })
    }
  }, [shareWith])
  // this function is use for view group ny id
  const getGroupById = () => {
    setloader(true)
    makePostRequestForAll(URLS.GET_ONE_GROUP, { "group_id": param.id })
      .then((res) => {
        //  console.log("getoneGroup:::", res)
        setloader(false)
        setUserId(res?.data?.tbl_user?.id)
        setGroupImage(res?.data?.image)
        setUserIdCreatedby(res?.data?.created_by)
        // setImage(res?.data?.image)
        setFile(res?.data?.image)
        setTransactionFee(res?.data?.group_transtion_fee)
        setAboutGroupData(res?.data?.about_group ? res?.data?.about_group : "")
        setPayLoad({
          group_name: res?.data?.group_name ? res?.data?.group_name : "",
          url: res?.data?.url,
          group_chat_permission: res?.data?.group_chat_permission ? res?.data?.group_chat_permission : 0,
          group_join_permission: res?.data?.group_join_permission ? res?.data?.group_join_permission : "anyone",
          group_id: res?.data?.group_id
        })
        setFullAddress({
          city: res?.data?.city,
          state: res?.data?.state,
          zipcode: res?.data?.zipcode,
          latitude: res?.data?.latitude,
          longitude: res?.data?.longitude,
        })
        setAddress(res.data.address)
        const grade = [];
        for (let i = 0; i < res?.data?.tbl_group_grade_mappings?.length; i++) {
          grade.push({ value: res?.data?.tbl_group_grade_mappings[i]?.grade_id, label: res?.data?.tbl_group_grade_mappings[i].tbl_grade_level.name });
        }
        setGradeSelected(grade)
        const keyword = [];
        for (let i = 0; i < res?.data?.tbl_group_keywords?.length; i++) {
          keyword.push({ keyword: res?.data?.tbl_group_keywords[i]?.keyword, label: res?.data?.tbl_group_keywords[i].keyword });
        }
        setKeywords(keyword)
        const pillar = [];
        for (let i = 0; i < res?.data?.tbl_group_learning_pillars?.length; i++) {
          pillar.push({ value: res?.data?.tbl_group_learning_pillars[i]?.pillar_id, label: res?.data?.tbl_group_learning_pillars[i].tbl_learning_pillar_master?.name });
        }
        setLearningPillarSelected(pillar)
        const shareWith = [];
        const sharevalue = [];
        const array = res?.data?.shared_with?.split(",")
        // console.log(array,"arrayof ")
        for (let i = 0; i < array?.length; i++) {
          if (array[i] === "friend") {
            shareWith.push({ value: array[i], label: "Friends" });
            sharevalue.push(array[i])
          }
          if (array[i] === "local") {
            shareWith.push({ value: array[i], label: "Local MatchED community" });
            sharevalue.push(array[i])
          }
          if (array[i] === "group") {
            shareWith.push({ value: array[i], label: "Groups" });
            sharevalue.push(array[i])
          }
          if (array[i] === "matched") {
            shareWith.push({ value: array[i], label: "All MatchED" });
            sharevalue.push(array[i])
          }
        }
        setShreWith(sharevalue)
        setShareWithSelected(shareWith)
        const useList = [];
        for (let i = 0; i < res?.data?.group_admin_users?.length; i++) {
          useList.push({ firstName: res?.data?.group_admin_users[i]?.tbl_user?.firstName, email: res?.data?.group_admin_users[i]?.tbl_user?.email });
        }
        setInputlist(useList)
      }).catch((error) => {
        console.log("error:::", error)
        setloader(false)
      })
  }
  
  const updateGroup = (e) => {
    e.preventDefault();
    const grade = [];
    const keyword = [];
    const pillar = [];
    const shareWith = [];

    if (gardeSelected.length > 0) {
      gardeSelected?.map((val) => {
        grade.push(val.value);
      });
    }

    if (keywords.length > 0) {
      keywords?.map((val) => {
        const keywordValue = val?.keyword?.trim() || val;
        keyword.push(keywordValue);
      });
    }

    if (learningPillarSelected.length > 0) {
      learningPillarSelected?.map((val) => {
        pillar.push(val.value);
      });
    }

    if (shareWithSelected.length > 0) {
      shareWithSelected?.map((val) => {
        shareWith.push(val.value);
      });
    }

    if (fullAddress.zipcode === "") {
      setZipCodeError("Please enter the Zip Code");
      inputFocusZip.current.focus();
      return;
    }

    if (!Zipcode.test(fullAddress?.zipcode?.replace(/\s/g, ""))) {
      setZipCodeError("Invalid Zip Code");
      inputFocusZip.current.focus();
      return;
    }

    if (payLoad.url && !checkUrl(payLoad.url)) {
      setIsValid(true);
      setError(true);
      resourceUrl.current.focus();
      return;
    } else {
      setIsValid(false);
      setError(false);
    }

    if (payLoad.group_name === "") {
      inputFocus.current.focus();
      setError(true);
      return;
    }
    if (shareWith.length === 0) {
      ShareFocus.current.focus();
      setError(true);
      return;
    }

    if (aboutGroupData === "") {
      AboutFocus.current?.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
      setError(true);
      return;
    }
    if (!inputlist[0]?.firstName && !inputlist[0]?.email) {
      setError(true);
      return;
    }

    const body = {
      ...payLoad,
      ...fullAddress,
      "address": address,
      "group_id": param.id ? param.id : "",
      "grade": grade,
      "userList": inputlist[0]?.firstName === "" ? [] : inputlist,
      "keywords": keyword,
      "learning_pillar": pillar,
      "shared_with": shareWith,
      "group_ids": groupId.length === 0 ? [] : groupId,
      "shared_id": shareFriend.length === 0 ? [] : shareFriend,
      "about_group": aboutGroupData,
      "user_id": userIdCreatedby,
      "group_transtion_fee": transactionFee,
    };
    makePostRequestForAll(URLS.updateMyGroup, body)
      .then((res) => {
        setloader(false);
        if (res.code === 200) {
          toastifyMessage(res.message, "success");
          setTimeout(() => {
            history.push("/group-list");
          }, 3000)
        }
      })
      .catch((error) => {
        console.error("API Error:", error);
        setloader(false);
      });
  };


  return (<>

    <Content>
      <h3 className="nk-block-title page-title  pb-4">Edit Group
        <button onClick={() => history.goBack()} className="btn btn-outline-light bg-white d-none d-sm-inline-flex float-end">
          <em className="icon ni ni-arrow-left"></em><span>Back</span>
        </button>
      </h3>
      {
        loader ?
          <div className="overflowloader" id="preload" style={{ marginTop: "250px" }}>
            <div className="circle-square" >
              <div className="red" style={{ background: "#AAC0CE" }}></div>
              <div className="blue" style={{ background: "#265472" }}></div>
              <div className="green" style={{ background: "#F19357" }}></div>
              <div className="yellow" style={{ background: "#F0B285" }}></div>
            </div>
          </div>
          :
          <Card className="card-bordered">
            <div class="card-bordered card">
              <div class="container">
                <div class="row">

                  <div className="main-container-group">
                    <div className="col-lg-12">
                      <div className="create-new">
                        <div className="create-inner">
                          <div className="icon-outer">
                            <img src={file ? file : groupImage} alt="group image" />
                          </div>
                          <div className="upload">
                            <div className="input-file-div">
                              <span className="upload-div">Upload Photo</span>
                              <input type="file" className="file-handle-div" onChange={handleImage} /></div>
                          </div>
                        </div>

                        <form>
                          <div className="create-new-form">
                            <div className="seleter-div">
                              <label htmlFor="username" className="label-form-div">Group Name<strong className="red-color">*</strong>{/* <span>Type Required Group Name</span> */}</label>
                              <input type="text" class="form-control" placeholder="Group Name" name="group_name" onChange={handleInput} value={payLoad.group_name} ref={inputFocus} />
                              <span className="error-span">{error == true ? payLoad.group_name === "" && "Please enter group name." : ""}</span>
                            </div>
                            <div className="row">
                              <div className="col-lg-6 col-12 title-group">
                                <label htmlFor="username" className="label-form-div">Location</label>
                                <input type="text" class="form-control" id="inputAddress" placeholder="Enter address" name="address" value={address} ref={inputRef} onChange={(e) => setAddress(e.target.value)} />

                              </div>
                              <div className="col-lg-6 col-12 title-group">
                                <label htmlFor="username" className="label-form-div">Zip Code<strong className="red-color">*</strong></label>
                                <input type="text" class="form-control" placeholder="Zip code" name="zipcode" onChange={handleInput} value={fullAddress.zipcode} ref={inputFocusZip} />
                                {(!Zipcode.test(fullAddress?.zipcode?.replace(/\s/g, "")) || fullAddress.zipcode === "") && <span className="error-span">{zipCodeError}</span>}
                              </div>
                            </div>
                            <div className="seleter-div">
                              <label htmlFor="username" className="label-form-div ">Keywords <span>enter any/all keywords associated with this group </span></label>
                              <div className="kayword-associated">
                                <Autocomplete
                                  multiple
                                  value={keywords}
                                  onChange={(event, newValue) => {
                                    if (typeof newValue === "string") {
                                      setKeywords({
                                        keyword: newValue,
                                      });
                                    } else if (newValue && newValue.inputValue) {
                                      setKeywords({
                                        keyword: newValue.inputValue,
                                      });
                                    } else {
                                      let filteredArray = newValue.filter(item => {
                                        if (typeof item === 'string') {
                                          return item.trim() !== '';
                                        }
                                        if (item.hasOwnProperty('inputValue') && typeof item.inputValue === 'string') {
                                          return item.inputValue.trim() !== '';
                                        }
                                        return true;
                                      });
                                      setKeywords(filteredArray);
                                    }
                                  }}
                                  filterOptions={(options, params) => {
                                    const filtered = filter(options, params);
                                    const { inputValue } = params;
                                    const isExisting = options.some(
                                      (option) => inputValue === option.keyword
                                    );
                                    if (inputValue !== "" && !isExisting) {
                                      filtered.push({
                                        inputValue,
                                        keyword: inputValue,
                                      });
                                    }

                                    return filtered;
                                  }}
                                  selectOnFocus
                                  clearOnBlur
                                  handleHomeEndKeys
                                  id="free-solo-with-text-demo"
                                  options={selectedOption}
                                  getOptionLabel={(option) => {
                                    if (typeof option === "string") {
                                      return option;
                                    }
                                    if (option.inputValue) {
                                      return option.inputValue;
                                    }
                                    return option.keyword;
                                  }}
                                  renderOption={(props, option) => (
                                    <li {...props}>{option.keyword}</li>
                                  )}
                                  sx={{ width: 300 }}
                                  freeSolo
                                  filterSelectedOptions

                                  renderInput={(params) => (

                                    <TextField {...params} label="" inputRef={input => {
                                      // keywordFocus = input;
                                    }} />
                                  )}
                                />

                              </div>
                            </div>

                            <div className="url-link mb-4">
                              <label htmlFor="username" className="label-form-div mt-0">URL <span>if you want your own url</span></label>
                              <input type="text" class="form-control" id="inputAddress" placeholder="URL'S" name="url" onChange={handleInput} value={payLoad.url} ref={resourceUrl} />
                              {isValid && <span className="error-span">Plz enter valid url.</span>}
                            </div>

                            <div className="mul-sel-div mb-4 ">
                              <h3>
                                Grade Range{" "}
                                <span className="select-one">(select one or multiple)</span>
                              </h3>
                              {/* <label htmlFor="username" className="label-form-div ">Grade Range <span>(select one or multiple)</span></label> */}
                              <div>
                                <Select
                                  onChange={handleselectedOptionGrade}
                                  options={grade && grade.map((temp) => ({
                                    value: temp.id,
                                    label: temp.name,
                                  }))}
                                  value={gardeSelected}
                                  isMulti={true}
                                />
                              </div>
                            </div>
                            <div className="mul-sel-div">
                              <h3>
                                Learning Pillars{" "}
                                <span className="select-one">(select one or multiple)</span>
                              </h3>
                              <div>
                                <Select
                                  value={
                                    learningPillarSelected
                                  }
                                  options={learningPillarData && learningPillarData.map((temp, key) => ({
                                    value: temp.id,
                                    label: temp.name,
                                  }))}
                                  isMulti
                                  // selectMultiple={true}
                                  onChange={handleselectedOptionPillars}
                                  ref={LearningFocus}
                                />
                              </div>
                              {/* <span className="error-span">{error && learningPillarSelected.length == 0 && "Please select learning pillar."}</span> */}

                            </div>
                            <div className="mul-sel-div">
                              <h3>
                                Who would you like to share this group with?<span className="start-color">*</span>{" "}
                                <span className="select-one">(select one or multiple)</span>
                              </h3>
                              {/* <label htmlFor="username" className="label-form-div ">Who would you like to share this group with?<strong>*</strong><span> (select at least one)</span></label> */}
                              <Select
                                name="share_with"
                                onChange={handleselectedOption}
                                // options={shareResourceOptions} 
                                options={shareResourceOptions && shareResourceOptions.map((temp) => ({
                                  value: temp.value,
                                  label: temp.label,
                                }))}
                                value={shareWithSelected}
                                isMulti={true}
                                ref={ShareFocus}
                              />
                              <span className="error-span">{error && shareWithSelected.length == 0 && "Please select who you would like to share this with."}</span>
                            </div>

                            {
                              shareWith?.includes("friend") && <div className="input-popup2">
                                <label className="pop-label">
                                  Select the friends you would like to share this with:{" "}
                                  <span className="select-one">(select at least one)</span>
                                </label>
                                <div>
                                  <Select
                                    value={friendData ? friendData : null}
                                    options={friendList}
                                    isMulti
                                    selectMultiple={true}
                                    onChange={handleFriendsId}
                                  />
                                  <span className="error-span">
                                  </span>
                                </div>
                              </div>
                            }
                            {
                              shareWith?.includes("group") && <div className="input-popup2">
                                <label className="pop-label">
                                  Select the groups you would like to share this with:
                                  <span className="select-one">(select at least one)</span>
                                </label>
                                <div>
                                  <Select
                                    value={
                                      groupData ? groupData : null
                                    }
                                    options={groupList}
                                    isMulti
                                    selectMultiple={true}
                                    onChange={handleGroupId}
                                  />
                                  <span className="error-span">
                                  </span>
                                </div>
                              </div>
                            }

                            <div className="about-group" ref={AboutFocus}>
                              <label htmlFor="username" ><label className="label-form-div mt-0">About this Group</label><span className="start-color">*</span></label>
                              <JoditEditor name="about_group"
                                config={editorConfig}
                                value={aboutGroupData}
                                onChange={(newContent) => setAboutGroupData(newContent)}
                              />
                              <span className="error-span">
                                {
                                  error == true ? aboutGroupData == "" && "Please enter about resource." : " "
                                }
                              </span>
                            </div>

                            <div className="row">
                              <div className="col-lg-12">
                                <div className="permission-group">
                                  <label className="form-h3">Permission to join the group<span className="start-color">* </span><span className="select-one">(select one)</span> </label>
                                  <div className='radio-btn-handle'>
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="radio" name="group_join_permission" checked={payLoad.group_join_permission === "admin_approval"} id="inlineRadio222" onChange={handleInput} value="admin_approval" />
                                      <label className="form-check-label  handle-radio" htmlFor="inlineRadio222">Join upon admin approval</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="radio" name="group_join_permission" checked={payLoad.group_join_permission === "anyone"} id="inlineRadio111" onChange={handleInput} value="anyone" />
                                      <label className="form-check-label  handle-radio" htmlFor="inlineRadio111" >Anyone can join</label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="group-chat-permission">
                              <label htmlFor="username" className="label-form-div ">Group Chat Permission<strong>*</strong></label>
                              <div className="permission">
                                <label className="switch" htmlFor="viewable-friend">
                                  <input type="checkbox" id="viewable-friend" name="group_chat_permission" checked={payLoad.group_chat_permission === "1" ? true : false} onChange={handleInput} value="1" />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-4 mt-2 mb-1">
                              <div className="mul-sel-div">
                                <h3>
                                  Admin Fee (%)
                                </h3>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="fee"
                                  name="fee"
                                  onChange={HnadleGroupAdminFee}
                                  value={transactionFee ? transactionFee : ""}
                                />
                              </div>
                            </div>

                            <div className="admin-group">
                              <h4>Add Group Admin
                              </h4>
                            </div>
                            {inputlist?.map((val, index) => {
                              return (
                                <div className="row">
                                  <div className="col-lg-5">
                                    <label
                                      htmlFor="name"
                                      className="form-label1"
                                    >
                                      Name
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="name"
                                      name="firstName"
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      value={val.firstName}
                                    />
                                    <span className="error-span">
                                      {error == true ? (!inputlist[0].firstName && !inputlist[0].email) && "you need create another admin user." : ""}
                                    </span>
                                  </div>
                                  <div className="col-lg-5">
                                    <label htmlFor="Email" className="form-label1 ">
                                      Email
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="Email"
                                      name="email"
                                      onChange={(event) =>
                                        handleInputChange(index, event)
                                      }
                                      value={val.email}
                                    />

                                  </div>

                                  <div className="col-lg-2">
                                    <label className="w-100 mb-0">&nbsp;</label>
                                    <div className="add add1">
                                      {inputlist.length > 1 && (
                                        <button
                                          className="add-remove"
                                          onClick={() => handleremove(index)}
                                        >
                                          X
                                        </button>
                                      )}

                                    </div>

                                  </div>
                                  <div className=" add">
                                    {inputlist.length - 1 == index && (
                                      <button className="add-add" onClick={handleadd}>
                                        Add more +
                                      </button>
                                    )}
                                  </div>

                                </div>
                              );
                            })}
                            <div className="save-btn1">
                              <button className="s-button" onClick={updateGroup} >
                                Update
                              </button>


                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </Card>
      }
    </Content>
  </>);
}
export default EditGroup;