import Select from "react-select";
import { useState, useRef } from "react";
import Content from "../../../layout/content/Content";
import { Card } from "reactstrap";
import { useHistory, useParams } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { useEffect } from "react";
import {
  makePostRequest,
  makeGetRequest,
  makePostRequestForAll,
  BASE_URL,
  isProduction,
  secretKey,
} from "../../../service/api";
import { URLS } from "../../../constants/urls";
import { getUserDetailsFromToken, isValidFileUploaded, checkUrl, toastifyMessage } from "../../../service/auth";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
const filter = createFilterOptions();
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import axios from 'axios';

const EditCustomer = () => {
  let history = useHistory();
  let my_token = sessionStorage.getItem("auth-token");
  let useractive = sessionStorage.getItem("useractive");
  const headers = { Authorization: "Bearer " + my_token };
  const fref = useRef();
  const lref = useRef();
  const scref = useRef();
  const hcref = useRef();
  const ceref = useRef();
  const ajref = useRef();
  const fbaref = useRef();
  const imgref = useRef();
  const childref = useRef();
  const childrel = useRef();
  const emref = useRef();
  const phref = useRef();
  const zpref = useRef();
  const userRef = useRef();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const queryParams = Object.fromEntries(urlSearchParams.entries());
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [Firth, setFirth] = useState("Yes");
  const [selectchild, setselectchild] = useState("");
  const [selectfb, setSelectfb] = useState("");
  const [selectce, setSelectce] = useState("");
  const [selectaj, setSelectaj] = useState("");
  const [selectfa, setSelectfa] = useState("");
  const [selectffb, setSelectffb] = useState("");
  const [showaddperson, setshowaddperson] = useState(true);
  const [phoneerror, setphoneerror] = useState(false);
  const [emailerror, setemailerror] = useState(false);
  const [ziperror, setziperror] = useState(false);
  const [error, seterror] = useState(false);
  const [file, setFile] = useState("");
  const [childList, setChildList] = useState([]);
  const [userType, setUserType] = useState("");
  const [homeschool, sethomeschool] = useState([]);
  const [imgflag, setimgflag] = useState(false);
  const [inputlist, setinputlist] = useState([]);
  const [childlen, setChildlen] = useState(0);
  const [zipcode, setZipcode] = useState("");
  const [status, setStatus] = useState(false);
  const [selectTimeZone, setSelectTimeZone] = useState("");
  const [Bio, setBio] = useState("");
  const [userTypeData, setUserTypeData] = useState([]);
  const [user, setUser] = useState([]);
  const [countryCode, setCountryCode] = useState('');
  const [dialCode, setDialCode] = useState('+1');
  const [counryName,setCountryNamme]=useState("US")

  const state = location.state;
  var childdisplay = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth",
    "Sixth",
    "Seventh",
    "Eighth",
    "Ninth",
    "Tenth",
    "Eleventh",
    "Twelveth",
    "Thirteenth",
    "Fourteenth",
    "Fifteenth",
    "Sixteenth",
    "Seventeenth",
    "Eighteenth",
    "Nineteenth",
    "Twentieth",
    "Twenty first",
    "Twenty second",
    "Twenty third",
    "Twenty fourth",
    "Twenty fifth",
    "Twenty sixth",
    "Twenty seventh",
    "Twenty eighth",
    "Twenty ninth",
    "Thirtieth",
    "Thirty first",
    "Thirty second",
    "Thirty third",
    "Thirty fourth",
    "Thirty fifth",
    "Thirty sixth",
    "Thirty seventh",
    "Thirty eighth",
    "Thirty ninth",
    "Fourtieth",
    "Fourty first",
    "Fourty second",
    "Fourty third",
    "Fourty fourth",
    "Fourty fifth",
    "Fourty sixth",
    "Fourty seventh",
    "Fourty eighth",
    "Fourty ninth",
    "Fiftieth",
    "Fifty first",
    "Fifty second",
    "Fifty third",
    "Fifty fourth",
    "Fifty fifth",
    "Fifty sixth",
    "Fifty seventh",
    "Fifty eighth",
    "Fifty ninth",
    "Sixtieth",
    "Sixty first",
    "Sixty second",
    "Sixty third",
    "Sixty fourth",
    "Sixty fifth",
    "Sixty sixth",
    "Sixty seventh",
    "Sixty eighth",
    "Sixty ninth",
    "Seventieth",
    "Seventy first",
    "Seventy second",
    "Seventy third",
    "Seventy fourth",
    "Seventy fifth",
    "Seventy sixth",
    "Seventy seventh",
    "Seventy eighth",
    "Seventy ninth",
    "Eightieth",
    "Eighty first",
    "Eighty second",
    "Eighty third",
    "Eighty fourth",
    "Eighty fifth",
    "Eighty sixth",
    "Eighty seventh",
    "Eighty eighth",
    "Eighty ninth",
    "Ninetieth",
    "Ninety first",
    "Ninety second",
    "Ninety third",
    "Ninety fourth",
    "Ninety fifth",
    "Ninety sixth",
    "Ninety seventh",
    "Ninety eighth",
    "Ninety ninth",
    "Hundredth",
  ];

  const [address, setAddress] = useState("");
  const [about, setAbout] = useState("");
  const [locationState, setLocationState] = useState({
    country: "",
    state: "",
    city: "",
    zipcode: "",
    latitude: "",
    longitude: "",
  });
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [timeZone, setTimeZone] = useState([]);
  const [timeZoneList,setTimeZoneList]=useState([])
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  let { id } = useParams();

  console.log(timeZone,"timeZone...........")
  console.log(timeZoneList,"timeZoneList.............")
  //===Functions for Image crop===//

  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      url: "",
    },
  ]);
  const onChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const beforeCrop = (file) => {
    if (
      file.name.includes("jpg") ||
      file.name.includes("png") ||
      file.name.includes("jpeg") ||
      file.name.includes("gif")
    ) {
      setimgflag(false);
      return true;
    } else {
      setimgflag(true);
      return false;
    }
  };

  const onRemove = async () => {
    setimgflag(false);
  };

  //function for fetching user detail

  const handleshowupload = () => {
    setLoading(true);
    let data = {
      id: id,
    };

    makePostRequest(URLS.CUSTOMER_GET_ONE, null, data, null).then((response) => {
      console.log(response,"response getone........")
      setLoading(false);
      setSelectTimeZone(response?.data?.uesrData?.timezone);
      setfirstname(response.data.uesrData.firstName == null ? "" : response.data.uesrData.firstName);
      setlastname(response.data.uesrData.lastName == null ? "" : response.data.uesrData.lastName);
      setBio(response.data.uesrData.bio_about == null ? "" : response.data.uesrData.bio_about);
      const result = response?.data?.uesrData?.tbl_users_roles?.map((item) => ({
        value: item?.tbl_user_role?.id,
        label: item?.tbl_user_role?.role_name,
      }));
      if (response?.data?.uesrData?.countryCode) {
        setDialCode(response?.data?.uesrData?.countryCode); 
        setCountryNamme(response?.data?.uesrData?.country_dial_code?.toLowerCase());
      }
      setReducedPhone(response?.data?.uesrData?.phone);
      setUserTypeData(result);
      setUserType(result.map((val) => val.value));
      response.data.uesrData.faith_background == null
        ? setFirth("Yes")
        : setFirth(response.data.uesrData.faith_background);
      response.data.uesrData.faith_affiliation && setSelectfa(response.data.uesrData.faith_affiliation);
      setSelectffb(
        response.data.uesrData.family_faith_background == null ? "" : response.data.uesrData.family_faith_background
      );
      setSelectce(
        response.data.uesrData.child_education_character_value == null
          ? ""
          : response.data.uesrData.child_education_character_value
      );
      setSelectaj(response.data.uesrData.any_additional_job == null ? "" : response.data.uesrData.any_additional_job);
      setChildList(response.data.childList == null ? [] : response.data.childList);
      setselectchild(
        response.data.uesrData.number_of_child == null || response.data.uesrData.number_of_child == 0
          ? ""
          : response.data.uesrData.number_of_child
      );
      setSelectfb(response.data.uesrData.family_background == null ? "" : response.data.uesrData.family_background);
      setChildlen(
        response.data.uesrData.number_of_child == null || response.data.uesrData.number_of_child == 0
          ? ""
          : response.data.uesrData.number_of_child
      );
      //   setFileList([{ url: uimage + response.data.uesrData.photo }]);

      //   //setimgflag(true)

      const hs = response.data.uesrData.tbl_ideal_homeschools.map((item) => {
        return item.name;
      });
      sethomeschool(hs);
      setAddress(response?.data?.uesrData?.address);
      let addressObj = {
        country: response?.data?.uesrData?.country,
        city: response?.data?.uesrData?.city,
        zipcode: response?.data?.uesrData?.zipcode,
        state: response?.data?.uesrData?.state,
        latitude: response?.data?.uesrData?.latitude,
        longitude: response?.data?.uesrData?.longitude,
      };
      setLocationState(addressObj);
      setZipcode(response?.data?.uesrData?.zipcode);
      setEmail(response?.data?.uesrData?.email);
      // setPhone(response?.data?.uesrData?.phone);
      setPhone(response?.data?.uesrData?.countryCode + response?.data?.uesrData?.phone);
    });
  };

  function setchildno() {
    if (childlen > 0) {
      const values = [];

      for (var i = 0; i < childlen; i++) {
        values.push({
          id: childList[i].id,
          firstName: childList[i].firstName,
          lastName: childList[i].lastName,
          flag: true,
        });
      }
      setinputlist(values);
    }
  }
  //===Function for uploading  user image===//
  function uploadimage(cropimage) {
    // const formData = new FormData();
    // formData.append("photo", cropimage ? cropimage : "");
    // formData.append("id", userData.userAuth.id);
    // makeFormDateRequset(URLS.useruploadprofile, formData).then((response) => {
    //   console.log("profile upload", response);
    //   if (response.code === 200) {
    //     toastifyMessage(response.message, "success");
    //     setTimeout(() => { }, 6000);
    //   }
    // });
  }

  // const onPreview = async () => {
  //   setFileList([])
  // }
  // function handleChange(e) {

  //   checkfile = (e.target.files);
  //   if (checkfile[0]) {

  //     const fileSize = e.target.files[0].size / (1024 * 1024);
  //     if (fileSize > 2) {
  //       toast.error(
  //         "File size exceeded, Please upload file upto 2 mb")
  //     }
  //     else if (checkfile[0].name.includes("png") || checkfile[0].name.includes("jpg") || checkfile[0].name.includes("jpeg")) {
  //       setFile(URL.createObjectURL(e.target.files[0]));
  //       var file1 = e.target.files[0];
  //       const formData = new FormData();
  //       formData.append("photo", file1)
  //       formData.append("id", tokenPayload.userAuth.id)

  //       axios.post(BASE_URL + URLS.useruploadprofile, formData, { headers })
  //         .then(response => {
  //           if (response.data.code === 200) {
  //             toast.success("Profile image uploaded successfully.");
  //             setTimeout(() => {
  //             }, 2000)

  //           }
  //           else {
  //             toast.error(response.data.message);
  //           }
  //         }).catch((error) => {
  //           console.log("errrr", error)
  //         })
  //     }
  //     else {

  //       toast.error("Please upload only png,jpg,jpeg file");
  //     }
  //   }
  // }

  //== Functions for capturing  user data==//

  const handleInputChange = (index, e) => {
    const values = [...inputlist];
    const updatedValue = e.target.name;
    values[index][updatedValue] = e.target.value;
    setinputlist(values);
  };
  const handlefn = (e) => {
    setfirstname(e.target.value);
  };
  const handleln = (e) => {
    setlastname(e.target.value);
  };
  const handlesc = (e) => {
    setselectchild(e.target.value);
  };


useEffect(() => {
    makeGetRequest(URLS.timeZone, null, null, null)
      .then((res) => {
        setTimeZoneList(res.data)
      })
  }, [])



// useEffect(()=>{
//  setSelectTimeZone(timeZone);
// },[zipcode,timeZone]);

  // useEffect ( () =>
  //   {
  //     if(zipcode!=""&&(zipcode?.length==5||zipcode?.length==6))
  //     {
  //     axios.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + zipcode.replace(" ", "") + "&region=US&key=" + process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  //     )
  //     .then((response) =>{
  //       console.log("logitute",response)
  //         if (response.data.status === "OK") {
  //           // 
  //           axios.get("https://maps.googleapis.com/maps/api/timezone/json?location="+response.data.results[0].geometry.location.lat+","+response.data.results[0].geometry.location.lng+"&timestamp=1331161200&key=" + process.env.REACT_APP_GOOGLE_MAP_API_KEY)
  //           .then((response)=>{
             
  //             console.log("timezone",response)
  //             setTimeZone(response.data.timeZoneId=="Asia/Calcutta"?"Asia/Kolkata":response.data.timeZoneId)
          
  //           })
  //           }
  //       }
  //       );
  //     }
  
  //   },[zipcode])

  // useEffect(() => {
  //   // setSelectTimeZone(""); 
  //   const fetchTimezone = async () => {
  //     if (zipcode !== "") {
  //       try {
  //         const geoResponse = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode.replace(" ", "")}&region=US&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`);
          
  //         if (geoResponse.data.status === "OK") {
  //           const { lat, lng } = geoResponse.data.results[0].geometry.location;
  //           const timezoneResponse = await axios.get(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=1331161200&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`);
            
  //           const tz = timezoneResponse.data.timeZoneId === "Asia/Calcutta" ? "Asia/Kolkata" : timezoneResponse.data.timeZoneId;
  //           setTimeZone(tz);
  
  //         } else if (geoResponse.data.status === "ZERO_RESULTS") {
  //           setTimeZone("");
  //           setSelectTimeZone("")
  //           // toastifyMessage("Invalid zip code. Please enter a valid zip code.", "error");
  //         } else {
  //           setTimeZone("");
            
  //         }
  //       } catch (error) {
  //         console.error("Error fetching data", error);
  //       }
  //     }
  //   };
  
  //   fetchTimezone();
  // }, [zipcode,timeZone]);
  useEffect(() => {
    const fetchTimezone = async () => {
      if (zipcode !== "") {
        try {
          const geoResponse = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${zipcode.replace(" ", "")}&region=US&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
          );
  
          if (geoResponse.data.status === "OK") {
            const { lat, lng } = geoResponse.data.results[0].geometry.location;
            const timezoneResponse = await axios.get(
              `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${Math.floor(Date.now() / 1000)}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`
            );
  
            const tz = timezoneResponse.data.timeZoneId === "Asia/Calcutta" ? "Asia/Kolkata" : timezoneResponse.data.timeZoneId;
            setTimeZone(tz);
          } else if (geoResponse.data.status === "ZERO_RESULTS") {
            setTimeZone("");
            setSelectTimeZone(null); // Ensure select reset on invalid zip
          } else {
            setTimeZone("");
          }
        } catch (error) {
          console.error("Error fetching data", error);
        }
      }
    };
  
    fetchTimezone();
  }, [zipcode]);
  
  

  
  const handlechild = () => {
    const values = [];
    if (childlen <= selectchild) {
      for (var i = 0; i < selectchild; i++) {
        values.push({
          id: childList[i]?.id || null,
          firstName: childList[i]?.firstName || "",
          lastName: childList[i]?.lastName || "",
          flag: true,
        });
      }
      setinputlist(values);
    } else {
      toastifyMessage("You cannot reduce the number of students. Please delete the students profile first.", "error");
      setselectchild(childlen);
    }
  };

  const handlehs = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      sethomeschool([...homeschool, value]);
    } else {
      sethomeschool(homeschool.filter((item) => item !== value));
    }
  };
  const fairthHandle = (e) => {
    setFirth(e.target.value);
  };
  const handlece = (e) => {
    setSelectce(e.target.value);
  };
  const handleaj = (e) => {
    setSelectaj(e.target.value);
  };
  const handlefa = (e) => {
    setSelectfa(e.target.value);
  };
  const handleffb = (e) => {
    setSelectffb(e.target.value);
  };
  const handleform = (event) => {
    event.preventDefault();
  };
  const getUserType = () => {
    makeGetRequest(URLS.GET_USER_TYPE, null, {}, null)
      .then((res) => {
        if (res.code == 200) {
          let option = [];
          res?.data?.length > 0 &&
            res?.data?.map((val) => {
              option.push({ value: val.id, label: val.role_name });
            });
          setUserTypeData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // functions for error handling and user data submission//==

  const handlesavedata = () => {
    if (firstname?.length == 0) {
      seterror(true);
      fref.current.focus();
    } else if (lastname === "") {
      //   seterror(true);
      lref.current.focus();
    } else if (email === "") {
      emref.current.focus();
      setEmailErr("Please enter valid email");
    } else if (zipcode == null) {
      setziperror(true);
      zpref.current.focus();
    } else if (zipcode?.length < 5 || zipcode?.length > 6) {
      setziperror(true);
      zpref.current.focus();
    } else if (userTypeData.length <= 0) {
      seterror(true);
      userRef.current.focus();
    } else if (selectchild < 0) {
      seterror(true);
      scref.current.focus();
    } else if (selectchild > 0 && inputlist?.length == 0) {
      seterror(true);
      childref.current.focus();
    }
    else if (selectchild > 0 && inputlist.filter((item) => item.firstName == "")?.length > 0) {
      seterror(true);
      childref.current.focus();
    }
    else {
      if (!emailValidation()) {
        return;
      }
      if (!phoneNumberValidation()) {
        return;
      }
      var objecthomeschool = [];
      for (var i = 0; i < homeschool?.length; i++) {
        var obj = { name: homeschool[i] };
        objecthomeschool.push(obj);
      }
      var tempList = [];
      var x = {};
      for (var i = 0; i < inputlist?.length; i++) {
        if (inputlist[i].id != null) {
          x = inputlist[i];
          x.flag = true;
          tempList.push(x);
        } else {
          x = inputlist[i];
          x.flag = false;

          tempList.push(x);
        }
      }
      let data = {
        id: id,
        firstName: firstname,
        lastName: lastname,
        number_of_child: selectchild <= 0 ? 0 : selectchild,
        child_list: tempList,
        ideal_home_schools: objecthomeschool,
        faith_background: Firth,
        family_faith_background: selectffb,
        faith_affiliation: selectfa,
        child_education_character_value: selectce,
        any_additional_job: selectaj,
        address: address,
        timezone: selectTimeZone,
        user_type_role_id: userTypeData?.map((val) => val.value) || [],
        zipcode: zipcode ? zipcode : locationState.zipcode,
        bio_about: Bio,
        // phone: phone,
        phone: reducedPhone,
        // countryCode: countryCode,
        email: email,
        countryCode: dialCode,//+1
        country_dial_code:counryName,//US
      };
console.log(data,"customer data...............")
      if (locationState.latitude) {
        data.latitude = locationState.latitude;
      }
      if (locationState.longitude) {
        data.longitude = locationState.longitude;
      }
      //   // Api call for user data submission
      makePostRequest(URLS.GET_CUSTOMER_UPDATE, null, data, null).then((response) => {
        if (response.code === 200) {
          toastifyMessage(response.message, "success");
          setTimeout(() => {
            history.push("/customer-list");
          }, 3000);
        } else {
          toastifyMessage(response.message, "error");
        }
      });
    }
  };
  useEffect(() => {
    handlechild();
  }, [selectchild]);

  useEffect(() => {
    if (selectfb == "no") {
      setshowaddperson(false);
      setSelectfb("no");
      setSelectffb("");
      setSelectfa("");
    } else {
      setshowaddperson(true);
      setSelectfb("Yes");
    }
  }, [selectfb]);
  const handleuser = () => {
    makeGetRequest(URLS.userroleget, null, null)
      .then((res) => {
        const result = res.data?.map((item) => ({
          value: item?.id,
          label: item?.role_name,
        }));
        setUser(result);
      })
      .catch((error) => { });
  };

  const getAccounts = () => {
    makePostRequest(URLS.getStripeAccount, null, {}, null)
      .then((res) => {
        const arr = [];
        res?.data?.map((val, index) => {
          if (val?.stripe_setup_done) {
            return arr.push({ value: val?.id, label: val?.email + "(payment verify)", id: val?.stripe_account_id });
          } else {
            return arr.push({ value: val?.id, label: val?.email, id: val?.stripe_account_id });
          }
        });
        setStripeData(arr);
      })
      .catch((error) => {
        console.log("error::", error);
      });
  };
  
  useEffect(() => {
    getUserType();
    handleuser();
    if (useractive == "") {
      navigate("/sign-in");
    }
    // getAccounts()
    // makeGetRequest(URLS.timeZone, null, null, null).then((res) => {
    //   setTimeZone(res.data);
    // });
    handleshowupload();
  }, []);

  useEffect(() => {
    setchildno();
  }, [childList]);

  const options = {
    componentRestrictions: { country: ["US", "IND"] },
    fields: ["address_components", "geometry", "icon", "name"],
    //strictBounds: false,
    types: ["address"],
  };

  // const getGoogleAddressStreetOne = () => {
  //   autoCompleteRef.current = new window.google.maps.places.Autocomplete(inputRef.current, options);
  //   autoCompleteRef.current.addListener("place_changed", async function () {
  //     const place = await autoCompleteRef.current.getPlace();
  //     let addressComponent = place?.address_components;
  //     let fullAddress = "";
  //     addressComponent.forEach((component) => {
  //       fullAddress += component.long_name + ", ";
  //     });
  //     fullAddress = fullAddress.slice(0, -2);
  //     setAddress(fullAddress);
  //     let obj = {
  //       country: "",
  //       city: "",
  //       zipcode: "",
  //       state: "",
  //       latitude: "",
  //       longitude: "",
  //     };
  //     obj.latitude = place.geometry.location.lat();
  //     obj.longitude = place.geometry.location.lng();

  //     for (let i = 0; i < addressComponent?.length; i++) {
  //       if (addressComponent[i].types[0] === "locality") {
  //         obj.city = addressComponent[i].long_name;
  //       }

  //       if (addressComponent[i].types[0] === "postal_code") {
  //         obj.zipcode = addressComponent[i].long_name;
  //       }

  //       if (addressComponent[i].types[0] === "administrative_area_level_1") {
  //         obj.state = addressComponent[i].long_name;
  //       }
  //       if (addressComponent[i].types[0] === "country") {
  //         obj.country = addressComponent[i].long_name;
  //       }
  //     }
  //     setZipcode(obj.zipcode);
  //     setLocationState(obj);
  //   });
  // };

// this function use for  google adress
  const getGoogleAddressStreetOne = () => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      let fullAddress = "";
      if (place?.address_components) {
        let addressComponent = place?.address_components;
        addressComponent.forEach((component) => {
          fullAddress += component.long_name + ", ";
        });
        fullAddress = fullAddress.slice(0, -2);
        setAddress(fullAddress);
        let obj = {
          country: "",
          city: "",
          zipcode: "",
          state: "",
          latitude: "",
          longitude: "",
          short_key: ""
        };
        obj.latitude = place.geometry.location.lat();
        obj.longitude = place.geometry.location.lng();
        for (let i = 0; i < addressComponent?.length; i++) {
          if (addressComponent[i].types[0] === "locality") {
            obj.city = addressComponent[i].long_name;
          }
          if (addressComponent[i].types[0] === "postal_code") {
            obj.zipcode = addressComponent[i].long_name;
          }
          if (addressComponent[i].types[0] === "administrative_area_level_1") {
            obj.state = addressComponent[i].long_name;
            obj.short_key = addressComponent[i].short_name
          }
          if (addressComponent[i].types[0] === "country") {
            obj.country = addressComponent[i].long_name;
          }
        }
        setZipcode(obj.zipcode);
        setLocationState(obj);
      }
    });
  };
  
  useEffect(() => {
    if (!loading) {
      getGoogleAddressStreetOne();
    }
  }, [!loading]);

  const handleAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
    //setemailerror(false);
    setEmailErr("");
  };

  const [reducedPhone, setReducedPhone] = useState("");
  const handlePhoneChange = (value, country) => {
    const reducedPhone = value.replace(country.dialCode, '');
    setCountryNamme((country?.countryCode)?.toUpperCase())
    setPhone(value);
    setReducedPhone(reducedPhone)
    setDialCode(`+${country.dialCode}`);
  };
  
  const handleZipecode = (e) => {
    setZipcode(e.target.value);
    setziperror(false);
  };

  //activate stripe account api
  const stripeAccountActivation = () => {
    setLoading(true);
    let data = {
      id: userData.userAuth.id,
    };
    makePostRequest(URLS.stripeAccountActivation, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          setLoading(false);
          toastifyMessage(res.message, "success");
          window.location.href = res?.data?.url;
        } else if (res.code === 400) {
          setLoading(false);
          toastifyMessage(res.message, "error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (queryParams.acc) {
      navigate("/serve-dashboard");
    }
  }, [queryParams.acc]);

  const emailValidation = () => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(email) === false) {
      setemailerror(true);
      setEmailErr("Please enter valid email");
      emref.current.focus();
      return false;
    }
    return true;
  };

  const phoneNumberValidation = () => {
    var regx = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (regx.test(phone) === false) {
      setphoneerror(true);
      setPhoneErr("Please enter valid phone no.");
      phref.current.focus();
      return false;
    }
    return true;
  };
  useEffect(() => {
    document.body.classList.add("editCustomer");
    return () => {
      document.body.classList.add("editCustomer");
    };
  }, []);
  //== UI for user registration form//==

  return (
    <>
      {loading && <div className="sharepopupOuter backgroundOpacity1"></div>}
      <div className={`${status == true ? "col-lg-10" : "containerparent"}`}>
        <div className="Container-fluid">
          <div className="profileStripSetings pt-5 ">
            <h3 className="nk-block-title page-title mt-3 mb-3 ms-5 pb-4 pt-5">
              Edit customer
              <button
                onClick={() => history.goBack()}
                className="btn btn-outline-light bg-white d-none d-sm-inline-flex float-end btn-right"
              >
                <em className="icon ni ni-arrow-left"></em>
                <span>Back</span>
              </button>
            </h3>
          </div>

          {/* {file != "" ? <img className="img-fluid" src={file} accept="image/png, image/jpeg" /> : <img className="img-fluid" src={User} />}
              <div className="input-file1">
                <div className="upload11" >upload profile image</div>
                <input type="file" className="file-handle" ref={imgref} onChange={handleChange} />
              </div> */}

          <Content>
            <Card className="card-bordered">
              <div className="form-container-parent">
                <div className="row px-3 pt-3">
                  <div className="col-lg-6">
                    <label className="form-label0">
                      <h5 className="form-h3">
                        First Name<span className="start-color">*</span>
                      </h5>
                    </label>
                    <input
                      type="text"
                      className="form-control input-control"
                      id="input1"
                      ref={fref}
                      onChange={handlefn}
                      value={firstname}
                    />
                    {error && firstname == "" ? <span className="error-span"> Please enter first name </span> : ""}
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label0">
                      <h5 className="form-h3">
                        Last Name<span className="start-color">*</span>
                      </h5>
                    </label>
                    <input
                      type="text"
                      className="form-control input-control"
                      id="input2"
                      ref={lref}
                      onChange={handleln}
                      value={lastname}
                    />
                    {error && lastname == "" ? <span className="error-span"> Please enter last name </span> : ""}
                  </div>
                </div>
                <div className="row px-3 pt-3">
                  <div className="col-lg-6">
                    <label className="form-label0">
                      <h5 className="form-h3">
                        Email<span className="start-color">*</span>
                      </h5>
                    </label>
                    <input
                      type="text"
                      className="form-control input-control rounded-pill"
                      id="inputemail"
                      ref={emref}
                      onChange={handleEmail}
                      value={email}
                    />

                    <span className="error-span">{emailErr} </span>
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label0">
                      <h5 className="form-h3">
                        Phone No.<span className="start-color">*</span>
                      </h5>
                    </label>

                    {/* <div>
                  <div className="phone-ul-inner">
      <PhoneInput
        country={counryName}               
        onChange={handleCountryChange} 
        disableDropdown={false}       
        enableSearch={true}             // Enable search in the dropdown
        inputStyle={{ display: 'none' }} // Hide the phone number input
        buttonStyle={{ width: 'auto', cursor: 'pointer' }} 
        dropdownStyle={{ zIndex: 1000 }} // Ensure dropdown visibility
      />
      <span className="dialcode-sapn">{dialCode}</span>
    </div>
                  <input
                    type="text"
                    className="form-control input-control input-admin-phone"
                    id="inputphone"
                    ref={phref}
                    // onChange={(e) => setPhones(commonService.formatPhoneNumebr(e.target.value))}
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                    maxlength="14"
                  />

                  </div> */}
                   <div className="phone-ul-inner">
                  <PhoneInput
                            country={'us'}
                            value={phone}
                            onChange={handlePhoneChange}
                            inputProps={{
                                name: 'phone',
                                required: true,
                                autoFocus: true
                            }}
                            enableSearch={true}
							              placeholder="Phone Number"
                        />
</div>

                    {phoneerror ? <span className="error-span"> Please enter valid phone no. </span> : ""}
                  </div>
                </div>
                <div className="row px-3 pt-3">
                  <div className="col-lg-6">
                    <label className="form-label0">
                      <h5 className="form-h3">Address</h5>
                    </label>
                    <input
                      type="text"
                      name="address"
                      className="form-control input-control rounded-pill"
                      placeholder="Enter address"
                      value={address ? address : ""}
                      onChange={handleAddress}
                      ref={inputRef}
                    />
                  </div>
                  <div className="zip-code-icon col-lg-6">
                    <label className="form-label0">
                      <h5 className="form-h3">
                        Zip Code<span className="start-color">*</span>
                      </h5>
                    </label>
                    <div className="facustomeI">i<div className="tootlippillerinfo">
                  For users outside the U.S. and Canada: If you don't have a zip code,
                   please enter '111111' to continue.
                    This will help us gather location data from the city and state you provide.
                  </div></div>
                    <input
                      type="text"
                      className="form-control input-control rounded-pill"
                      id="inputphone"
                      onChange={handleZipecode}
                      value={zipcode}
                      ref={zpref}
                    />
                     {/* <span className="select-one"> */}
              
                  {/* </span> */}
                    {ziperror && zipcode == null ? <span className="error-span">Please enter valid zipcode </span> : ""}
                    {ziperror && (zipcode?.length < 5 || zipcode?.length > 6) ? (
                      <span className="error-span">Please enter valid zipcode </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row px-3 pt-3">
                   <div className="col-lg-6">
                    <label className="form-label2">
                      <h5 className="form-h3">Time Zone</h5>
                     
                    </label>
                    {/* <select
                      className="form-control"
                      onChange={handleTimeZone}
                      value={selectTimeZone}
                    >
                      <option value="">Choose a time zone</option>
  {Array.isArray(timeZoneList) && timeZoneList.map((temp, index) => (
    <option key={index} value={temp?.nameTimeZone}> {temp?.nameTimeZone} </option>
  ))}
                    </select> */}

                    {zipcode === "111111"  || timeZone === "" ?
                    <select
                      className="form-control"
                      onChange={(e) => setSelectTimeZone(e.target.value)} value={selectTimeZone}>
                      <option value="">Choose a time zone</option>
                      {timeZoneList && timeZoneList.length > 0 && timeZoneList?.map((temp) => (
                        <option value={temp.nameTimeZone}>{temp.nameTimeZone}</option>
                      ))}
                    </select>
                    :
                    <input
                      type="text"
                      className="form-control input-control"
                      id="inputphone"
                      value={timeZone}
                    />
                  }

                    
                    {error && "" ? <span className="error-span controlinput">Please enter your time zone</span> : ""}
                  </div> 



 {/* <div className="col-lg-6">
                  <label className="form-label2">
                    Time Zone
                  </label>
                  <input
                    type="text"
                    className="form-control input-control"
                    id="inputphone"
                    value={timeZone}
                  />
                  <span className="select-one">
                  <div className="facustomeI">i<div className="tootlippillerinfo">
                  The Time zone will be updated based on your zip code.
                  </div></div></span>
                  {error && "" ? (
                    <span className="error-span controlinput">
                      Please enter your grade level
                    </span>
                  ) : (
                    ""
                  )}
                </div> */}

                  <div className="col-lg-6">
                    <label className="form-label2">
                      <h5 className="form-h3">
                        User Type <span className="select-one"> (select one or multiple)</span>
                        <span className="start-color">*</span>
                      </h5>
                      {/* <span className="start-color">*</span> */}
                    </label>
                    <Select
                      value={userTypeData}
                      options={user}
                      isMulti
                      selectMultiple={true}
                      onChange={(e) => setUserTypeData(e)}
                      closeMenuOnSelect={false}
                      ref={userRef}
                    />
                    {error && userTypeData.length <= 0 ? (
                      <span className="error-span controlinput">Please choose user type.</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="rows px-3 pt-3">
                  <div className="biotextareaouter">
                    <div className="radio-div1">
                      <h5 className="form-h3">About Yourself</h5>

                      <div className="form-check p-0 ">
                        <textarea
                          value={Bio}
                          onChange={(e) => setBio(e.target.value)}
                          rows={5}
                          cols={150}
                          style={{ width: "100%", border: "1px solid #dbdfea" }}
                          className="input-control p-1"
                          id="input1"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row px-3 pt-3">
                  <div className="col-lg-12 ">
                    <label className="form-label0">
                      <h5 className="form-h3"> How many students are you creating profiles for?</h5>
                      {/* <span className="start-color">*</span> */}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="input3"
                      // min=""
                      ref={scref}
                      onChange={handlesc}
                      value={selectchild}
                    />
                    {error && selectchild < 0 ? (
                      <span className="error1"> Please enter valid student number </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              {inputlist &&
                inputlist.length > 0 &&
                inputlist.map((val, index) => {
                  return (
                    <>
                      <div className="first-child-detail-cons">
                        <div className="row p-3">
                          <h4 className="first-child-h4">{childdisplay[index]} student's name</h4>
                          <div className="col-lg-6">
                            <label className="form-label2">
                              <small>First Name </small>
                              <span className="start-color">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control rounded"
                              placeholder=""
                              id="input1"
                              name="firstName"
                              ref={childref}
                              onChange={(event) => handleInputChange(index, event)}
                              value={val.firstName}
                            />
                            {error && val.firstName == "" ? (
                              <span className="error1"> Please enter first student name </span>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="col-lg-6">
                            <label className="form-label2">
                              <small>Last Name</small>
                              <span className="start-color">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control rounded"
                              placeholder=""
                              id="input2"
                              name="lastName"
                              ref={childrel}
                              onChange={(event) => handleInputChange(index, event)}
                              value={val.lastName}
                            />
                            {error && val.lastName == "" ? (
                              <span className="error1"> Please enter lastname. </span>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>{" "}
                      </div>
                    </>
                  );
                })}
              <div className="row p-3">
                <div className="col-lg-12">
                  <div className="f-con">
                    <h5 className="form-h3">
                      What would your ideal homeschool schedule look like?{" "}
                      <span className="select-one">(select all that apply)</span>{" "}
                    </h5>

                    <div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineRadio111"
                          ref={hcref}
                          value="Half Day"
                          checked={homeschool.find((element) => element == "Half Day")}
                          onChange={handlehs}
                        />
                        <label className="form-check-label  handle-radio" htmlFor="inlineRadio111">
                          Half Day
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineRadio222"
                          value="Full Day"
                          checked={homeschool.find((element) => element == "Full Day")}
                          onChange={handlehs}
                        />
                        <label className="form-check-label  handle-radio" htmlFor="inlineRadio222">
                          Full Day
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineRadio333"
                          value="4 days week"
                          checked={homeschool.find((element) => element == "4 days week")}
                          onChange={handlehs}
                        />
                        <label className="form-check-label  handle-radio" htmlFor="inlineRadio333">
                          4 Days a Week
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineRadio444"
                          value="5 days week"
                          checked={homeschool.find((element) => element == "5 days week")}
                          onChange={handlehs}
                        />
                        <label className="form-check-label  handle-radio" htmlFor="inlineRadio444">
                          5 Days a Week
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="inlineRadio555"
                          value="experimental"
                          checked={homeschool.find((element) => element == "experimental")}
                          onChange={handlehs}
                        />
                        <label className="form-check-label  handle-radio" htmlFor="inlineRadio555">
                          Experiential/Field Trip Based
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row px-3 pt-3">
                  <div className="col-lg-12">
                    <div className="radio-div">
                      <h5 className="form-h31">
                        Does your family have a faith background? <span className="select-one">(select one)</span>
                      </h5>
                      <div className="radio-btn-handle">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions1"
                            id="inlineRadio6"
                            checked={Firth == "No" ? true : false}
                            value="No"
                            onClick={fairthHandle}
                          />
                          <label className="form-check-label handle-radio" htmlFor="inlineRadio6">
                            No
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="inlineRadioOptions1"
                            id="inlineRadio66"
                            value="Yes"
                            checked={Firth == "Yes" ? true : false}
                            onClick={fairthHandle}
                          />
                          <label className="form-check-label handle-radio" htmlFor="inlineRadio66">
                            Yes
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {Firth != "No" && showaddperson ? (
                <div className="container-2 ">
                  <div className="row px-3">
                    <div className="col-lg-12">
                      <label className="form-label11">If yes, what is your faith affiliation?</label>
                      <input
                        type="text"
                        className="form-control"
                        id="input3"
                        placeholder="Example: Lutheran, Catholic, Baptist, Nondenominational, etc."
                        ref={fbaref}
                        value={selectfa}
                        onChange={handlefa}
                      />
                    </div>
                  </div>
                  <div className="row p-3 pb-0">
                    <div className="col-lg-12">
                      <h5 className="form-h32">
                        How important is it to you to integrate your family's faith background into your student's
                        education ? <span className="select-one">(select one)</span>
                      </h5>

                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions2"
                          id="inlineRadio777"
                          value="Not Important"
                          checked={selectffb == "Not Important"}
                          onChange={handleffb}
                        />
                        <label className="form-check-label handle-radio" htmlFor="inlineRadio777">
                          Not Important
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions2"
                          id="inlineRadio888"
                          value="Somewhat Important"
                          checked={selectffb == "Somewhat Important"}
                          onChange={handleffb}
                        />
                        <label className="form-check-label handle-radio" htmlFor="inlineRadio888">
                          Somewhat Important
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions2"
                          id="inlineRadio999"
                          value="Very Important"
                          checked={selectffb == "Very Important"}
                          onChange={handleffb}
                        />
                        <label className="form-check-label handle-radio" htmlFor="inlineRadio999">
                          Very Important
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row p-3">
                <div className="col-lg-12">
                  <div className="radio-div-3 ">
                    <h5 className="form-h3">
                      How important is it to you to integrate character and values into your student’s education?
                    </h5>
                    <div className="radio-btn-handle">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions3"
                          id="inlineRadio000"
                          ref={ceref}
                          value="Not important"
                          checked={selectce == "Not important"}
                          onChange={handlece}
                        />
                        <label className="form-check-label handle-radio" htmlFor="inlineRadio000">
                          Not Important
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions3"
                          id="inlineRadio321"
                          value="Somewhat important"
                          checked={selectce == "Somewhat important"}
                          onChange={handlece}
                        />
                        <label className="form-check-label handle-radio" htmlFor="inlineRadio321">
                          Somewhat Important
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions3"
                          id="inlineRadio432"
                          value="very important"
                          checked={selectce == "very important"}
                          onChange={handlece}
                        />
                        <label className="form-check-label handle-radio" htmlFor="inlineRadio432">
                          Very Important
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                {userType.includes(2) ? (
                  <div className="row p-3">
                    <div className="col-lg-12">
                      <div className="radio-div1">
                        <h5 className="form-h3">
                          Will you be a full-time homeschool teacher or do you have an additional job?
                        </h5>
                        <div className="radio-btn-handle">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions4"
                              ref={ajref}
                              id="inlineRadiooo"
                              value="Full time"
                              checked={selectaj == "Full time"}
                              onChange={handleaj}
                            />
                            <label className="form-check-label handle-radio" htmlFor="inlineRadiooo">
                              Full Time
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions4"
                              id="inlineRadio2o"
                              value="Additional job"
                              checked={selectaj == "Additional job"}
                              onChange={handleaj}
                            />
                            <label className="form-check-label handle-radio" htmlFor="inlineRadio2o">
                              Working an Additional Job
                            </label>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="publice-btn-save-p">
                  <button className="save-button11" onClick={handlesavedata}>
                    Update
                  </button>
                </div>
              </div>
            </Card>
          </Content>
        </div>
      </div>
    </>
  );
};
export default EditCustomer;
