import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { useEffect, useRef } from "react";
import { URLS } from "../../../constants/urls";
import { BlockHead, BlockHeadContent } from "../../../components/Component";
import {
  Icon,
  ReactDataTable,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import { useHistory } from "react-router-dom";
import { makePostRequest, makeGetRequest } from "../../../service/api";
import DatePicker from "react-datepicker";
import { capital } from "../../../utils/Utils";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import exporting from "highcharts/modules/exporting";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
// exporting(Highcharts);

function SubscriptionPaymentReport() {
  const ExcelJS = require("exceljs");
  const [subscriptionpaymentreportchart, setSubscriptionPaymentReportChart] = useState([]);
  const [subscriptionpaymentreport, setSubscriptionPaymentReport] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [selectyear, setSelectyear] = useState(new Date().getFullYear());
  const [zipcodechart, setZipcodechart] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [selectedfromdate, setSelectedFromDate] = useState(null);
  const [selectedtodate, setSelectedToDate] = useState(null);
  const [limitvalue, setlimitvalue] = useState("");
  const [sortvalue, setSortValue] = useState("");
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [showchart, setshowchart] = useState([]);
  const [showchart1, setshowchart1] = useState([]);
  const [ziperror, setZipError] = useState("");
  const [formDateError, setformDateError] = useState("");
  const [toDateError, settoDateError] = useState("");
  const [clearStateGraph, setClearStateGraph] = useState(false);
  const [clearStateList, setClearStateList] = useState(false);
  const [userId, setUserId] = useState("");
  const [userNameData, setUserNameData] = useState([]);
  const [userName, setUserName] = useState("");
  const [displayvalueSelectListview, setDisplayvalueSelectListview] = useState(true);
  const [displayvalueSelectGraph, setDisplayValueSelectGraph] = useState(true);
  const selectRefList = useRef(null);
  const selectRefGraph = useRef(null);
  const [userNameUsers, setNameUsers] = useState("");

  const year = 2023;
  const curryear = new Date().getFullYear();
  let years = Array.from(new Array(curryear - 2023 + 2), (val, index) => index + year);

  let chartdata = [];
  let chartdata1 = [];

  const handleyear = (e) => {
    setSelectyear(e.target.value);
  };

  const handleZipCodechart = (e) => {
    setZipcodechart(e.target.value);
    setZipError("");
  };
  const handleZipCode = (e) => {
    setZipcode(e.target.value);
  };
  const displaySelectUserList = (e) => {
    if (selectRefList.current) {
      setDisplayvalueSelectListview(!displayvalueSelectListview);
      selectRefList.current.focus();
    }
  };
  const displaySelectGraph = (e) => {
    if (selectRefGraph.current) {
      setDisplayValueSelectGraph(!displayvalueSelectGraph);
      selectRefGraph.current.focus();
    }
  };

  const handlechart = (e) => {
    getsubscriptionpaymentreportchart();
  };
  const handlesearch = (e) => {
    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date");
    } else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ");
    } else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date");
    } else {
      getsubscriptionpaymentlist();
    }
  };

  const HandleFromDate = (e) => {
    setSelectedFromDate(e);
    setDatePickerShow(!datepickershow);
    setformDateError("");
  };

  const HandleToDate = (e) => {
    setSelectedToDate(e);
    setDatePickerShow1(!datepickershow1);
    settoDateError("");
  };
  const HandleLimitValue = (e) => {
    setlimitvalue(e.target.value);
    setCurrentPage(1);
  };
  const HandleSort = (e) => {
    setSortValue(e.target.value);
  };
  const HandleUserName = (e) => {
    setNameUsers(e.value);
    setUserName(e.label);
  };
  const HandleUserNameList = (e) => {
    setUserId(e.value);
  };
  function handleclickdatepickericon() {
    setDatePickerShow(!datepickershow);
  }
  function handleclickdatepickericon1() {
    setDatePickerShow1(!datepickershow1);
  }
  const clearFilterGraph = () => {
    clearFilterGraphFun();
  };

  const clearFilterGraphFun = () => {
    selectRefGraph.current.select.setValue("", "select-option");
    setUserName("");
    setZipcodechart("");
    setSelectyear(new Date().getFullYear());
    setClearStateGraph(!clearStateGraph);
  };
  const clearFilterList = () => {
    clearFilterListFun();
  };

  const clearFilterListFun = () => {
    setUserName("");
    selectRefList.current.select.setValue("", "select-option");
    setUserId("");
    setlimitvalue("");
    setSortValue("");
    setZipcode("");
    setCurrentPage(1);
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setClearStateList(!clearStateList);
  };

  const ExportEcelFile = () => {
    setLoader(true);

    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      payment_frequency_type: zipcode ? zipcode : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      user_id:userId,
      export_data: "yes",
    };
    makePostRequest(URLS.GET_SUBSCRIPTION_SALE_LIST, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          // console.log("subscription payment  export", res.data.list_data);
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("SubscriptionPaymentReport");
          sheet.properties.defaultRowHeight = 30;

          sheet.getRow(1).font = {
            size: 10,
            bold: true,
          };

          sheet.columns = [
            {
              header: "S.No",
              key: "S_No",
              width: 5,
            },
            {
              header: "Subscription Date",
              key: "REGISTERDATE",
              width: 30,
            },
            {
              header: "Customer Name",
              key: "USER_NAME",
              width: 30,
            },

            {
              header: "Email",
              key: "OWNEREMAIL",
              width: 30,
            },
            {
              header: "Phone",
              key: "OWNWERPHONE",
              width: 30,
            },

            {
              header: "Payment Frquency",
              key: "payment_frequency_type",
              width: 20,
            },
            {
              header: "Amount($)",
              key: "amount",
              width: 20,
            },
          ];
          res.data.list_data?.map((val, i) => {
            sheet.addRow({
              S_No: i + 1,
              REGISTERDATE: moment(val.created_at).format("YYYY-MM-DD"),
              USER_NAME:
                (val?.tbl_payment_history?.purchased_user?.firstName
                  ? val.tbl_payment_history.purchased_user.firstName
                  : "--") +
                " " +
                (val?.tbl_payment_history?.purchased_user?.lastName
                  ? val.tbl_payment_history.purchased_user.lastName
                  : ""),
              OWNEREMAIL: val?.tbl_payment_history?.purchased_user
                ? val?.tbl_payment_history?.purchased_user.email
                : "--",
              OWNWERPHONE: val?.tbl_payment_history?.purchased_user
                ? val?.tbl_payment_history?.purchased_user.phone
                : "--",
              payment_frequency_type: val?.tbl_subscription_plan?.payment_frequency_type,
              amount: val?.total_amount ? val.total_amount : "0.00",
            });
          });

          workbook.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            });
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement("a");
            anchor.href = url;
            anchor.download = "subscriptionpaymentreport.xlsx";
            anchor.click();
            window.URL.revokeObjectURL(url);
          });
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getsubscriptionpaymentreportchart = () => {
    setLoader(true);
    let data = {
      year: selectyear,
      payment_frequency_type: zipcodechart ? zipcodechart : "",
      user_id: userNameUsers ? userNameUsers : "",
    };
    console.log(data,"graph data")
    makePostRequest(URLS.GET_SUBSCRIPTION_SALE_GRAPH, null, data, null)
      .then((res) => {
        if (res.code === 200) {
          setSubscriptionPaymentReportChart(res?.data?.graph_data);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /*========================================user list api =================================*/

  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState();

  const getsubscriptionpaymentlist = () => {
    setLoader1(true);
    let data = {
      start_date: selectedfromdate ? moment(selectedfromdate).format("YYYY-MM-DD") : "",
      end_date: selectedtodate ? moment(selectedtodate).format("YYYY-MM-DD") : "",
      payment_frequency_type: zipcode ? zipcode : "",
      page_number: currentPage ? currentPage : 1,
      item_per_page: limitvalue ? parseInt(limitvalue) : 10,
      user_id:userId,
    };
    makePostRequest(URLS.GET_SUBSCRIPTION_SALE_LIST, null, data, null).then((res) => {
      setLoader1(true);
      if (res.code === 200) {
        setSubscriptionPaymentReport(res?.data?.list_data);
        setCount(Math.ceil(res.data.total_count / parseInt(limitvalue ? limitvalue : 10)));
        setLoader1(false);
      } else {
        setLoader1(false);
      }
    });
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  useEffect(() => {
    getsubscriptionpaymentlist();
  }, [currentPage, limitvalue, sortvalue]);

  useEffect(() => {
    getsubscriptionpaymentreportchart();
    getsubscriptionpaymentlist();
    getUserName();
  }, []);

  useEffect(() => {
    getchartdata();
  }, [subscriptionpaymentreportchart]);
  useEffect(() => {
    getsubscriptionpaymentreportchart();
  }, [clearStateGraph]);

  useEffect(() => {
    getsubscriptionpaymentlist();
  }, [clearStateList]);

  const getchartdata = () => {
    subscriptionpaymentreportchart?.map((val) => {
      chartdata.push(val.total_subscribed_count);
      chartdata1.push(parseFloat(val.total_subscribed_amount));
    });
    setshowchart(chartdata);
    setshowchart1(chartdata1);
  };

  const getUserName = () => {
    makePostRequest(URLS.GET_SUBSCRIPTION_PURCHASE_CUSTOMER, null, {}, null)
      .then((res) => {
        // console.log(res,"res")
        if (res.code == 200) {
          let option = [];
          option.push({ value: "", label: "All Users" });
          res?.data?.data?.length > 0 &&
            res?.data?.data?.map((val) => {
              option.push({ value: val?.tbl_user?.id, label: val?.tbl_user?.firstName + " " + val?.tbl_user?.lastName });
            });
          setUserNameData(option);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const columns = [
    {
      name: "#",
      selector: (row) => "",
      grow: 0,
      minWidth: "60px",
      style: { minWidth: "60px" },

      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            {" "}
            {currentPage == 1
              ? index + 1
              : (currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10) + (index + 1)}{" "}
          </div>
        </>
      ),
    },
    {
      name: "Customer Details",
      selector: (row) => row.firstName,
      grow: 4,
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2">
            <div className="user-info">
              <span className="tb-lead">
                {" "}
                {row?.tbl_payment_history?.purchased_user
                  ? row?.tbl_payment_history?.purchased_user?.firstName
                  : "--"}{" "}
                {row?.tbl_payment_history?.purchased_user?.lastName
                  ? row?.tbl_payment_history?.purchased_user?.lastName
                  : ""}
              </span>
              <span className="sub-text">
                {row?.tbl_payment_history?.purchased_user ? row?.tbl_payment_history?.purchased_user?.email : ""}
              </span>
              <span className="sub-text">
                {/* {row?.tbl_payment_history?.purchased_user ? row?.tbl_payment_history?.purchased_user?.phone : ""} */}
              </span>
            </div>
          </div>
        </>
      ),
    },
    {
      name: "Subscribe Date",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          {row.subscribed_date ? moment(row.subscribed_date).format("MM-DD-YYYY") : "--"}
          <br />
        </div>
      ),
    },
    {
      name: "Payment Frequency Type",
      grow: 3,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {row?.tbl_subscription_plan?.payment_frequency_type
            ? capital(row.tbl_subscription_plan.payment_frequency_type)
            : ""}
          <br />
        </div>
      ),
    },
    {
      name: "Amount",
      grow: 2,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          {" "}
          {row?.total_amount
            ? new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(row.total_amount)
            : "$0.00"}
          <br />
        </div>
      ),
    },
    {
      name: "View",
      grow: 1,
      sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <Link to={`/view-subscriptionplan/${row.subscription_id}`}>
            {" "}
            <button className="dropdown-item btn btn-md">
              <em className="icon ni ni-eye"></em>
            </button>
          </Link>
        </div>
      ),
    },
  ];
  

  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  };
  const handleOutSideClick1 = (e) => {
    if (newRef1.current && !newRef1.current.contains(e.target)) {
      setDatePickerShow1(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1);
    };
  });

  return (
    <React.Fragment>
      <Head title="Subscription Payment Report"></Head>
      <Content>
        <h3 className="nk-block-title page-title mb5 pb-4"> Subscription Payment Report</h3>
        <>
          <Card className="card-bordered">
            <div className="card-inners relativeClass">
              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                    <div className="p-4">
                      <div className="filtersidebutton">
                        <h3 className="graphheading  mb-0 mt-1">Graph View</h3>
                        <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterGraph}>
                          Clear Filters
                        </button>
                      </div>

                      <div className="row">
                      <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Customer Name</strong>
                            </label>
                            <div className="form-control-wrap paymetsession">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelectGraph}></i>
                              <Select
                                className="form-select"
                                defaultvalue={userName}
                                options={userNameData}
                                openMenuOnFocus={displayvalueSelectGraph}
                                onChange={HandleUserName}
                                ref={selectRefGraph}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Select Year</strong>
                            </label>

                            <div className="form-control-wrap">
                              <select className="form-select" value={selectyear} onChange={handleyear}>
                                {years.map((year, index) => {
                                  return (
                                    <option key={`year${index}`} value={year}>
                                      {year}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4">
                          <label>
                            <strong>Frequency Type</strong>
                          </label>
                          <div className="form-control-text">
                            <select className="form-select pl-2" value={zipcodechart} onChange={handleZipCodechart}>
                              <option value="">Select Frequency Type</option>

                              <option value="monthly">Monthly</option>
                              <option value="yearly">Yearly</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-control border-0 px-0">
                            <label className="w-100 mb-0"></label>
                            <button className="btn btn-primary" type="submit" onClick={handlechart}>
                              {" "}
                              View{" "}
                            </button>
                          </div>
                        </div>
                        <span className="error">{ziperror}</span>
                      </div>
                    </div>

                    <div className="p-5">
                      <div className="row">
                        <div className="col-lg-6"></div>
                      </div>
                      <div className="relativeClass">
                        {loader ? (
                          <div className="overflowloader" id="preload">
                            <div className="circle-square">
                              <div className="red" style={{ background: "#AAC0CE" }}></div>
                              <div className="blue" style={{ background: "#265472" }}></div>
                              <div className="green" style={{ background: "#F19357" }}></div>
                              <div className="yellow" style={{ background: "#F0B285" }}></div>
                            </div>
                          </div>
                        ) : (
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={{
                              chart: {
                                type: "line",
                              },
                              tooltip: {
                                shared: true,
                                split: false,
                                enabled: true,
                              },
                              title: {
                                text: "Monthly Subscription Payment Chart",
                                style: { color: "#003C71", fontSize: "25px" },
                              },

                              xAxis: {
                                categories: [
                                  "Jan",
                                  "Feb",
                                  "Mar",
                                  "Apr",
                                  "May",
                                  "Jun",
                                  "Jul",
                                  "Aug",
                                  "Sep",
                                  "Oct",
                                  "Nov",
                                  "Dec",
                                ],
                                labels: {
                                  style: {
                                    color: "#364a63",
                                  },
                                },
                              },
                              credits: {
                                enabled: false,
                              },
                              exporting: {
                                enabled: true,
                              },

                              plotOptions: {
                                line: {
                                  dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                      return this.y != 0
                                        ? new Intl.NumberFormat("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                          }).format(this.y)
                                        : "$0.00";
                                    },
                                  },
                                  enableMouseTracking: true,
                                },
                              },

                              yAxis: {
                                title: {
                                  text: "Subscriptions",
                                  style: {
                                    color: "#364a63",
                                  },
                                },
                                labels: {
                                  style: {
                                    color: "#364a63",
                                  },
                                },
                              },
                              series: [
                                {
                                  name: "Month",
                                  data: showchart1,
                                  color: "#364a63",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="row">
                        <div className="d-flex justify-content-between mb-2">
                          <div className="filtersidebutton">
                            <h3 className="graphheading mb-0 mt-1">List View</h3>
                            <button className="btn btn-md btn-primary" type="submit" onClick={clearFilterList}>
                              Clear Filters
                            </button>
                          </div>

                          <div className="row">
                            <div className="col-lg-12 d-flex  justify-content-end">
                              <select
                                value={sortvalue}
                                className="form-select"
                                style={{ width: "190px" }}
                                onChange={HandleSort}
                              >
                                <option value="">Sort By</option>
                                <option value="ASCDATE">Date Ascending</option>
                                <option value="DESCDATE">Date Descending</option>
                              </select>

                              <div>
                                <label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label>
                              </div>
                              <div>
                                <select
                                  value={limitvalue}
                                  className="form-select"
                                  style={{ width: "85px" }}
                                  onChange={HandleLimitValue}
                                >
                                  <option value="10">10</option>
                                  <option value="20">20</option>
                                  <option value="30">30</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                </select>
                              </div>
                              <div>
                                &nbsp; &nbsp;
                                <button className="btn btn-primary " onClick={ExportEcelFile}>
                                  <em className="icon ni ni-download-cloud"></em>
                                  <span>Export</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">

                        <div className="col-lg-4">
                          <div className="form-control border-0 px-0">
                            <label>
                              <strong>Customer Name</strong>
                            </label>

                            <div className="form-control-wrap saveZindex paymetsession">
                              <i className="fa fa-chevron-down chevron-downcustome" onClick={displaySelectUserList}></i>
                              <Select
                                className="form-select"
                                defaultvalue={userId}
                                options={userNameData}
                                openMenuOnFocus={displayvalueSelectListview}
                                onChange={HandleUserNameList}
                                ref={selectRefList}
                              />
                            </div>
                          </div>
                        </div>

                          <div className="col-lg-4">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>Start Date</strong>
                              </label>
                              <div className="form-control-wrap" ref={newRef}>
                                <div className="form-icon form-icon-left">
                                  <span onClick={() => handleclickdatepickericon()}>
                                    <Icon name="calendar"></Icon>
                                  </span>
                                </div>
                                <DatePicker
                                  placeholderText="MM/DD/YYYY"
                                  selected={selectedfromdate}
                                  onChange={HandleFromDate}
                                  onFocus={() => setDatePickerShow(!datepickershow)}
                                  className="form-control date-picker"
                                  open={datepickershow}
                                />
                              </div>
                            </div>
                            <span className="error">{formDateError}</span>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-control border-0 px-0">
                              <label>
                                <strong>End Date</strong>
                              </label>
                              <div className="form-control-wrap" ref={newRef1}>
                                <div className="form-icon form-icon-left">
                                  <span onClick={() => handleclickdatepickericon1()}>
                                    <Icon name="calendar"></Icon>
                                  </span>
                                </div>
                                <DatePicker
                                  placeholderText="MM/DD/YYYY"
                                  selected={selectedtodate}
                                  onChange={HandleToDate}
                                  onFocus={() => setDatePickerShow1(!datepickershow1)}
                                  className="form-control date-picker"
                                  open={datepickershow1}
                                />
                              </div>
                            </div>
                            <span className="error">{toDateError}</span>
                          </div>
                          <div className="col-md-4">
                            <label>
                              <strong>Frequency Type</strong>
                            </label>
                            <div className="form-control-text">
                              <select className="form-select pl-2" value={zipcode} onChange={handleZipCode}>
                                <option value="">Select Frequency Type</option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row p-2">
                        <div className="col-lg-3 align-self-end">
                          <div className="outerTypeBox mb-3">
                            <button className="btn btn-primary" type="submit" onClick={handlesearch}>
                              {" "}
                              Search{" "}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="relativeClass newregisterGrouptable">
                      {loader1 ? (
                        <div className="overflowloader" id="preload">
                          <div className="circle-square">
                            <div className="red" style={{ background: "#AAC0CE" }}></div>
                            <div className="blue" style={{ background: "#265472" }}></div>
                            <div className="green" style={{ background: "#F19357" }}></div>
                            <div className="yellow" style={{ background: "#F0B285" }}></div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {subscriptionpaymentreport?.length > 0 ? (
                        <div className="color">
                          <ReactDataTable
                            data={subscriptionpaymentreport}
                            columns={columns}
                            className="nk-tb-list"
                          />
                        </div>
                      ) : (
                        " "
                      )}
                    </div>

                    <div className="p-4">
                      {subscriptionpaymentreport?.length > 0 ? (
                        <div>
                          <ReactPaginate
                            previousLabel={"<<"}
                            nextLabel={">>"}
                            pageCount={count}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            activeClassName={"active"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      ) : (
                        "  No Data Found "
                      )}
                    </div>
                  </BlockHead>
                </BlockHeadContent>
              </div>
            </div>
          </Card>
        </>
      </Content>
    </React.Fragment>
  );
}
export default SubscriptionPaymentReport;
