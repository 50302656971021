import React, { useState, useRef } from "react";
import Head from "../../../../layout/head/Head";
import Content from "../../../../layout/content/Content";
import { useEffect } from "react";
import { URLS } from "../../../../constants/urls";
import {
  ReactDataTable,
  Icon
} from "../../../../components/Component";
import { Card, Input } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import { Modal, ModalFooter, ModalHeader } from "reactstrap";
import { CSVLink } from "react-csv";
import ReactPaginate from "react-paginate";
import { makePostRequest } from "../../../../service/api";
import { BlockHeadContent, BlockHead } from "../../../../components/Component";
import { S3_BUCKET_URL } from "../../../../constants";
import DatePicker from "react-datepicker";
import { FaFilter } from "react-icons/fa";
import Select from "react-select";
const ExcelJS = require('exceljs');
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


function PaymentReport() {
  const [mentordata, setmentordata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [childsessiondetail, setchildsessiondetail] = useState([])
  const [childparentdata, setchildparentdata] = useState([])
  const [buyerdata, setBuyerdata] = useState([]);
  const [sellerdata, setSellerdata] = useState([]);
  const [childdata, setchilddata] = useState([])
  const [parentid, setparentid] = useState("")
  const [childid, setchildid] = useState('')
  const [getCustomer, setGetCustomer] = useState("")
  const [alldata, setalldata] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  let [count, setCount] = useState()
  const [pageCount, setPageCount] = useState(null)
  const [search, setSearch] = useState("")
  const [selectedfromdate, setSelectedfromDate] = useState(null)
  const [selectedtodate, setSelectedToDate] = useState(null)
  const [paymentFor, setPaymentFor] = useState("")
  const [modalData, setModalData] = useState({});
  const [limitvalue, setlimitvalue] = useState("")
  const [sortpayment, setSortPayment] = useState("")
  const [buyer, setBuyer] = useState("")
  const [seller, setSeller] = useState("")
  const [formDateError, setformDateError] = useState("");
  const [toDateError, settoDateError] = useState("");
  const [datepickershow, setDatePickerShow] = useState(false);
  const [datepickershow1, setDatePickerShow1] = useState(false);
  const [clearState, setClearState] = useState(false)

  const [userName, setUserName] = useState(true);
  const [mentorName, setMentorName] = useState(true);

  const handleAction = (data) => {
    setModalData(data);
    setIsOpen(true);
  };

  // console.log("modalData",modalData)

  const HandleFromDate = (e) => {
    setSelectedfromDate(e)
    setDatePickerShow(!datepickershow);
    setformDateError("")

  }
  const HandleToDate = (e) => {
    setSelectedToDate(e)
    setDatePickerShow1(!datepickershow1);
    settoDateError("")

  }
  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const [show, setShow] = useState(false)
  const ShowHideSelect = () => {
    setShow(!show)
  }
  const HandleLimitValue = (e) => {

    setlimitvalue(e.target.value)
    setCurrentPage(1)
  }
  const HandleSortPayment = (e) => {
    setSortPayment(e.target.value)
    setCurrentPage(1)

  }
  function getTime(time) {
    let [h, m] = time.split(':');


    let res, prefix;
    let date = new Date();
    if (h > 12) {
      h = h - 12;
      prefix = "PM"
    }
    else {
      prefix = "AM"
    }
    date.setHours(h, m, 0)
    date.toString();
    if (date.getHours() < 10 && date.getMinutes() < 10)
      res = `0${date.getHours()}:0${date.getMinutes()}`;
    else if (date.getHours() < 10 && date.getMinutes() > 10)
      res = `0${date.getHours()}:${date.getMinutes()}`;
    else
      if (date.getMinutes() < 10 && date.getHours() > 10)
        res = `${date.getHours()}:0${date.getMinutes()}`;
      else

        res = `${date.getHours()}:${date.getMinutes()}`
    res = res + " " + prefix;
    return res;

  }

  function handleclickdatepickericon() {


    setDatePickerShow(!datepickershow);

  }
  function handleclickdatepickericon1() {


    setDatePickerShow1(!datepickershow1);


  }
  useEffect(() => {
    getallmentordata()
  }
    , [sortpayment, limitvalue])

  const ExportEcelFile = () => {

    setLoader(true)
    let data = {
      "page_number": currentPage,
      "payment_for": paymentFor ?? "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
      "start_date": selectedfromdate ?? "",
      "buyer_id": buyer.value ?? "",
      "seller_id": seller.value ?? "",
      limit: limitvalue ? limitvalue : 10,
      sortby: sortpayment ? sortpayment : "",
      key: "export"
    };
    makePostRequest(URLS.GET_ALL_PAYMENT_HISTORY_EXPORT, null, data, null)
      .then((res) => {
        console.log("Success", res)
        if (res.code == 200) {
          //  setmentordata(res.data.data);
          //  setCount(res.data.count/10)
          const workbook = new ExcelJS.Workbook();
          const sheet = workbook.addWorksheet("PaymentReportData");
          sheet.properties.defaultRowHeight = 30;


          sheet.getRow(1).font = {

            size: 10,
            bold: true,
          }

          sheet.columns = [
            {
              header: "S.No",
              key: 'S_No',
              width: 5
            },
            {
              header: "PURCHASED/BOOKED DATE",
              key: 'PURCHASED_BOOKED_DATE',
              width: 15
            },
            {
              header: "RESOURCE TITLE",
              key: 'RESOURCE_TITLE',
              width: 15
            },
            {
              header: "PAYMENT FOR",
              key: 'PAYMENT_FOR',
              width: 23
            },
            {
              header: "BUYER NAME",
              key: 'BUYER_NAME',
              width: 23
            },
            {
              header: "BUYER EMAIL",
              key: 'BUYER_EMAIL',
              width: 23
            },
            {
              header: "BUYER PHONE",
              key: 'BUYER_PHONE',
              width: 23
            },
            {
              header: "SELLER NAME",
              key: 'SELLER_NAME',
              width: 23
            },
            {
              header: "SELLER EMAIL",
              key: 'SELLER_EMAIL',
              width: 23
            },
            {
              header: "SELLER PHONE",
              key: 'SELLER_PHONE',
              width: 23
            },
            // {
            //   header:"STUDENT NAME",
            // key:'CHILD_NAME',
            // width:23
            // },
            {
              header: "PRICE",
              key: 'PRICE',
              width: 23
            },
            // {
            //   header:"AMOUNT",
            // key:'AMOUNT',
            // width:15
            // },
            // {
            //   header:"TRANSACTON FEES",
            // key:'TRANSACTON_FEES',
            // width:23
            // },
            {
              header: "TAX AMOUNT",
              key: 'TAX_AMOUNT',
              width: 23
            },
            {
              header: "TOTAL AMOUNT",
              key: 'GRAND_TOTAL',
              width: 23
            },
            {
              header: "MATCHED REVENUE",
              key: 'MATCHED_REVENUE',
              width: 23
            },
            {
              header: "SELLER/MENTOR PAYMENT",
              key: 'SELLER_MENTOR_PAYMENT',
              width: 23
            },
            {
              header: "INVOICE NUMBER",
              key: 'INVOICE_NUMBER',
              width: 23
            },
            {
              header: "TRANSACTION ID",
              key: 'TRANSACTION_NUMBER',
              width: 23
            },

            {
              header: "TRANSFER TO SELLER/MENTOR",
              key: 'TRANSFER_TO_SELLERMENTOR',
              width: 23
            },






          ];

          res?.data?.data?.map((val, i) => {
            sheet.addRow({
              S_No: (i + 1),
              PURCHASED_BOOKED_DATE: moment(val?.created_at).format("MM-DD-YYYY"),

              PAYMENT_FOR: val?.payment_for == "book_session" ? "Booked Session" : "Resource Purchased",
              BUYER_NAME: val?.purchased_user?.firstName ? val?.purchased_user?.firstName + " " + val?.purchased_user?.lastName : "--",
              BUYER_EMAIL: val?.purchased_user?.email ? val?.purchased_user?.email : "--",
              BUYER_PHONE: val?.purchased_user?.phone ? val?.purchased_user?.phone : "--",

              SELLER_NAME: val?.payment_for == "book_session" ? val?.tbl_user_mentoring_sessions[0]?.sold_by_user?.firstName ? val?.tbl_user_mentoring_sessions[0]?.sold_by_user?.firstName + " " + val?.tbl_user_mentoring_sessions[0]?.sold_by_user?.lastName : "--" : val?.tbl_resource_purchase?.tbl_user_resource?.tbl_user?.firstName ? val?.tbl_resource_purchase?.tbl_user_resource?.tbl_user?.firstName + " " + val?.tbl_resource_purchase?.tbl_user_resource?.tbl_user?.lastName : "--",
              SELLER_EMAIL: val?.payment_for == "book_session" ? val?.tbl_user_mentoring_sessions[0]?.sold_by_user?.email ? val?.tbl_user_mentoring_sessions[0]?.sold_by_user?.email : "--" : val?.tbl_resource_purchase?.tbl_user_resource?.tbl_user?.email,
              SELLER_PHONE: val?.payment_for == "book_session" ? val?.tbl_user_mentoring_sessions[0]?.sold_by_user?.phone ? val?.tbl_user_mentoring_sessions[0]?.sold_by_user?.phone : "--" : val?.tbl_resource_purchase?.tbl_user_resource?.tbl_user?.phone,
              // CHILD_NAME: val?.payment_for == "book_session"?val?.tbl_user_mentoring_sessions?.tbl_child?val?.tbl_user_mentoring_sessions?.tbl_child.firstName+" "+val?.tbl_user_mentoring_sessions?.tbl_child.lastName:"--":val?.tbl_resource_purchase?.tbl_resource_saveds?.map((v)=>v.tbl_child.firstName+" "+v.tbl_child.lastName).join(", "),
              // CHILD_NAME: val?.tbl_user_mentoring_session?.tbl_child?val?.tbl_user_mentoring_session?.tbl_child.firstName+" "+val?.tbl_user_mentoring_session?.tbl_child.lastName:"--",
              PRICE: val?.payment_for == "book_session" ? val?.tbl_user_mentoring_sessions[0]?.price_per_duration ? val.tbl_user_mentoring_sessions[0]?.price_per_duration : "--" : val.tbl_resource_purchase?.tbl_user_resource?.purchase_price,
              RESOURCE_TITLE: val?.payment_for == "book_session" ? val?.tbl_user_mentoring_sessions[0]?.tbl_user_resource?.resource_title ? val?.tbl_user_mentoring_sessions[0]?.tbl_user_resource?.resource_title : "--" : val?.tbl_resource_purchase?.tbl_user_resource?.resource_title,
              // AMOUNT: val?.total_amount?val?.total_amount:"--",
              // TRANSACTON_FEES: val?.transaction_fee?val?.transaction_fee:"--",
              TAX_AMOUNT: val?.tax_amount ? val?.tax_amount : "--",
              GRAND_TOTAL: val?.total_amount ? val?.total_amount : "--",
              MATCHED_REVENUE: val?.admin_fee ? val?.admin_fee : "--",
              SELLER_MENTOR_PAYMENT: val?.tbl_user_mentoring_sessions[0]?.mentor_amount ? val?.tbl_user_mentoring_sessions[0].mentor_amount : "--",
              INVOICE_NUMBER: val?.tbl_user_mentoring_sessions[0]?.invoice_number ? val?.tbl_user_mentoring_sessions[0].invoice_number : "--",
              TRANSACTION_NUMBER: val?.payment_intent ? val.payment_intent : "--",
              TRANSFER_TO_SELLERMENTOR: val?.tbl_user_mentoring_sessions[0]?.transferred_amount ? val?.tbl_user_mentoring_sessions[0].transferred_amount : "--",

            })
          })

          workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet",
            })
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'PaymentReport.xlsx';
            anchor.click();
            window.URL.revokeObjectURL(url)
          })
          //  console.log("data",res)
          setLoader(false)
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
      });

  }
  // const csvLink = useRef();

  // let headers = [
  //   { label: "DATE", key: "date" },
  //   { label: "AMOUNT", key: "amount" },
  //   { label: "PAYMENT FOR", key: "paymentFor" },
  //   { label: "BUYER", key: "buyer" },
  //   { label: "SELLER", key: "seller" },
  //   { label: "TRANSACTION NUMBER", key: "transactionNumber" },

  // ];
  // let mentorDatas = [];
  // mentordata &&
  // mentordata.map((val, i) => {
  //      mentorDatas.push({
  //       date:
  //       val?.created_at,

  //       amount : val?.total_amount?.toFixed(2),
  //       paymentFor : val?.payment_for == "book_session" ? "Book Session" : "Resource Purchased",
  //       buyer : val?.tbl_user_mentoring_session?.purchased_user?.firstName,
  //       seller : val?.tbl_user_mentoring_session?.sold_by_user?.firstName,

  //      transactionNumber : val?.payment_intent

  //     });
  //   });

  //   const downloadCsv = () => {
  //     if (mentorDatas.length!=0) {
  //       csvLink.current.link.click();
  //     } else {
  //       toast.error("No data import");
  //     }
  //   };

  const columns = [
    {
      name: '#',
      selector: row => "1",
      grow: 0,
      minWidth: "80px",
      style: { minWidth: "80px" },
      borderRadius: "4px",
      cell: (row, index) => (
        <>
          <div className="user-card mt-2 mb-2"> {currentPage == 1 ? (index + 1) : ((currentPage - 1) * (limitvalue != "" ? parseInt(limitvalue) : 10)) + (index + 1)} </div>
        </>
      )
    },
    {
      name: " Date",
      selector: (row) => row?.created_at,
      grow: 1,
      minWidth: "120px",
      style: { minWidth: "120px" },
      // sortable: true,
      cell: (row) => <span className="tb-lead">{moment(row?.created_at).format("MM-DD-YYYY")}</span>,
    },
    // {
    //       name: " Amount",
    //       selector: (row) =>row?.total_amount,
    //       grow: 1,
    //       sortable: true,
    //       cell: (row) => <span className="sub-text">${row?.total_amount?.toFixed(2)}</span>,
    //     },
    {
      name: "Payment For",
      selector: (row) => row?.payment_for,
      grow: 1,
      minWidth: "120px",
      style: { minWidth: "120px" },
      // sortable: true,
      cell: (row) => <span className="sub-text">{row?.payment_for == "book_session" ? "Book Session" : "Resource Purchased"}</span>,
    },
    {
      name: "Customer Details",
      selector: (row) => row?.purchased_user?.firstName,
      grow: 3,

      // sortable: true,
      cell: (row) => <div>{row?.payment_for ? <span className="sub-text"><span>{row?.purchased_user?.firstName}{" "}{row.purchased_user?.lastName}<br /></span>
        <span style={{ padding: "3px 0px" }}>{row?.purchased_user?.email}<br /></span>
        <span  className="sub-phoneinp-text">
          {/* {row?.purchased_user?.phone}<br /> */}
          <PhoneInput
            country={row?.purchased_user?.country_dial_code?.toUpperCase() || ""}
            value={(row?.purchased_user?.countryCode + row?.purchased_user?.phone)}
            placeholder="--"
            inputClass="minimal-input"
            buttonStyle={{ display: "none" }}
            inputStyle={{
              border: "none",
              padding: "0",
              background: "none",
              fontSize: "12px",
              width: "100%",
              outline: "none",
              color: "#8094ae",
              cursor: "pointer",
              // position: "relative",
              // top: "-56px",
            }}
            containerStyle={{
              border: "none",
              padding: "0",
            }}
            disabled={true}
          />
        </span>

      </span> :
        <span className="sub-text"><span>{row?.purchased_user?.firstName}<br /></span>
          <span style={{ padding: "3px 0px" }}>{row?.purchased_user?.email}<br /></span>
          <span className="sub-phoneinp-text">
            {/* {row?.purchased_user?.phone} */}
            
          <PhoneInput
            country={row?.purchased_user?.country_dial_code?.toUpperCase() || ""}
            value={(row?.purchased_user?.countryCode + row?.purchased_user?.phone)}
            placeholder="--"
            inputClass="minimal-input"
            buttonStyle={{ display: "none" }}
            inputStyle={{
              border: "none",
              padding: "0",
              background: "none",
              fontSize: "12px",
              width: "100%",
              outline: "none",
              color: "#8094ae",
              cursor: "pointer",
              // position: "relative",
              // top: "-56px",
            }}
            containerStyle={{
              border: "none",
              padding: "0",
            }}
            disabled={true}
          />
          </span>
        </span>
      }</div>,
    },
    {
      name: "Vendor Details",
      selector: (row) => row?.tbl_user_mentoring_sessions?.sold_by_user?.firstName,
      grow: 4,

      // sortable: true,
      cell: (row) => <div>{row?.payment_for == "book_session" ? <span className="sub-text"><span>{row?.tbl_user_mentoring_sessions[0]?.sold_by_user?.firstName}{" "}{row?.tbl_user_mentoring_sessions[0]?.sold_by_user?.lastName}<br /></span>

        <span>{row?.tbl_user_mentoring_sessions[0]?.sold_by_user?.email}<br /></span>
        {/* <span>{row?.tbl_user_mentoring_sessions[0]?.sold_by_user?.phone} */}

        <span className="sub-phoneinp-text">
          <PhoneInput
            country={row?.tbl_user_mentoring_sessions[0]?.sold_by_user?.country_dial_code?.toUpperCase() || ""}
            value={(row?.tbl_user_mentoring_sessions[0]?.sold_by_user?.countryCode + row?.tbl_user_mentoring_sessions[0]?.sold_by_user?.phone)}
            placeholder="--"
            inputClass="minimal-input"
            buttonStyle={{ display: "none" }}
            inputStyle={{
              border: "none",
              padding: "0",
              background: "none",
              fontSize: "12px",
              width: "100%",
              outline: "none",
              color: "#8094ae",
              cursor: "pointer",
              // position: "relative",
              // top: "-56px",
            }}
            containerStyle={{
              border: "none",
              padding: "0",
            }}
            disabled={true}
          />
        </span>

      </span> :
        <span className="sub-text"><span>{row?.tbl_resource_purchase?.tbl_user_resource?.tbl_user?.firstName}{" "}{row?.tbl_resource_purchase?.tbl_user_resource?.tbl_user?.lastName}<br /></span>
          <span style={{ padding: "3px 0px" }}>{row?.tbl_resource_purchase?.tbl_user_resource?.tbl_user?.email}<br /></span>
          <span className="sub-phoneinp-text">
            {/* {row?.tbl_resource_purchase?.tbl_user_resource?.tbl_user?.phone}<br /> */}
           
          <PhoneInput
            country={row?.tbl_resource_purchase?.tbl_user_resource?.tbl_user?.country_dial_code?.toUpperCase() || ""}
            value={(row?.tbl_resource_purchase?.tbl_user_resource?.tbl_user?.countryCode + row?.tbl_resource_purchase?.tbl_user_resource?.tbl_user?.phone)}
            placeholder="--"
            inputClass="minimal-input"
            buttonStyle={{ display: "none" }}
            inputStyle={{
              border: "none",
              padding: "0",
              background: "none",
              fontSize: "12px",
              width: "100%",
              outline: "none",
              color: "#8094ae",
              cursor: "pointer",
              // position: "relative",
              // top: "-56px",
            }}
            containerStyle={{
              border: "none",
              padding: "0",
            }}
            disabled={true}
          />
           </span>
        </span>
      }</div>,
    },
    {
      name: "View",
      grow: 0,
      selector: (row) => "",
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <button className="dropdown-item btn btn-md" onClick={() => handleAction(row)}>
            <em className="icon ni ni-eye"></em>
          </button>
        </div>
      ),
    },

  ];
  const columns1 = [
    // {
    //   name: "#",
    //   selector: (row) => row.id,
    // grow: 1,
    //   minWidth: "100px",
    //   style: { minWidth: "100px" },
    //   borderRadius: "4px",
    //   cell: (row, index) => (
    //     <>
    //       <div className="user-card mt-2 mb-2"> {index+1}</div>
    //     </>
    //   ),
    // },
    // {
    //   name: "Price per duration",
    //   selector: (row) => row.session_date,
    //   minWidth: "140px",
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="user-card mt-2 mb-2">
    //       <div className="user-info">

    //         <span className="sub-text">{row.price_per_duration}</span>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      name: "Session date/time",
      selector: (row) => "",
      minWidth: "140px",
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">{row?.tbl_user_mentoring_session_datetimes?.map((val, index) =>

              <>
                {moment(val.session_date).format("MM-DD-YYYY")}{" "}{getTime(val.session_time)}{index < row?.tbl_user_mentoring_session_datetimes.length - 1 ? "," : ""}<br />
              </>
            )}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Session Status",
      selector: (row) => "Attend",
      minWidth: "140px",
      style: { minWidth: "140px" },
      //   grow: 0,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">

              {row?.tbl_user_mentoring_session_datetimes?.map((val, index) =>
                <>
                  {val.is_cancelled === "yes" ? "Cancelled" : moment(val.session_date).format("MM-DD-YYYY") >= date ? "Upcoming" : "Attended"}
                  {val.is_cancelled === "yes" && val.cancel_by == 1 ? " by Vendor" : val.is_cancelled === "yes" && val.cancel_by == 2 ? " by Customer" : ""}
                  {index < row?.tbl_user_mentoring_session_datetimes.length - 1 ? "," : ""}<br />
                </>
              )}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Total Amount",
      selector: (row) => "Total Amount",
      minWidth: "140px",
      style: { minWidth: "140px" },
      //   grow: 0,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.total_amount ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", }).format(row.total_amount) : "$0.00"}
            </span>
          </div>
        </div>
      ),
    },
    {
      name: "Mentor Amount",
      selector: (row) => "mentor_amount",
      minWidth: "140px",
      style: { minWidth: "140px" },
      //   grow: 0,
      // sortable: true,
      cell: (row) => (
        <div className="user-card mt-2 mb-2">
          <div className="user-info">
            <span className="sub-text">
              {row.mentor_amount ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", }).format(row.mentor_amount) : "$0.00"}
            </span>
          </div>
        </div>
      ),
    },
  ];
  const current = new Date();
  const date = moment(current).format("MM-DD-YYYY")
  // console.log(date,"date")

  let selectparentid;
  let selectchildid;
  //const handlebuyer=(e)=>{
  //    selectparentid=e.target.value;
  //    setparentid(selectparentid)
  //  // console.log(selectparentid,"pid")
  //   getchilddata()
  //setBuyer(e.target.value)
  // }

  //const handleseller=(e)=>{
  //   selectparentid=e.target.value;
  //   setparentid(selectparentid)
  // // console.log(selectparentid,"pid")
  //  getchilddata()
  //setSeller(e.target.value)


  //}
  const handleChildID = (e) => {
    selectchildid = e.target.value;
    setchildid(selectchildid)
    // console.log(selectchildid,"cid")
  }
  const handleCustomer = (e) => {
    setGetCustomer(e.target.value)
  }
  const getallmentordata = () => {
    setLoader(true)
    let data = {
      "page_number": currentPage,
      "payment_for": paymentFor ?? "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
      "start_date": selectedfromdate ?? "",
      "buyer_id": buyer.value ?? "",
      "seller_id": seller.value ?? "",
      limit: limitvalue ? limitvalue : 10,
      sortby: sortpayment ? sortpayment : "",
      key: "",
    };
    console.log("data payload ", data)
    makePostRequest(URLS.GET_PAYMENT_HISTORY, null, data, null)
      .then((res) => {
        console.log("Success", res)
        if (res.code == 200) {
          setmentordata(res.data.data);
          setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)))
          console.log("payment data", res)
          setLoader(false)
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
      });
  };
  useEffect(() => {
    // getallmentordata();
    // getcustomerdata();
    // getchilddata();
    getbuyerdata();
    getsellerdata();
  }, []);
  const handleSearch = () => {

    if (selectedfromdate != null && selectedtodate == null) {
      settoDateError("please select valid end date")
    }
    else if (selectedtodate != null && selectedfromdate == null) {
      setformDateError("please select valid start date ")
    }
    else if (selectedfromdate > selectedtodate) {
      toast.error("end date should be greater than start date")
    } else {

      getallmentordata();
      setCurrentPage(1)
    }
  }

  const handleClearData = () => {
    setparentid("");
    setchildid("");
    setGetCustomer("");
    setSearch("")
    setSelectedfromDate("")
    setSelectedToDate("")
    setPaymentFor("")
    getallmentordata();
    setSortPayment("")
    setBuyer("")
    setSeller("")
    setCurrentPage(1)
    setlimitvalue(10);
    setClearState(!clearState)
  }

  const clearfilter = () => {
    handleClearData()
  }
  useEffect(() => {
    getallmentordata();
  }, [clearState])

  const handleCustomerlistId = (sessiondetail) => {
    setchildsessiondetail(sessiondetail.tbl_user_mentoring_session_datetimes)
    setalldata(sessiondetail)
    setIsOpen(true)
    //  console.log(childsessiondetail,sessiondetail,"se de")
  };
  const getbuyerdata = () => {
    setLoader(true);
    makePostRequest(URLS.GET_ALL_BUYERS_PAYMENT, null, { page_number: 1 }, null)
      .then((res) => {
        console.log("res buyer", res)
        if (res.code == 200) {
          //setBuyerdata(res.data.data);
          //   setLoader(false);
          //   // console.log(res.data,"cus")
          //   setparentid("")
          setBuyerdata((prevOptions) => [...prevOptions, ...res?.data?.data]);
          //setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getsellerdata = () => {
    setLoader(true);
    makePostRequest(URLS.GET_ALL_SELLERS_PAYMENT, null, { page_number: 1 }, null)
      .then((res) => {
        console.log("res seller", res)
        if (res.code == 200) {
          //setSellerdata(res.data.data);
          //   setLoader(false);
          //   // console.log(res.data,"cus")
          //   setparentid("")
          setSellerdata((prevOptions) => [...prevOptions, ...res?.data?.data]);
          //setDropdownPage((prevPage) => prevPage + 1);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getchilddata = () => {
    let data = {
      "parentId": selectparentid
    }
    makePostRequest(URLS.GETALL_CHILD, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setchilddata(res.data);
          // console.log(res.data,"child name")
          setchildid("")
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    //  fetchData(selectedPage.selected+1)
  };
  // console.log(currentPage,"current page",)
  // Fetch data based on the current page
  const fetchData = (page) => {
    // Your code to fetch data from the server based on the currentPage
    // For example, you can make an API call or update the state with the new data
    let data = {
      "page_number": currentPage,
      "payment_for": paymentFor ?? "",
      end_date: selectedtodate ? moment(selectedtodate).add(1, 'd').format("YYYY-MM-DD") : "",
      "start_date": selectedfromdate ?? "",
      "buyer_id": buyer.value ?? "",
      "seller_id": seller.value ?? "",
      limit: limitvalue ? limitvalue : 10,
      sortby: sortpayment ? sortpayment : "",
      key: ""
    };
    console.log("data", data)

    makePostRequest(URLS.GET_PAYMENT_HISTORY, null, data, null)
      .then((res) => {
        if (res.code == 200) {
          setmentordata(res.data.data);
          setCount(Math.ceil(res.data.count / parseInt(limitvalue ? limitvalue : 10)))
          console.log("Rdata", res.data.data)
          setLoader(false)
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false)
      });
  };
  useEffect(() => {
    fetchData()
  }, [currentPage])
  // Render your component with pagination
  const newRef = useRef(null);
  const newRef1 = useRef(null);
  const selectRefUserName = useRef(null);
  const selectRefMentor = useRef(null);

  const handleMentor = () => {
    if (selectRefMentor.current) {
      setMentorName(!mentorName);
      selectRefMentor.current.focus();
    }

  }

  const handleuserName = (e) => {
    if (selectRefUserName.current) {
      setUserName(!userName);
      selectRefUserName.current.focus();
    }

  }

  const handleOutSideClick = (e) => {
    if (newRef.current && !newRef.current.contains(e.target)) {
      setDatePickerShow(false);
    }
  }
  const handleOutSideClick1 = (e) => {
    if (newRef1.current && !newRef1.current.contains(e.target)) {
      setDatePickerShow1(false);
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleOutSideClick);
    document.addEventListener("mousedown", handleOutSideClick1);
    return () => {
      document.removeEventListener("mousedown", handleOutSideClick);
      document.removeEventListener("mousedown", handleOutSideClick1)
    }
  });

  useEffect(() => {
    document.body.classList.add("payment-report");
    return () => {
      document.body.classList.remove("payment-report");
    };
  }, []);


  return (
    <React.Fragment>
      <Head title="Payment Report"></Head>
      <Content>
        <ToastContainer />
        <h3 className="nk-block-title page-title mb5 pb-4">Payment Report</h3>
        <Card className="card-bordered">
          <>
            <Modal isOpen={isOpen} toggle={openModal}>
              <ModalHeader
                toggle={openModal}
                close={
                  <button className="close" onClick={openModal}>
                    <Icon name="cross" />
                  </button>
                }
              >
                Payment Information
              </ModalHeader>
              <Card className="card-bordered">
                <div className="card-inner">
                  <BlockHeadContent>
                    <BlockHead size="lg">
                      <div className="customerDetailsTable">
                        <div className="row">
                          {modalData?.payment_for == "book_session" ?

                            <div>
                              <Card className="card-bordered mb-5">


                                <ul className="data-list is-compact">
                                  {/* <li className="data-item">
                       <div className="data-col">
                       <div className="data-label">Price per Duration</div>
                       <div className="data-value">
                       { modalData?.tbl_user_mentoring_sessions[0]?.price_per_duration ? new Intl.NumberFormat("en-US",{
        style:"currency",
       currency:"USD",
     }).format(modalData?.tbl_user_mentoring_sessions[0]?.price_per_duration) :"$0.00"}
                       </div>
                       </div>
                       </li> */}
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Total Amount</div>
                                      <div className="data-value">
                                        {modalData?.total_amount ? new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        }).format(modalData?.total_amount) : "$0.00"}
                                      </div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Tax Amount</div>
                                      <div className="data-value">
                                        {modalData?.tbl_user_mentoring_sessions[0]?.tax_amount ? new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        }).format(modalData?.tbl_user_mentoring_sessions[0]?.tax_amount) : "$0.00"}
                                      </div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">MatchED Revenue</div>
                                      <div className="data-value">
                                        {modalData?.admin_fee ? new Intl.NumberFormat("en-US", {
                                          style: "currency",
                                          currency: "USD",
                                        }).format(modalData?.admin_fee) : "$0.00"}
                                      </div>
                                    </div>
                                  </li>
                                  {/* <li className="data-item">
                       <div className="data-col">
                       <div className="data-label">Stripe Fees</div>
                       <div className="data-value">
                       { modalData?.transaction_fee ? new Intl.NumberFormat("en-US",{
        style:"currency",
       currency:"USD",
     }).format(modalData?.transaction_fee) :"$0.00"}
                       </div>
                       </div>
                       </li> */}


                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Transaction ID</div>
                                      <div className="data-value" style={{ width: "50%" }}>
                                        {" "}{modalData.payment_intent ? modalData.payment_intent : "--"}
                                      </div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Invoice Number</div>
                                      <div className="data-value">
                                        {modalData?.tbl_user_mentoring_sessions[0].invoice_number ? modalData?.tbl_user_mentoring_sessions[0].invoice_number : "--"}
                                      </div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Invoice PDF Link</div>
                                      <div className="data-value invoicePDF">
                                        <a href={S3_BUCKET_URL + modalData.tbl_user_mentoring_sessions[0].mentor_invoice_pdf_link} target="_blank">View Invoice</a>

                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Card>
                              <h3 className="nk-block-title page-title">Session Details</h3>
                              <ReactDataTable
                                data={modalData?.tbl_user_mentoring_sessions}
                                columns={columns1}
                                className="nk-tb-list"
                              />
                            </div>
                            :
                            <div>
                              <Card className="card-bordered mb-5">
                                <ul className="data-list is-compact">
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Resource Title</div>
                                      <div className="data-value">{modalData?.tbl_resource_purchase?.tbl_user_resource?.resource_title}</div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Amount</div>
                                      <div className="data-value">{modalData?.tbl_resource_purchase?.total_amount ? new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(modalData?.tbl_resource_purchase?.total_amount) : "$0.00"}</div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Tax Amount</div>
                                      <div className="data-value">{modalData?.tax_amount ? new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(modalData?.tax_amount) : "$0.00"}</div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">MatchED Revenue</div>
                                      <div className="data-value">{modalData?.admin_fee ? new Intl.NumberFormat("en-US", {
                                        style: "currency",
                                        currency: "USD",
                                      }).format(modalData?.admin_fee) : "$0.00"}</div>
                                    </div>
                                  </li>
                                  {/* <li className="data-item">
                                      <div className="data-col">
                                        <div className="data-label">Stripe Fees</div>
                                        <div className="data-value">{ modalData?.transaction_fee ?  new Intl.NumberFormat("en-US",{
        style:"currency",
       currency:"USD",
     }).format(modalData?.transaction_fee) :"$0.00"}</div>
                                      </div>
                                    </li> */}
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Transaction ID</div>
                                      <div className="data-value" style={{ width: "50%" }}>{modalData?.payment_intent ? modalData?.payment_intent : "--"}</div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Invoice Number</div>
                                      <div className="data-value">{modalData?.invoice_number ? modalData?.invoice_number : "--"}</div>
                                    </div>
                                  </li>
                                  <li className="data-item">
                                    <div className="data-col">
                                      <div className="data-label">Invoice PDF Link</div>
                                      <div className="data-value invoicePDF">
                                        <a
                                          href={S3_BUCKET_URL + modalData?.invoice_pdf_link}
                                          target="_blank"
                                        >
                                          View Invoice
                                        </a>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Card>
                            </div>
                          }


                        </div>
                      </div>
                    </BlockHead>
                  </BlockHeadContent>
                </div>
              </Card>
              <ModalFooter className="">
              </ModalFooter>
            </Modal>
          </>
          {loader ? (
            <div className="overflowloader" id="preload">
              <div className="circle-square" style={{ marginTop: "250px" }} >
                <div className="red" style={{ background: "#AAC0CE" }}></div>
                <div className="blue" style={{ background: "#265472" }}></div>
                <div className="green" style={{ background: "#F19357" }}></div>
                <div className="yellow" style={{ background: "#F0B285" }}></div>
              </div>
            </div>
          ) : (<>
            <div className="card-inner relativeClass">
              {/* <h4 onClick={ShowHideSelect} className="cursorPointer">Filters <i className="fa fa-filter" aria-hidden="true"></i></h4> */}
              <div className="row pb-2 pt-2  align-items-center">
                <div className="col-lg-4 ">
                  <div className="filtersidebutton">
                    <h5 style={{ cursor: "pointer" }} onClick={ShowHideSelect}>Filters<FaFilter /></h5>
                    <button className="btn btn-md btn-primary" type="submit" onClick={clearfilter}> Clear Filters </button>
                  </div>
                </div>
                <div className="col-lg-8 d-flex  justify-content-end">

                  <select value={sortpayment} className="form-select" style={{ width: "170px" }} onChange={HandleSortPayment}>
                    <option value="">Sort By</option>
                    <option value="ASCDATE">Date Ascending</option>
                    <option value="DESCDATE">Date Descending</option>
                    <option value="PAYMENTFORASC">Payment For Ascending</option>
                    <option value="PAYMENTFORDESC">Payment For Descending</option>
                    <option value="ASCBUYER">Customer Ascending</option>
                    <option value="DESCBUYER">Customer Descending</option>
                    <option value="ASCSELLER">Vendor Ascending</option>
                    <option value="DESCSELLER">Vendor Descending</option>
                  </select>

                  <div><label className="mt-1 ">&nbsp;&nbsp;Show&nbsp;</label></div>
                  <div><select value={limitvalue} className="form-select" style={{ width: "85px" }} onChange={HandleLimitValue}>

                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  </div>
                  <div>
                    &nbsp; &nbsp;
                    <button className="btn btn-primary " onClick={ExportEcelFile}>
                      <em className="icon ni ni-download-cloud"></em><span>Export</span>
                    </button>
                  </div>
                </div>
              </div>
              <hr></hr>
              {show &&
                <div>
                  <div className="row pb-2">
                    <div className="col-lg-3">

                      <div className="form-control border-0 px-0">
                        <label><strong> Customer Name</strong></label>
                        <div className="form-control-wrap paymetsession">
                          <i className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList" onClick={handleuserName}></i>
                          {/* <select value={buyer}  className="form-select"  onChange={handlebuyer}>
                                    <option value="">All Buyers</option>         
                      {
							     buyerdata &&	buyerdata.map((val)=>{
                  return(<>
                   <option value={val.id}>{val.firstName}{" "}{val.lastName}</option>
                  </>)
                })
										 }
								    </select> */}

                          <Select
                            className="form-select"
                            autosize={true}
                            onChange={(e) => setBuyer(e)}
                            defaultValue={buyer}
                            options={[
                              { value: "", label: "All" },
                              ...(buyerdata?.map(val => ({ value: val.id, label: val.firstName + " " + val.lastName })) || [])
                            ]}
                            openMenuOnFocus={userName}
                            ref={selectRefUserName}
                          />


                        </div>
                      </div></div>

                    <div className="col-lg-3">
                      <div className="form-control border-0 px-0">
                        <label><strong> Vendor Name</strong></label>

                        <div className="form-control-wrap paymetsession">
                          <i className="fa fa-chevron-down chevron-downcustome chevron-downcustomerList" onClick={handleMentor}></i>
                          {/* <select value={seller}  className="form-select"  onChange={handleseller}>
                                    <option value="">All Sellers</option>
                                   
                      {
							     sellerdata &&	sellerdata.map((val)=>{
                  return(<>
                   <option value={val.id}>{val.firstName}{" "}{val.lastName}</option>
                  </>)
                })
										 }
								    </select> */}

                          <Select
                            className="form-select"
                            autosize={true}
                            onChange={(e) => setSeller(e)}
                            defaultValue={seller}
                            options={[
                              { value: "", label: "All" },
                              ...(sellerdata?.map(val => ({ value: val.id, label: val.firstName + " " + val.lastName })) || [])
                            ]}
                            openMenuOnFocus={mentorName}
                            ref={selectRefMentor}
                          />
                        </div>
                      </div>
                    </div>


                    <div className="col-lg-3">
                      <div className="form-control border-0 px-0">
                        <label><strong>Start Date</strong></label>
                        {/* <input type="date" format="MM/DD/YYYY" timezone="en-us" name ="formdate" value={selectedfromdate} className="form-control" onChange={HandleFromDate} />   */}

                        <div className="form-control-wrap" ref={newRef}>
                          <div className="form-icon form-icon-left">
                            <span onClick={() => handleclickdatepickericon()}>
                              <Icon name="calendar"></Icon>
                            </span>
                          </div>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedfromdate}
                            onChange={HandleFromDate}
                            className="form-control date-picker"
                            open={datepickershow}
                            onFocus={() => setDatePickerShow(!datepickershow)}
                          />
                        </div>
                      </div>
                      <span className="error">{formDateError}</span>
                    </div>
                    <div className="col-lg-3">
                      <div className="form-control border-0 px-0">
                        <label><strong> End Date</strong></label>
                        {/* <input type="date"  name ="todate" value={selectedtodate} className="form-control" onChange={HandleToDate}></input> */}
                        <div className="form-control-wrap" ref={newRef1}>
                          <div className="form-icon form-icon-left">
                            <span onClick={() => handleclickdatepickericon1()}>
                              <Icon name="calendar"></Icon>
                            </span>
                          </div>
                          <DatePicker
                            placeholderText="MM/DD/YYYY"
                            selected={selectedtodate}
                            onChange={HandleToDate}
                            className="form-control date-picker"
                            open={datepickershow1}
                            onFocus={() => setDatePickerShow1(!datepickershow1)}
                          />
                        </div>
                      </div>
                      <span className="error">{toDateError}</span>
                    </div>
                    <div className="col-lg-3">
                      <label><strong>Payment For</strong></label>
                      <select value={paymentFor} className="form-select" onChange={(e) => setPaymentFor(e.target.value)}>
                        <option value="">Select Payment For</option>
                        <option value="resource_purchased">Resource Purchased</option>
                        <option value="book_session">Book Session</option>
                      </select>
                    </div>
                    {/* <div className="row pb-2"> */}
                    {/* <div className="col-lg-4">
                      <div className="form-control border-0 px-0">
                        <label>
                          <strong>Search by Buyer </strong>
                        </label>
                        <Input
                          name="name"
                          placeholder="search..."
                          type="text"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        </div>
                      </div> */}
                    {/* </div> */}


                    <div className="col-lg-4">
                      <div className="outerTypeBox mb-3">
                        <label className="w-100 mb-0">&nbsp;</label>
                        <button className="btn btn-md btn-primary" type="submit" onClick={handleSearch}> Search </button>


                      </div>

                    </div>

                  </div>

                </div>}

              <div className="card-inners relativeClass">
                <BlockHeadContent>
                  <BlockHead size="lg">
                    <div className="clearboth"></div>


                    {mentordata.length == 0 ? <div >No Data Found</div> : (
                      <ReactDataTable
                        data={mentordata}
                        columns={columns}
                        className="nk-tb-list"
                        searchable={false}
                        setPageCount={setPageCount}
                      // pagination
                      />
                    )}

                    {mentordata.length > 0 ?

                      <div>
                        <ReactPaginate
                          previousLabel={"<<"}
                          nextLabel={">>"}
                          pageCount={count}
                          pageRangeDisplayed={5}
                          marginPagesDisplayed={2}
                          onPageChange={handlePageChange}
                          containerClassName={'pagination'}
                          pageClassName={"page-item"}
                          activeClassName={'active'}
                          pageLinkClassName={"page-link"}
                          previousClassName={"page-item"}
                          previousLinkClassName={"page-link"}
                          nextClassName={"page-item"}
                          nextLinkClassName={"page-link"}
                          breakClassName={"page-item"}
                          breakLinkClassName={"page-link"}
                        />
                      </div>

                      : ""}

                  </BlockHead>
                </BlockHeadContent>
              </div>
            </div>
          </>)}
        </Card>
      </Content>
    </React.Fragment>
  );
}
export default PaymentReport;

